import React, {useEffect, useState} from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    Divider,
    Link,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    InputAdornment, Autocomplete,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import logo from "../../../../assets/react-logo-no-background.svg";
import {ApiDetails, KEY, SITE_KEY, SKY_AUTH_ApiDetails} from "../../../../dummyData";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {LockOutlined} from "@material-ui/icons";
import CryptoJS from "crypto-js";
import {useLocation, useNavigate} from "react-router-dom";
import {showSnackbar} from "../../../SnackBar/SnackBar";
import QRAuthentication from "../QR Auth/QRAuthentication";

const LoginRight = (props) => {

    let userData = 0;

    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(<VisibilityOutlined/>);

    const initialValues = {
        email_address: "",
        password: "",
    };

    const validationSchema = Yup.object().shape({
        email_address: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string().required('Password is required'),
    });


    const navigate = useNavigate();
    const [userTypes, setUserTypes] = React.useState([]);
    const [selectedUserType, setSelectedUserType] = React.useState();

    const [loading, setLoading] = React.useState(false);
    const [loadingAuthentication, setLoadingAuthentication] =
        React.useState(false);
    const [proceed, setProceed] = React.useState(false);

    const [authenticationPage, setAuthenticationPage] = useState();


    useEffect(() => {
        //Get user types
        fetch(ApiDetails + "pegasus/visionary/extras/user_types", {
            method: "GET",
        })
            .then(async (response) => {
                let data = await response.json();
                // console.log("User types : " + data);
                if (response.status === 200) {
                    setUserTypes(data);
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Failed Fetching System User Types",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });

    }, []);

    const confirmAuthCode = async (email, code) => {
        setLoadingAuthentication(true);
        if (code === "") {
            setLoadingAuthentication(false);

            showSnackbar({
                title: "Error!",
                message: "Code Cannot Be Empty",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            return;
        }
        let body = {
            auth_code: code,
            identifier_type: "MAIL",
            identifier: email,
            program_id: parseInt("100009"),
        };
        fetch(SKY_AUTH_ApiDetails + "/sky-auth/confirmation", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                // let data = await response.json();
                // console.log(data);
                if (response.status === 200) {
                    // handleClose();
                    getUserDetails(userData);
                    setLoadingAuthentication(false);
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Invalid Code Provided",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                    setLoadingAuthentication(false);
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
                setLoadingAuthentication(false);
            });
    };

    const onSubmit = (values) => {

        // e.preventDefault();
        setLoading(true);
        if (selectedUserType === undefined || selectedUserType === null) {
            showSnackbar({
                title: "Error!",
                message: "User Type Not Selected",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            setLoading(false);
            return;
        }
        values = {...values, user_type: selectedUserType.id};
        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/authorization/login", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                let data = await response.json();
                userData = data;
                if (response.status === 200) {
                    // landlordID = new Map(Object.entries(data)).get("landlord_id");

                    // console.log(new Map(Object.entries(data)))
                    // return;
                    let two_fa = new Map(Object.entries(data)).get("two_factor_enabled");

                    // if (false) {
                    if (two_fa === "YES") {
                        props.showOtp()
                        //Create SSE Connection
                        try {
                            await fetchEventSource(
                                SKY_AUTH_ApiDetails + "/sky-auth/confirmation/sse/100009",
                                {
                                    method: "GET",
                                    headers: {
                                        Accept: "text/event-stream",
                                    },
                                    onopen(res) {
                                        if (res.ok && res.status === 200) {
                                            // console.log("Connection made ", res);

                                            //Get Qr Code
                                            //program_id,identifier,identifier_type
                                            const body = {
                                                identifier_type: "MAIL",
                                                identifier: values.email_address,
                                                program_id: parseInt("100009"),
                                            };
                                            fetch(
                                                SKY_AUTH_ApiDetails +
                                                "/sky-auth/authorization/biometric/add",
                                                {
                                                    method: "POST",
                                                    body: JSON.stringify(body),
                                                }
                                            )
                                                .then(async (response) => {
                                                    let data = await response.json();
                                                    if (response.status === 200) {
                                                        // console.log(data);
                                                        let base_64_image = new Map(
                                                            Object.entries(data)
                                                        ).get("Base64 QR image");

                                                        setAuthenticationPage(
                                                            < QRAuthentication
                                                                base_64_image={base_64_image}
                                                                validateCode={(code) => {
                                                                    confirmAuthCode(values.email_address, code);
                                                                }}
                                                            />)
                                                        setProceed(true);
                                                    } else {
                                                        showSnackbar({
                                                            title: "Error!",
                                                            message: "Invalid Code Provided",
                                                            type: "error", // Options: success, error, warning, info
                                                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                                        });
                                                        setLoading(false);
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    showSnackbar({
                                                        title: "Error!",
                                                        message: "Something went wrong. Kindly try again later.",
                                                        type: "error", // Options: success, error, warning, info
                                                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                                    });
                                                });
                                        } else if (
                                            res.status >= 400 &&
                                            res.status < 500 &&
                                            res.status !== 429
                                        ) {
                                            console.log("Client side error ", res);
                                        }
                                    },
                                    onmessage() {
                                        // let notifications = Array.from(JSON.parse(event.data));

                                        console.log("Code confirmed successfully");
                                        getUserDetails(data);

                                        // console.log(event.data);
                                    },
                                    onclose() {
                                        console.log(
                                            "Connection closed by the server retrying ... "
                                        );
                                    },
                                    onerror(err) {
                                        console.log("There was an error from server", err);

                                        // handleClose();
                                        // getUserDetails(data);
                                    },
                                }
                            );
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        getUserDetails(data);
                    }
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });
    };

    const getUserDetails = (userDetails) => {
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/dashboard/get/details", {
            method: "POST",
            body: JSON.stringify(userDetails),
        })
            .then(async (response) => {
                // console.log("userDetails get user details");
                // console.log(userDetails);
                let data = await response.json();
                if (response.status === 200) {
                    setLoading(false);
                    // props.topBar();
                    const map1 = new Map(Object.entries(data));
                    const details = new Map(Object.entries(userDetails));
                    const subscription = new Map();

                    subscription.set("subscription_expired", details.get("subscription_expired"))
                    subscription.set("subscription_amount", details.get("subscription_amount"))

                    let myMap = CryptoJS.AES.encrypt(
                        JSON.stringify(Array.from(details.entries())),
                        KEY
                    ).toString();
                    let portfolioDetails = CryptoJS.AES.encrypt(
                        JSON.stringify(Array.from(map1.entries())),
                        KEY
                    ).toString();

                    let subscriptionDetails = CryptoJS.AES.encrypt(
                        JSON.stringify(Array.from(subscription.entries())),
                        KEY
                    ).toString();

                    localStorage.setItem("myMap", myMap);
                    localStorage.setItem("isNew", userDetails.new);
                    localStorage.portfolioDetails = portfolioDetails;
                    localStorage.subscriptionDetails = subscriptionDetails;

                    // return;
                    navigate("/home", {
                        state: {
                            userDetails: userDetails,
                        },
                    });
                    showSnackbar({
                        title: "Success!",
                        message: "Login Successful",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                    props.refresh();
                    props.fn();
                } else {
                    setLoading(false);
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    return (
        <Box
            sx={{
                flex: 1,
                padding: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >

            {proceed ? (<>{authenticationPage}</>) : (<><img
                style={{
                    width: "250px",
                    height: "150px",
                }}
                src={logo}
                alt="React Logo"
            />

                <Typography variant="h4" sx={{mb: 2}}>
                    Welcome back
                </Typography>
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({errors, touched}) => (
                        <Form style={{width: "100%", maxWidth: "400px"}}>
                            <Field
                                as={TextField}
                                name="email_address"
                                label="Email   "
                                fullWidth
                                margin="normal"
                                InputLabelProps={{shrink: true}}
                                error={touched.email_address && !!errors.email_address}
                                helperText={<ErrorMessage name="email"/>}
                            />
                            <Field
                                as={TextField}
                                name="password"
                                label="Password   "
                                type={type}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{shrink: true}}
                                error={touched.password && !!errors.password}
                                helperText={<ErrorMessage name="password"/>}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                onClick={() => {
                                                    setType(type === "password" ? "text" : "password");
                                                    setIcon(type === "password" ? <VisibilityOffOutlined/> :
                                                        <VisibilityOutlined/>);
                                                }}
                                            >
                                                {icon}
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {/* Dropdown Field */}
                            <Autocomplete
                                // disablePortal
                                id="combo-box-demo"
                                options={userTypes.map((type) => {
                                    return type.description;
                                })}
                                style={{width: "100%", marginTop: "10px"}}
                                onChange={(e, value) => {
                                    setSelectedUserType(
                                        userTypes.filter((type) => {
                                            if (type.description === value) {
                                                return type.id;
                                            }
                                        })[0]
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        id="outlined-basic"
                                        label="Account type"
                                        variant="outlined"
                                        name="property"
                                        {...params}
                                    />
                                )}
                            />

                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 2, mb: 2, backgroundColor: "#3799D6", color: "#FFF"}}
                                loading={loading}
                            >
                                Sign In
                            </LoadingButton>
                            <Divider sx={{my: 2}}>or</Divider>

                            <Typography align="center">
                                Don’t have an account?{" "}
                                <Link href="/signup" underline="hover" color="primary">
                                    Sign up
                                </Link>
                            </Typography>
                        </Form>
                    )}
                </Formik>
            </>)}
        </Box>

    );
}

export default LoginRight;
