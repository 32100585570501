import React from "react";
import {
    Box,
    Typography,
} from "@mui/material";
import {DotLottieReact} from '@lottiefiles/dotlottie-react';
import {useLocation} from "react-router-dom";

const LoginLeft = (props) => {


    console.log("Login Left OTP")
    console.log(props.otp)


    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: "#EDEDED",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {props.otp === true ? (<Box>

                <DotLottieReact
                    src="https://lottie.host/1f3dc3c7-8268-4a09-93d0-458d90e4a02e/fd5Zgjr4Zq.lottie"
                    loop
                    autoplay
                    style={{width: 450, height: 450}}
                />
            </Box>) : (<Box>
                <DotLottieReact
                    src="https://lottie.host/f4df5355-a010-4687-9989-0b32fc17fdad/9cR9eYlJ5D.lottie"
                    autoplay
                    loop
                    style={{width: 450, height: 450}}
                />
                <Typography
                    variant="h6"
                    align="center"
                    sx={{color: "text.secondary", mt: 2}}
                >
                    Welcome back to our platform!
                </Typography>
            </Box>)}
        </Box>
    );
};

export default LoginLeft;
