import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    IconButton,
    Avatar,
    Divider,
    Fade,
} from "@mui/material";
import {styled} from "@mui/system";
import Transactions from "../Transactions/Transactions";
import Arrears from "../Arrears/Arrears";

const themeColor = "#2C5283";

const StyledCard = styled(Card)(({theme}) => ({
    marginBottom: theme.spacing(2),
    border: "1px solid #DDE6ED",
    borderRadius: theme.spacing(1),
}));

const StatusChip = styled(Box)(({status}) => ({
    color: "#fff",
    backgroundColor: status === "ACTIVE" ? "#38B973" : "#D9534F",
    padding: "5px 10px",
    borderRadius: "20px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
}));

const Account = (props) => {

    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <>
            {/*<Grid item xs={15} md={8}>*/}
            {/* Tabs Section */}
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                sx={{marginBottom: 2}}
            >
                <Tab label="Transactions"/>
                <Tab label="Arrears"/>
            </Tabs>

            {/* Tab Content */}
            {activeTab === 0 && (
                <Transactions tenantID={props.tenantID}/>
            )}

            {activeTab === 1 && (
                <Arrears tenantID={props.tenantID}/>
            )}
            {/*</Grid>*/}
        </>
    );
};

export default Account;
