import React, {useState} from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    Grid,
    MenuItem,
} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function TenantReport() {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [reportType, setReportType] = useState("monthly");

    const handleGenerateReport = () => {
        if (!startDate || !endDate) {
            alert("Please select valid dates!");
            return;
        }

        const formattedStart = dayjs(startDate).format("YYYY-MM-DD");
        const formattedEnd = dayjs(endDate).format("YYYY-MM-DD");

        alert(`Generating report from ${formattedStart} to ${formattedEnd}`);
        // Here you can integrate your API call or logic to generate the report
    };

    return (
        <Box
            sx={{
                backgroundColor: "#F7F9FC",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
        >

            {/* Report Type Selector */}
            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{marginBottom: "10px"}}>
                    Select Report Type:
                </Typography>
                <TextField
                    select
                    fullWidth
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                    variant="outlined"
                >
                    <MenuItem value="monthly">Monthly Report</MenuItem>
                    <MenuItem value="duration">Duration Report</MenuItem>
                </TextField>
            </Box>

            {/* Date Pickers */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Start Date:</Typography>
                        <DatePicker
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "10px",
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">End Date:</Typography>
                        <DatePicker
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "10px",
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </LocalizationProvider>

            {/* Generate Report Button */}
            <Box
                sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        textTransform: "none",
                        borderRadius: "20px",
                        padding: "10px 20px",
                        fontSize: "16px",
                    }}
                    onClick={handleGenerateReport}
                >
                    Generate Report
                </Button>
            </Box>
        </Box>
    );
}
