import React, {useState} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import "./FormPage.css";
import {Typography} from "@mui/material";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";
import {ApiDetails} from "../../../../dummyData";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";

export default function FormComponent(props) {


    const originalText = props.originalDetails;

    const tenantDetails = {
        first_name: props.tenatDetails.first_name,
        last_name: props.tenatDetails.last_name,
        email_address: props.tenatDetails.email_address,
        phone_number: props.tenatDetails.phone_number,
        secondary_phone_number: props.tenatDetails.secondary_phone_number,
        rent: props.tenatDetails.rent,
        national_id: props.tenatDetails.national_id,

        next_of_kin_relationship: props.tenatDetails.next_of_kin_relationship,
        next_of_kin_first_name: props.tenatDetails.next_of_kin_first_name,
        next_of_kin_last_name: props.tenatDetails.next_of_kin_last_name,
        next_of_kin_national_id: props.tenatDetails.next_of_kin_national_id,
        next_of_kin_contacts: props.tenatDetails.next_of_kin_contacts,
    };

    const tenantValidationSchema = Yup.object({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email_address: Yup.string().email("Invalid email address"),
        phone_number: Yup.string()
            .required("Phone number is required")
            .matches(/^\d{10}$/, "Phone number must be 10 digits"),
        secondary_phone_number: Yup.string()
            .matches(/^\d{10}$/, "Secondary phone number must be 10 digits"),
        rent: Yup.number()
            .required("Rent is required")
            .min(1, "Rent must be at least 1")
            .typeError("Rent must be a valid number"),
        national_id: Yup.number()
            .required("National ID is required")
            .min(5, "National ID must be at least 5")
            .typeError("National ID must be a valid number"),
    });


    const handleSubmit = (values) => {


        if (values.first_name === "") {
            showSnackbar({
                title: "Error!",
                message: "Enter a valid first name.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "top", horizontal: "right"}, // Options for position
            });
            return;
        }
        if (values.last_name === "") {
            showSnackbar({
                title: "Error!",
                message: "Enter a valid last name.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "top", horizontal: "right"}, // Options for position
            });
            return;
        }
        if (values.rent === "") {
            showSnackbar({
                title: "Error!",
                message: "Enter a valid rent.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "top", horizontal: "right"}, // Options for position
            });
            return;
        }
        if (values.phone_number === "") {
            showSnackbar({
                title: "Error!",
                message: "Enter a valid phone number.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "top", horizontal: "right"}, // Options for position
            });
            return;
        }

        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/update/tenant", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: props.tenatDetails.tenant_id,
                first_name: values.first_name,
                last_name: values.last_name,
                national_id: values.national_id,
                email_address: values.email_address,
                phone_number: values.phone_number,
                occupation_or_profession: values.occupation_or_profession,
                next_of_kin_relationship: values.next_of_kin_relationship,
                next_of_kin_first_name: values.next_of_kin_first_name,
                next_of_kin_last_name: values.next_of_kin_last_name,
                next_of_kin_national_id: values.next_of_kin_national_id,
                next_of_kin_contacts: values.next_of_kin_contacts,
                rent: values.rent,
                secondary_phone_number: values.secondary_phone_number,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    showSnackbar({
                        title: "Success!",
                        message: "Tenant details updated successfully.",
                        type: "success", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Failed updating tenant details.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "top", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong while processing your request.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "top", horizontal: "right"}, // Options for position
                });
            });


        console.log("Form Data Submitted:", values);
        // alert(JSON.stringify(values, null, 2));


    };

    return (
        <div className="container">
            <Formik
                initialValues={tenantDetails}
                validationSchema={tenantValidationSchema}
                onSubmit={handleSubmit}
            >
                {({isSubmitting}) => (
                    <Form className="form">
                        {/* First Name and Last Name */}
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">First Name:</Typography>
                                <Field
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    placeholder="Enter First Name"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="first_name"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">Last Name:</Typography>
                                <Field
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    placeholder="Enter Last Name"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="last_name"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        {/* Email and Phone Number */}
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Email:</Typography>
                                <Field
                                    type="email"
                                    id="email_address"
                                    name="email_address"
                                    placeholder="Enter Email"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="email_address"
                                    component="div"
                                    className="error-message"
                                />
                            </div>

                            <div className="form-group">
                                <Typography variant="subtitle1">National ID:</Typography>

                                <Field
                                    type="number"
                                    id="national_id"
                                    name="national_id"
                                    placeholder="Enter National ID"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="national_id"
                                    component="div"
                                    className="error-message"
                                />
                            </div>


                        </div>

                        {/* Secondary Phone Number and County */}
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Phone Number:</Typography>

                                <Field
                                    type="text"
                                    id="phone_number"
                                    name="phone_number"
                                    placeholder="Enter Phone Number"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="phone_number"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">Secondary Phone Number:</Typography>

                                <Field
                                    type="text"
                                    id="secondary_phone_number"
                                    name="secondary_phone_number"
                                    placeholder="Enter Secondary Phone Number"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="secondary_phone_number"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        {/* Secondary Phone Number and County */}
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Rent:</Typography>
                                <Field
                                    type="text"
                                    id="rent"
                                    name="rent"
                                    placeholder="Enter rent"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="rent"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div
                            style={{width: "fit-content"}}
                        >
                            <h3 className="section-title">Next of Kin Details</h3>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">First Name:</Typography>

                                <Field
                                    type="text"
                                    id="next_of_kin_first_name"
                                    name="next_of_kin_first_name"
                                    placeholder="Enter First Name"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="next_of_kin_first_name"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">Last Name:</Typography>
                                <Field
                                    type="text"
                                    id="next_of_kin_last_name"
                                    name="next_of_kin_last_name"
                                    placeholder="Enter Last Name"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="next_of_kin_last_name"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Relationship:</Typography>
                                <Field
                                    type="text"
                                    id="next_of_kin_relationship"
                                    name="next_of_kin_relationship"
                                    placeholder="Enter Relationship"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="next_of_kin_relationship"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">Phone Number:</Typography>
                                <Field
                                    type="text"
                                    id="next_of_kin_contacts"
                                    name="next_of_kin_contacts"
                                    placeholder="Enter Phone Number"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="next_of_kin_contacts"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">National ID:</Typography>
                                <Field
                                    type="number"
                                    id="next_of_kin_national_id"
                                    name="next_of_kin_national_id"
                                    placeholder="Enter National ID"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="next_of_kin_national_id"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>


                        {/* Submit Button */}
                        <div className="form-row">
                            <button
                                type="submit"
                                className="button primary"
                                // disabled={isSubmitting}
                            >
                                Submit Form
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
