import React, {useEffect, useState} from "react";
import {styled} from "@mui/system";
import {CheckCircleOutline, Done, MoreVert, WarningAmber} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";
import {TextField, Typography} from "@material-ui/core";
import Switch from "@mui/material/Switch";
import {ApiDetails} from "../../../../dummyData";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Slide from "@mui/material/Slide";
import {useConfirm} from "material-ui-confirm";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";
import Box from "@mui/material/Box";

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    "& .MuiDataGrid-root": {
        height: "400px", // Prevents it from exceeding 500px
        minHeight: "200px", // Ensures it remains visible even with few rows
        overflowY: "auto",  // Enables scrolling when needed
    },
    "& .MuiDataGrid-columnHeaders": {
        fontSize: "1rem",
        fontWeight: 700,
        backgroundColor: "#E3F2FD", // Light blue background
        color: "#0D47A1", // Dark blue text
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "#f5faf5", // Light green hover color
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "0.9rem",
    },
}));


// Slide transition for smoother opening
const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

// Styled components
const styles = {
    dialogTitle: {
        textAlign: "center",
        fontWeight: "bold",
        color: "#2C5283",
        padding: "16px",
        borderBottom: "2px solid #2C5283",
    },
    dialogContent: {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    container: {
        height: "500px",
        width: "100%",
        maxWidth: "90vw",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        // padding: "15px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    },
    billingHeader: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "20px",
    },
    label: {
        fontSize: "1rem",
        fontWeight: 600,
        color: "#444",
    },
    cancelButton: {
        color: "#d32f2f",
        borderColor: "#d32f2f",
        "&:hover": {
            backgroundColor: "rgba(211, 47, 47, 0.1)",
        },
    },
    billButton: {
        backgroundColor: "#2C5283",
        color: "white",
        "&:hover": {
            backgroundColor: "#1E3A63",
        },
    },
};

const PropertyTenants = (props) => {
        const navigate = useNavigate();
        const {
            originalText,
            waterBill,
            minimumWaterBill,
            electricityBill,
            minimumElectricityBill,
            propertyDetails,
            viewModal
        } = props;

        const confirm = useConfirm();

        function getMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber);

            return date.toLocaleString("en-US", {month: "long"});
        }

        const billTenantsModal = viewModal;
        let [billedTenants, setBilledTenants] = useState([]);
        let [elecBilledTenants, setElecBilledTenants] = useState([]);

        const label = {inputProps: {"aria-label": "Switch demo"}};

        const [removedWaterBilling, setRemovedWaterBilling] = useState([])
        const [removedElectricityBilling, setRemovedElectricityBilling] = useState([])

        const [month, setMonth] = useState(new Date(2020, 11, 17, 0, 0, 0, 0));
        const [selectedMonth, setSelectedMonth] = useState(null);


        const [tenants, setTenants] = useState([])
        const [update, RunUpdateTenants] = useState(false)
        let [tenantsLength, setTenantsLength] = useState(tenants.length);

        useEffect(() => {
                // if (props.property_id !== undefined) {
                fetch(ApiDetails + "pegasus/visionary/tenant/get/property/tenants", {
                    method: "POST",
                    body: JSON.stringify({property_id: propertyDetails.property_id}),
                })
                    .then(async (response) => {
                        let data = await response.json();
                        if (response.status === 200) {
                            setTenantsLength(data.length);
                            setTenants(data); // ✅ This ensures tenants update correctly
                        }
                    })
                    .catch((err) => console.log(err));
                // }
            }, [update, propertyDetails]
        ); // Runs whenever `tenants` changes


        let [billLoading, setBillLoading] = useState(false);

        const columns = [
            {
                field: "user",
                headerAlign: "center",
                headerName: "Name",
                minWidth: 200,
                flex: 1,
                renderCell: (params) => (
                    <div style={{display: "flex", alignItems: "center"}}>
                        {params.row.state === "NOTICE" ? (
                            <WarningAmber style={{color: "#F57C00", marginRight: 8}}/>
                        ) : (
                            <CheckCircleOutline style={{color: "#4CAF50", marginRight: 8}}/>
                        )}
                        <span>{params.row.first_name} {params.row.last_name}</span>
                    </div>
                ),
            },
            {
                field: "house_number",
                headerAlign: "center",
                align: "center",
                headerName: "HSE NO",
                minWidth: 100,
                flex: 1,
            },
            {
                field: "phone_number",
                headerAlign: "center",
                align: "center",
                headerName: "Phone Number",
                minWidth: 150,
                flex: 1,
            },
            {
                field: "rent",
                headerName: "Rent",
                minWidth: 120,
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderCell: (params) => (
                    <span style={{fontWeight: 600, color: "#2E7D32"}}>
          {parseInt(params.row.rent.toString().replace(",", "")).toLocaleString("en-US", {
              style: "currency",
              currency: "KSH",
          })}
        </span>
                ),
            },
            {
                field: "bill",
                headerName: "Wallet",
                minWidth: 120,
                flex: 1,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => (
                    <span style={{color: params.row.status === "PENDING" ? "#D32F2F" : "#388E3C"}}>
          {params.row.Wallet.toLocaleString("en-US", {
              style: "currency",
              currency: "KSH",
          })}
        </span>
                ),
            },
            {
                field: "status",
                headerName: "Status",
                minWidth: 120,
                flex: 1,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => {
                    switch (params.row.status) {
                        case "PENDING":
                            return <Chip label="PENDING" color="error" variant="outlined"/>;
                        case "COMPLETED":
                            return <Chip label="PAID" color="success" variant="outlined"/>;
                        default:
                            return <Chip label="PARTIAL" color="warning" variant="outlined"/>;
                    }
                },
            },
            {
                field: "login_successful",
                headerName: "Logged in",
                flex: 1,
                minWidth: 80,
                headerAlign: "center",
                renderCell: (params) => (
                    params.row.login_successful ? (
                        <Chip label="Yes" color="success" variant="outlined" icon={<Done/>}/>
                    ) : (
                        <Chip label="No" color="error" variant="outlined" icon={<WarningAmber/>}/>
                    )
                ),
            },
            {
                field: "action",
                headerName: "Actions",
                minWidth: 230,
                flex: 1,
                headerAlign: "center",
                renderCell: (params) => (
                    <Chip
                        label="View Details"
                        color="info"
                        onClick={() => navigate(`/tenant/${params.row.tenant_id}`, {
                            state: {tenant_id: params.row.tenant_id},
                        })}
                        onDelete={() => navigate(`/tenant/${params.row.tenant_id}`, {
                            state: {tenant_id: params.row.tenant_id},
                        })}
                        deleteIcon={<MoreVert/>}
                        variant="outlined"
                        style={{cursor: "pointer"}}
                    />
                ),
            },
        ];
        const getRowStyle = (params) => ({
            backgroundColor: params.row.billing_enabled ? "transparent" : "#f0f0f0",
            color: params.row.billing_enabled ? "inherit" : "#888",
            pointerEvents: params.row.billing_enabled ? "auto" : "none",
            opacity: params.row.billing_enabled ? 1 : 0.6,
        });

        const billingColumns = [
            {
                field: "user",
                headerName: "Name",
                flex: 1,
                minWidth: 120,
                sortOrder: "asc",
                renderCell: (params) => (
                    <div className="userListUser" style={getRowStyle(params)}>
                        {params.row.first_name} {params.row.last_name}
                    </div>
                ),
            },
            {
                field: "house_number",
                headerName: "HSE NO",
                flex: 1,
                minWidth: 80,
                renderCell: (params) => (
                    <div style={getRowStyle(params)}>{params.row.house_number}</div>
                ),
            },
            {
                field: "rent",
                headerName: "Rent",
                flex: 1,
                minWidth: 50,
                renderCell: (params) => (
                    <div style={{...getRowStyle(params), color: "green"}}>
                        {parseInt(params.row.rent.toString().replace(",", "")).toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                ),
            },
            {
                field: "bill",
                headerName: "Wallet",
                minWidth: 120,
                flex: 1,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => (
                    <span
                        style={{
                            ...getRowStyle(params),
                            color: params.row.status === "PENDING" ? "#D32F2F" : "#388E3C",
                        }}
                    >
                {params.row.Wallet.toLocaleString("en-US", {
                    style: "currency",
                    currency: "KSH",
                })}
            </span>
                ),
            },

            {
                field: "meter_reading", headerName: "Water", flex: 1, minWidth: 120, // align: "center",
                renderCell: (params) => {
                    return <div>{params.row.meter_reading}</div>;
                },
            },
            {
                field: "current_meter_reading", headerName: "Water Reading", flex: 1, minWidth: 100, // align: "center",
                renderCell: (params) => {
                    return (<div className="userListAction">
                        <TextField
                            type={"number"}
                            placeholder="Enter Reading ..."
                            onChange={(e) => {
                                let filteredTenantArray = billedTenants.filter((tenant) => {
                                    return tenant.tenant_id !== params.row.tenant_id;
                                });

                                if (e.target.value === undefined || e.target.value === null || e.target.value === "") {
                                    return;
                                }

                                let consumedUnits = e.target.value - params.row.meter_reading;
                                consumedUnits = consumedUnits.toFixed(1);

                                let finalWaterBill = parseFloat(consumedUnits) * parseInt(waterBill);

                                if (finalWaterBill < minimumWaterBill) {
                                    finalWaterBill = minimumWaterBill;
                                }

                                setBilledTenants([...filteredTenantArray, {
                                    tenant_id: params.row.tenant_id,
                                    rent: parseInt(params.row.rent.replace(",", "")),
                                    arrears: parseInt(params.row.arrears),
                                    finalWaterBill: parseFloat(finalWaterBill),
                                    total_bill: parseInt(params.row.rent.replace(",", "")) + parseInt(params.row.arrears) + parseFloat(finalWaterBill),
                                    meter_reading: e.target.value,
                                    landlord_id: JSON.parse(originalText)[0][1],
                                },]);
                            }}
                            disabled={!params.row.billing_enabled}
                        />
                    </div>);
                },
            },

            {
                field: "electricity_meter_reading", headerName: "Elec Reading", flex: 1, minWidth: 80,
            },
            {
                field: "current_electricity_meter_reading",
                headerName: "Electricity Reading",
                flex: 1,
                minWidth: 120,
                renderCell: (params) => {
                    return (<div className="userListAction">
                        <TextField
                            type={"number"}
                            placeholder="Enter Reading ..."
                            onChange={(e) => {
                                let filteredTenantArray = elecBilledTenants.filter((tenant) => {
                                    return tenant.tenant_id !== params.row.tenant_id;
                                });
                                if (e.target.value === undefined || e.target.value === null || e.target.value === "") {
                                    return;
                                }
                                let consumedUnits = e.target.value - params.row.electricity_meter_reading;
                                consumedUnits = consumedUnits.toFixed(1);

                                // console.log(
                                //   "Prior elec reading: " + params.row.electricity_meter_reading
                                // );
                                // console.log("Current elec reading: " + e.target.value);
                                // console.log("Consumed units: " + consumedUnits);
                                let finalElecBill = parseFloat(consumedUnits) * parseInt(electricityBill);

                                // console.log("Final elec bill: " + finalElecBill);

                                if (finalElecBill < minimumElectricityBill) {
                                    finalElecBill = minimumElectricityBill;
                                }

                                setElecBilledTenants([...filteredTenantArray, {
                                    tenant_id: params.row.tenant_id,
                                    rent: parseInt(params.row.rent.replace(",", "")),
                                    arrears: parseInt(params.row.arrears),
                                    electricity_bill: parseFloat(finalElecBill),
                                    total_bill: parseInt(params.row.rent.replace(",", "")) + parseInt(params.row.arrears) + parseFloat(finalElecBill),
                                    electricity_meter_reading: e.target.value,
                                    landlord_id: JSON.parse(originalText)[0][1],
                                },]);
                            }}
                            disabled={!params.row.billing_enabled}
                        />
                    </div>);
                },
            },

            {
                field: "billing_enabled", headerName: "Bill tenant?", flex: 1, minWidth: 100, // align: "center",
                renderCell: (params) => {
                    return (<div className="userListAction">
                        {params.row.billing_enabled ? (<Switch
                            onChange={() => {
                                // console.log("Before changing : " + tenantsLength);
                                //API call to disable tenant billing

                                fetch(ApiDetails + "pegasus/visionary/tenant/billing", {
                                    method: "POST", body: JSON.stringify({
                                        id: params.row.tenant_id, value: false,
                                    }),
                                })
                                    .then(async (response) => {
                                        if (response.status === 200) {
                                            // getPropertyDetails();

                                            //Remove electricity billing if any
                                            let removedElecBill;
                                            let filteredTenantArray = elecBilledTenants.filter((tenants) => {
                                                if (tenants.tenant_id !== params.row.tenant_id) {
                                                    return tenants
                                                } else {
                                                    removedElecBill = tenants
                                                }
                                                // return tenant.tenant_id !== params.row.tenant_id;
                                            });
                                            setElecBilledTenants([...filteredTenantArray]);

                                            // console.log("Stima billed number : "+filteredTenantArray.length)

                                            //Add to removed electricity billings
                                            setRemovedElectricityBilling([...removedElectricityBilling, removedElecBill])

                                            //Remove water billing if any
                                            let removedWaterBill;
                                            let filteredTenantArray2 = billedTenants.filter((tenants) => {
                                                if (tenants.tenant_id !== params.row.tenant_id) {
                                                    return tenants
                                                } else {
                                                    removedWaterBill = tenants
                                                }
                                            });
                                            setBilledTenants([...filteredTenantArray2]);
                                            // console.log("Water billed number : "+filteredTenantArray2.length)


                                            //Add to removed water billings
                                            setRemovedWaterBilling([...removedWaterBilling, removedWaterBill])

                                            tenantsLength = tenantsLength - 1;
                                            setTenantsLength((prevLength) => Math.max(0, prevLength - 1));
                                            // tenantsLength = tenantsLength - 1;
                                            console.log("After changing : " + tenantsLength);

                                            // setUpdateState(!updateState);
                                            RunUpdateTenants(!update)
                                        } else {


                                            showSnackbar({
                                                title: "Error!",
                                                message: "An error occurred while billing tenant. Kindly try again later",
                                                type: "error", // Options: success, error, warning, info
                                                position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);

                                        showSnackbar({
                                            title: "Error!",
                                            message: "An error occurred while billing tenant. Kindly try again later",
                                            type: "error", // Options: success, error, warning, info
                                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                        });
                                    });
                            }}
                            {...label}
                            defaultChecked
                        />) : (<Switch
                            onChange={() => {


                                fetch(ApiDetails + "pegasus/visionary/tenant/billing", {
                                    method: "POST", body: JSON.stringify({
                                        id: params.row.tenant_id, value: true,
                                    }),
                                })
                                    .then(async (response) => {
                                        if (response.status === 200) {

                                            //Get removed electricity billing if any
                                            if (removedElectricityBilling !== undefined && removedElectricityBilling.length > 0) {
                                                let removedElecBill;
                                                let filteredTenantArray = removedElectricityBilling.filter((tenants) => {

                                                    if (tenants !== undefined) {
                                                        if (tenants.tenant_id !==
                                                            params.row.tenant_id) {
                                                            return tenants;
                                                        } else {
                                                            removedElecBill = tenants
                                                        }
                                                    }
                                                });

                                                //Return electricity bill to map
                                                setElecBilledTenants([...elecBilledTenants, removedElecBill]);

                                                //Set removed electricity bill to new value
                                                setRemovedElectricityBilling([...filteredTenantArray])
                                            }


                                            //Get removed water billing if any
                                            if ((removedWaterBilling !== undefined && removedWaterBilling.length > 0)) {
                                                let removedWaterBill;
                                                let filteredTenantArray2 = removedWaterBilling.filter((tenants) => {

                                                    if (tenants !== undefined) {

                                                        if (tenants.tenant_id !== params.row.tenant_id) {
                                                            return tenants;
                                                        } else {
                                                            removedWaterBill = tenants
                                                        }
                                                    }
                                                    // return tenant.tenant_id !== params.row.tenant_id;
                                                });

                                                //Return water bill to map
                                                setBilledTenants([...billedTenants, removedWaterBill]);

                                                //Set removed water bill to new value
                                                setRemovedWaterBilling([...filteredTenantArray2])
                                            }


                                            // setUpdateState(!updateState);
                                            tenantsLength = tenantsLength + 1;
                                            setTenantsLength((prevLength) => Math.max(0, prevLength + 1));

                                            RunUpdateTenants(!update)

                                        } else {
                                            showSnackbar({
                                                title: "Error!",
                                                message: "Failed updating tenant's details",
                                                type: "error", // Options: success, error, warning, info
                                                position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);

                                        showSnackbar({
                                            title: "Error!",
                                            message: "Something went wrong while updating tenant's details",
                                            type: "error", // Options: success, error, warning, info
                                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                        });
                                    });
                            }}
                            {...label}
                        />)}
                    </div>);
                },
            },
        ];

        /*

            const billingColumns =
                [
                    {
                        field: "user", headerName: "Name", flex: 1, minWidth: 120, sortOrder: "asc", renderCell: (params) => {
                            return (<div className="userListUser">
                                {params.row.first_name} {params.row.last_name}
                            </div>);
                        },
                    },
                    {
                        field: "house_number", // align: "center",
                        headerName: "HSE NO", flex: 1, minWidth: 80,
                    },
                    {
                        field: "rent", headerName: "Rent", flex: 1, minWidth: 50, // align: "center",
                        renderCell: (params) => {
                            return (<div style={{color: "green"}}>
                                {parseInt(params.row.rent.toString().replace(",", "")).toLocaleString("en-US", {
                                    style: "currency", currency: "KSH",
                                })}
                            </div>);
                        },
                    },
                    {
                        field: "bill",
                        headerName: "Wallet",
                        minWidth: 120,
                        flex: 1,
                        headerAlign: "center",
                        align: "center",
                        renderCell: (params) => (
                            <span style={{color: params.row.status === "PENDING" ? "#D32F2F" : "#388E3C"}}>
                  {params.row.Wallet.toLocaleString("en-US", {
                      style: "currency",
                      currency: "KSH",
                  })}
                </span>
                        ),
                    },
                    {
                        field: "meter_reading", headerName: "Water", flex: 1, minWidth: 120, // align: "center",
                        renderCell: (params) => {
                            return <div>{params.row.meter_reading}</div>;
                        },
                    },
                    {
                        field: "current_meter_reading", headerName: "Water Reading", flex: 1, minWidth: 100, // align: "center",
                        renderCell: (params) => {
                            return (<div className="userListAction">
                                <TextField
                                    type={"number"}
                                    placeholder="Enter Reading ..."
                                    onChange={(e) => {
                                        let filteredTenantArray = billedTenants.filter((tenant) => {
                                            return tenant.tenant_id !== params.row.tenant_id;
                                        });

                                        if (e.target.value === undefined || e.target.value === null || e.target.value === "") {
                                            return;
                                        }

                                        let consumedUnits = e.target.value - params.row.meter_reading;
                                        consumedUnits = consumedUnits.toFixed(1);

                                        let finalWaterBill = parseFloat(consumedUnits) * parseInt(waterBill);

                                        if (finalWaterBill < minimumWaterBill) {
                                            finalWaterBill = minimumWaterBill;
                                        }

                                        setBilledTenants([...filteredTenantArray, {
                                            tenant_id: params.row.tenant_id,
                                            rent: parseInt(params.row.rent.replace(",", "")),
                                            arrears: parseInt(params.row.arrears),
                                            finalWaterBill: finalWaterBill,
                                            total_bill: parseInt(params.row.rent.replace(",", "")) + parseInt(params.row.arrears) + parseFloat(finalWaterBill),
                                            meter_reading: e.target.value,
                                            landlord_id: JSON.parse(originalText)[0][1],
                                        },]);
                                    }}
                                    disabled={!params.row.billing_enabled}
                                />
                            </div>);
                        },
                    },
                    {
                        field: "electricity_meter_reading", headerName: "Elec Reading", flex: 1, minWidth: 80,
                    },
                    {
                        field: "current_electricity_meter_reading",
                        headerName: "Electricity Reading",
                        flex: 1,
                        minWidth: 120,
                        renderCell: (params) => {
                            return (<div className="userListAction">
                                <TextField
                                    type={"number"}
                                    placeholder="Enter Reading ..."
                                    onChange={(e) => {
                                        let filteredTenantArray = elecBilledTenants.filter((tenant) => {
                                            return tenant.tenant_id !== params.row.tenant_id;
                                        });
                                        if (e.target.value === undefined || e.target.value === null || e.target.value === "") {
                                            return;
                                        }
                                        let consumedUnits = e.target.value - params.row.electricity_meter_reading;
                                        consumedUnits = consumedUnits.toFixed(1);

                                        // console.log(
                                        //   "Prior elec reading: " + params.row.electricity_meter_reading
                                        // );
                                        // console.log("Current elec reading: " + e.target.value);
                                        // console.log("Consumed units: " + consumedUnits);
                                        let finalElecBill = parseFloat(consumedUnits) * parseInt(electricityBill);

                                        // console.log("Final elec bill: " + finalElecBill);

                                        if (finalElecBill < minimumElectricityBill) {
                                            finalElecBill = minimumElectricityBill;
                                        }

                                        setElecBilledTenants([...filteredTenantArray, {
                                            tenant_id: params.row.tenant_id,
                                            rent: parseInt(params.row.rent.replace(",", "")),
                                            arrears: parseInt(params.row.arrears),
                                            electricity_bill: finalElecBill,
                                            total_bill: parseInt(params.row.rent.replace(",", "")) + parseInt(params.row.arrears) + parseFloat(finalElecBill),
                                            electricity_meter_reading: e.target.value,
                                            landlord_id: JSON.parse(originalText)[0][1],
                                        },]);
                                    }}
                                    disabled={!params.row.billing_enabled}
                                />
                            </div>);
                        },
                    },
                    {
                        field: "billing_enabled", headerName: "Bill tenant?", flex: 1, minWidth: 100, // align: "center",
                        renderCell: (params) => {
                            return (<div className="userListAction">
                                {params.row.billing_enabled ? (<Switch
                                    onChange={() => {
                                        // console.log("Before changing : " + tenantsLength);
                                        //API call to disable tenant billing

                                        fetch(ApiDetails + "pegasus/visionary/tenant/billing", {
                                            method: "POST", body: JSON.stringify({
                                                id: params.row.tenant_id, value: false,
                                            }),
                                        })
                                            .then(async (response) => {
                                                if (response.status === 200) {
                                                    // getPropertyDetails();

                                                    //Remove electricity billing if any
                                                    let removedElecBill;
                                                    let filteredTenantArray = elecBilledTenants.filter((tenant) => {
                                                        if (tenant.tenant_id !== params.row.tenant_id) {
                                                            return tenant
                                                        } else {
                                                            removedElecBill = tenant
                                                        }
                                                        // return tenant.tenant_id !== params.row.tenant_id;
                                                    });
                                                    setElecBilledTenants([...filteredTenantArray]);

                                                    // console.log("Stima billed number : "+filteredTenantArray.length)

                                                    //Add to removed electricity billings
                                                    setRemovedElectricityBilling([...removedElectricityBilling, removedElecBill])

                                                    //Remove water billing if any
                                                    let removedWaterBill;
                                                    let filteredTenantArray2 = billedTenants.filter((tenant) => {
                                                        if (tenant.tenant_id !== params.row.tenant_id) {
                                                            return tenant
                                                        } else {
                                                            removedWaterBill = tenant
                                                        }
                                                    });
                                                    setBilledTenants([...filteredTenantArray2]);
                                                    // console.log("Water billed number : "+filteredTenantArray2.length)


                                                    //Add to removed water billings
                                                    setRemovedWaterBilling([...removedWaterBilling, removedWaterBill])

                                                    tenantsLength = tenantsLength - 1;
                                                    setTenantsLength((prevLength) => Math.max(0, prevLength - 1));
                                                    // tenantsLength = tenantsLength - 1;
                                                    console.log("After changing : " + tenantsLength);

                                                    // setUpdateState(!updateState);
                                                } else {


                                                    showSnackbar({
                                                        title: "Error!",
                                                        message: "An error occurred while billing tenant. Kindly try again later",
                                                        type: "error", // Options: success, error, warning, info
                                                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                                    });
                                                }
                                            })
                                            .catch((err) => {
                                                console.log(err);

                                                showSnackbar({
                                                    title: "Error!",
                                                    message: "An error occurred while billing tenant. Kindly try again later",
                                                    type: "error", // Options: success, error, warning, info
                                                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                                });
                                            });
                                    }}
                                    {...label}
                                    defaultChecked
                                />) : (<Switch
                                    onChange={() => {

                                        fetch(ApiDetails + "pegasus/visionary/tenant/billing", {
                                            method: "POST", body: JSON.stringify({
                                                id: params.row.tenant_id, value: true,
                                            }),
                                        })
                                            .then(async (response) => {
                                                if (response.status === 200) {

                                                    //Get removed electricity billing if any
                                                    let removedElecBill;
                                                    let filteredTenantArray = removedElectricityBilling.filter((tenant) => {
                                                        if (tenant.tenant_id !== params.row.tenant_id) {
                                                            return tenant;
                                                        } else {
                                                            removedElecBill = tenant
                                                        }
                                                    });

                                                    //Return electricity bill to map
                                                    setElecBilledTenants([...elecBilledTenants, removedElecBill]);

                                                    //Set removed electricity bill to new value
                                                    setRemovedElectricityBilling([...filteredTenantArray])


                                                    //Get removed water billing if any
                                                    let removedWaterBill;
                                                    let filteredTenantArray2 = removedWaterBilling.filter((tenant) => {
                                                        if (tenant.tenant_id !== params.row.tenant_id) {
                                                            return tenant;
                                                        } else {
                                                            removedWaterBill = tenant
                                                        }
                                                        // return tenant.tenant_id !== params.row.tenant_id;
                                                    });

                                                    //Return water bill to map
                                                    setBilledTenants([...billedTenants, removedWaterBill]);

                                                    //Set removed water bill to new value
                                                    setRemovedWaterBilling([...filteredTenantArray2])

                                                    // setUpdateState(!updateState);
                                                    tenantsLength = tenantsLength + 1;
                                                    setTenantsLength((prevLength) => Math.max(0, prevLength + 1));

                                                } else {
                                                    showSnackbar({
                                                        title: "Error!",
                                                        message: "Failed updating tenant's details",
                                                        type: "error", // Options: success, error, warning, info
                                                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                                    });
                                                }
                                            })
                                            .catch((err) => {
                                                console.log(err);

                                                showSnackbar({
                                                    title: "Error!",
                                                    message: "Failed updating tenant's details",
                                                    type: "error", // Options: success, error, warning, info
                                                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                                                });
                                            });
                                    }}
                                    {...label}
                                />)}
                            </div>);
                        },
                    },
                ];
        */


        const handleClose = () => {
            // setBillTenantsModal(false);
        };

        const [htmlContent, setHtmlContent] = useState('');

        const billTenants = async () => {
            console.log(billedTenants);
            console.log(elecBilledTenants);

            setBillLoading(true);

            /*
                    if (currentTenants !== null) {
            */
            if (// billedTenants.length < currentTenants.length ||
                // elecBilledTenants.length < currentTenants.length
                billedTenants.length < tenantsLength || elecBilledTenants.length < tenantsLength) {

                showSnackbar({
                    title: "Error!",
                    message: "Please Make sure to bill all tenants both water and electricity",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });

                setBillLoading(false);

                return;
            }
            /*        } else {
                        // console.log("Billed tenants :" + billedTenants.length);
                        // console.log("All tenants :" + tenantsLength);
                        if (// billedTenants.length < tenants.length ||
                            // elecBilledTenants.length < tenants.length
                            billedTenants.length < tenantsLength || elecBilledTenants.length < tenantsLength) {
                            console.log("here");
                            props.snackBar({
                                text: "Please Make sure to bill all tenants both water and electricity", type: "error",
                            });
                            setBillLoading(false);

                            return;
                        }
                    }*/

            let finalBill = [];

            if (billedTenants > elecBilledTenants) {
                billedTenants.forEach((tenant) => {
                    elecBilledTenants.forEach((tenant2) => {
                        if (tenant.tenant_id === tenant2.tenant_id) {
                            finalBill = [...finalBill, {
                                tenant_id: tenant.tenant_id,
                                water_bill: tenant.finalWaterBill,
                                electricity_bill: tenant2.electricity_bill,
                                total_bill: parseInt(tenant.rent.replace(",", "")) + parseInt(tenant.arrears) + parseFloat(tenant2.electricity_bill) + parseFloat(tenant.finalWaterBill),
                                meter_reading: tenant.meter_reading,
                                electricity_meter_reading: tenant2.electricity_meter_reading,
                                landlord_id: JSON.parse(originalText)[0][1],
                            },];
                            billedTenants = billedTenants.filter((tenant) => tenant.tenant_id !== tenant2.tenant_id);
                            elecBilledTenants = elecBilledTenants.filter((tenant) => tenant.tenant_id !== tenant2.tenant_id);
                        }
                    });
                });
            } else {
                elecBilledTenants.forEach((tenant) => {
                    billedTenants.forEach((tenant2) => {
                        if (tenant.tenant_id === tenant2.tenant_id) {
                            finalBill = [...finalBill, {
                                tenant_id: tenant.tenant_id,
                                water_bill: tenant2.finalWaterBill,
                                electricity_bill: tenant.electricity_bill,
                                total_bill: parseInt(tenant.rent) + parseInt(tenant.arrears) + parseFloat(tenant.electricity_bill) + parseFloat(tenant2.finalWaterBill),
                                meter_reading: tenant2.meter_reading,
                                electricity_meter_reading: tenant.electricity_meter_reading,
                                landlord_id: JSON.parse(originalText)[0][1],
                            },];
                            billedTenants = billedTenants.filter((tenant) => tenant.tenant_id !== tenant2.tenant_id);
                            elecBilledTenants = elecBilledTenants.filter((tenant) => tenant.tenant_id !== tenant2.tenant_id);
                        }
                    });
                });
            }

            let finalWaterBills = [];
            billedTenants.forEach((reading) => {
                reading = {
                    tenant_id: reading.tenant_id,
                    water_bill: reading.finalWaterBill,
                    electricity_bill: 0,
                    total_bill: parseInt(reading.rent) + parseInt(reading.arrears) + parseFloat(reading.finalWaterBill),
                    meter_reading: reading.meter_reading,
                    electricity_meter_reading: 0,
                    landlord_id: JSON.parse(originalText)[0][1],
                };
                finalWaterBills = [...finalWaterBills, reading];
            });

            let finalElectricityBills = [];
            elecBilledTenants.forEach((reading) => {
                reading = {
                    tenant_id: reading.tenant_id,
                    water_bill: 0,
                    electricity_bill: reading.electricity_bill,
                    total_bill: parseInt(reading.rent) + parseInt(reading.arrears) + parseFloat(reading.electricity_bill),
                    meter_reading: 0,
                    electricity_meter_reading: reading.electricity_meter_reading,
                    landlord_id: JSON.parse(originalText)[0][1],
                };
                finalElectricityBills = [...finalElectricityBills, reading];
            });

            finalBill = [...finalBill, ...finalWaterBills, ...finalElectricityBills];

            // console.log("Billed tenants :" + billedTenants.length);
            // console.log("All tenants :" + tenantsLength);
            if (finalBill.length < tenantsLength) {
                // console.log("here");
                showSnackbar({
                    title: "Error!",
                    message: "Please Make sure to bill all tenants",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });

                setBillLoading(false);

                return;
            }

            if (selectedMonth === null) {
                showSnackbar({
                    title: "Error!",
                    message: "Kindly select the month you are billing for",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });

                setBillLoading(false);
                return;
            }

            let continueBilling = false;

            await confirm({
                description: `You are about to bill for the month of 
         ${getMonthName(parseInt(month.getMonth())).toUpperCase()} , ${parseInt(month.getFullYear())}`
            })
                .then(() => {
                    continueBilling = true
                })
                .catch(() => continueBilling = false);

            if (!continueBilling) {
                fetch(ApiDetails + "pegasus/visionary/property/enableBilling", {
                    method: "POST", body: JSON.stringify({
                        property_id: propertyDetails.property_id,
                    }),
                })
                    .then(async (response) => {
                        if (response.status === 200) {
                            window.location.reload(false);
                        } else {
                            showSnackbar({
                                title: "Error!",
                                message: "Failed updating tenant's details",
                                type: "error", // Options: success, error, warning, info
                                position: {vertical: "bottom", horizontal: "right"}, // Options for position
                            });

                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        showSnackbar({
                            title: "Error!",
                            message: "Failed updating tenant's details",
                            type: "error", // Options: success, error, warning, info
                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                        });

                    });
                return;
            }


            //Make Billing API Call
            fetch(ApiDetails + "pegasus/visionary/property/bill/tenants", {
                method: "POST", body: JSON.stringify([...finalBill, {
                    month: parseInt(month.getMonth()),
                    year: parseInt(month.getFullYear()),
                    property_id: propertyDetails.property_id,
                    property_name: propertyDetails.property_name,
                    email_address: JSON.parse(originalText)[5][1],
                },]),
            })
                .then(async (response) => {
                    let data = await response.json();
                    if (response.status === 200) {
                        setHtmlContent(data.htmlReport);

                        showSnackbar({
                            title: "Success!",
                            message: "Tenants billed successfully",
                            type: "success", // Options: success, error, warning, info
                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                        });
                        setBillLoading(false);
                        // window.location.reload(false);

                    } else {
                        showSnackbar({
                            title: "Error!",
                            message: "Failed billing tenants",
                            type: "error", // Options: success, error, warning, info
                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                        });
                        setBillLoading(false);

                        handleClose();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong while billing tenants",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                    setBillLoading(false);

                    handleClose();
                });
        };

        const enableBilling = () => {
            fetch(ApiDetails + "pegasus/visionary/property/enableBilling", {
                method: "POST",
                body: JSON.stringify({property_id: propertyDetails.property_id}),
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        window.location.reload(false);
                    } else {
                        showSnackbar({
                            title: "Error!",
                            message: "Failed updating tenant's details",
                            type: "error",
                            position: {vertical: "bottom", horizontal: "right"},
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    showSnackbar({
                        title: "Error!",
                        message: "Failed updating tenant's details",
                        type: "error",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                });
            handleClose();
        };

        const downloadPDF = () => {
            //Make Billing API Call
            fetch(ApiDetails + "pegasus/visionary/property/bill/report", {
                method: "POST", body: JSON.stringify({
                    pdf: htmlContent
                }),
            })
                .then(async (response) => {
                    if (response.status === 200) {

                        let fileName;

                        for (let pair of response.headers.entries()) {
                            // accessing the entries
                            if (pair[0] === "content-disposition") {
                                // key I'm looking for in this instance
                                fileName = pair[1]; // saving that value where I can use it
                            }
                        }

                        showSnackbar({
                            title: "Success!",
                            message: "File download will start shortly",
                            type: "success", // Options: success, error, warning, info
                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                        });


                        let blob = await response.blob();

                        let href = window.URL.createObjectURL(blob);
                        // create "a" HTML element with href to file & click
                        const link = document.createElement("a");
                        link.href = href;
                        link.setAttribute("download", fileName); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);

                        handleClose();


                    } else {
                        showSnackbar({
                            title: "Error!",
                            message: "Failed downloading report",
                            type: "error", // Options: success, error, warning, info
                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                        });
                        setBillLoading(false);

                        handleClose();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong while downloading report",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                    setBillLoading(false);

                    handleClose();
                });
        };

        return (

            <>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={billTenantsModal}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle sx={styles.dialogTitle}>Billing Tenants</DialogTitle>

                    <DialogContent sx={styles.dialogContent}>

                        {htmlContent !== "" ? (
                                <div style={{borderRadius: '10px'}}>
                                    {/* Render the HTML content */}
                                    <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
                                </div>

                            ) :
                            (
                                <Box sx={styles.container}>

                                    {/* Billing Month Selection */}
                                    <Box sx={styles.billingHeader}>
                                        <Typography sx={styles.label}>Billing for which month?</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                id="outlined-basic"
                                                variant="outlined"
                                                label="Select Month"
                                                openTo="year"
                                                views={["year", "month"]}
                                                value={month}
                                                onChange={(newValue) => {
                                                    setMonth(newValue);
                                                    setSelectedMonth(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>

                                    {/* Data Grid for Tenants */}
                                    <StyledDataGrid
                                        initialState={{sorting: {sortModel: [{field: "house_number", sort: "asc"}]}}}
                                        rows={tenants}
                                        columns={billingColumns}
                                        disableSelectionOnClick
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                    />
                                </Box>


                            )
                        }

                    </DialogContent>

                    {htmlContent !== "" ? (
                            <DialogActions sx={{padding: "16px"}}>

                                {/* <div className="download-btn text-center mt-4">
                                <button className="btn btn-primary" onClick={downloadPDF}>
                                    Download PDF
                                </button>
                            </div>*/}

                                <Button onClick={enableBilling} variant="outlined" sx={styles.cancelButton}>
                                    Cancel
                                </Button>

                            </DialogActions>
                        ) :
                        (
                            <DialogActions sx={{padding: "16px"}}>
                                {/* Cancel Button */}
                                <Button onClick={enableBilling} variant="outlined" sx={styles.cancelButton}>
                                    Cancel
                                </Button>

                                {/* Bill Button */}
                                <LoadingButton
                                    size="medium"
                                    onClick={billTenants}
                                    loading={billLoading}
                                    startIcon={<SendIcon/>}
                                    variant="contained"
                                    sx={styles.billButton}
                                    loadingPosition="start"
                                >
                                    Bill
                                </LoadingButton>
                            </DialogActions>
                        )
                    }
                </Dialog>

                <StyledDataGrid
                    rows={tenants}
                    columns={columns}
                    disableSelectionOnClick
                    pageSize={5}
                    rowsPerPageOptions={[5, 10]}
                    autoHeight
                />
            </>
        );
    }
;

export default PropertyTenants;
