import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useNavigate } from "react-router-dom";
import "./authentication.css";
import { ApiDetails, KEY, SKY_AUTH_ApiDetails } from "../../dummyData";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import CryptoJS from "crypto-js";
import { TextField } from "@material-ui/core";
import { Login } from "@mui/icons-material";

export default function LoginDialog(props) {
  props.snackBar({
    text: "An error occurred. Kindly try logging again",
    type: "error",
  });
  let CryptoJS = require("crypto-js");
  const [open, setOpen] = React.useState(true);
  let email;

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const confirmAuthCode = async (event) => {
    event.preventDefault();
    let code = document.getElementById("auth_code").value;
    if (code === "") {
      props.snackBar({
        text: "Code Can Not Be Empty.",
        type: "error",
      });
      return;
    }
    let body = {
      auth_code: code,
      identifier_type: "MAIL",
      identifier: email,
      program_id: parseInt("100009"),
    };
    fetch(SKY_AUTH_ApiDetails + "/sky-auth/confirmation", {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          let details = Array.from(JSON.parse(event.data));
          handleClose();
          getUserDetails(details);
        } else {
          props.snackBar({
            text: "Invalid Code Provided.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Try Again Later",
          type: "error",
        });
      });
  };
  const [loading, setLoading] = React.useState(false);
  const [proceed, setProceed] = React.useState(false);
  const onSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    // email = document.getElementById("login_email").value;
    let body = {
      password: document.getElementById("login_password").value,
      email_address: document.getElementById("login_email").value,
    };
    const today = new Date();
    const yyyy = today.getFullYear();
    let MM = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hh = today.getHours();
    let mm = today.getMinutes();
    let ss = today.getSeconds();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    // const formattedToday = dd + "/" + mm + "/" + yyyy;
    const timestamp = yyyy + "" + MM + "" + dd + "" + hh + "" + mm + "" + ss;

    // body = CryptoJS.AES.encrypt(
    //   JSON.stringify(body),
    //   timestamp.toString()
    // ).toString();
    // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/authorization/login", {
      method: "POST",
      body: JSON.stringify(body),
      // headers: new Headers({
      //   Timestamp: timestamp.toString(),
      // }),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          // return;
          let two_fa = new Map(Object.entries(data)).get("two_factor_enabled");

          if (two_fa === "YES") {
            //Create SSE Connection
            // handleClose();

            try {
              await fetchEventSource(
                SKY_AUTH_ApiDetails + "/sky-auth/confirmation/sse/100009",
                {
                  method: "GET",
                  headers: {
                    Accept: "text/event-stream",
                  },
                  onopen(res) {
                    if (res.ok && res.status === 200) {
                      console.log("Connection made ", res);

                      //Get Qr Code
                      //program_id,identifier,identifier_type
                      const body = {
                        identifier_type: "MAIL",
                        identifier:
                          document.getElementById("login_email").value,
                        program_id: parseInt("100009"),
                      };
                      fetch(
                        SKY_AUTH_ApiDetails +
                          "/sky-auth/authorization/biometric/add",
                        {
                          method: "POST",
                          body: JSON.stringify(body),
                        }
                      )
                        .then(async (response) => {
                          let data = await response.json();
                          if (response.status === 200) {
                            // console.log(data);
                            let base_64_image = new Map(
                              Object.entries(data)
                            ).get("Base64 QR image");

                            setLoginPage(
                              <div className="Authentication">
                                <div className="register">
                                  <div className="col-1-auth">
                                    <form id="form" className="flex flex-col">
                                      <label
                                        htmlFor="login_email"
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                        }}
                                      >
                                        <h3> Sky Auth Code</h3>
                                      </label>
                                      <input type="number" id="auth_code" />

                                      <button
                                        className="btn"
                                        onClick={(event) => {
                                          confirmAuthCode(event);
                                        }}
                                      >
                                        Verify Code
                                      </button>
                                      <br />
                                      <span
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        Kindly enter auth code or scan the QR
                                        code before the timer expires
                                      </span>
                                    </form>
                                  </div>
                                  <div className="col-2-auth">
                                    {/*CountDown*/}

                                    <img
                                      style={{
                                        height: "calc(100% - 30px)",
                                      }}
                                      src={
                                        "data:image/png;base64," + base_64_image
                                      }
                                      alt="Red dot"
                                    />
                                    {/*<img src={require("../../assets/img1.jpg")} alt="" />*/}
                                  </div>
                                </div>
                              </div>
                            );
                            setProceed(true);
                          } else {
                            props.snackBar({
                              text: "Invalid Code Provided.",
                              type: "error",
                            });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          props.snackBar({
                            text: "Something Went Wrong. Try Again Later",
                            type: "error",
                          });
                        });
                    } else if (
                      res.status >= 400 &&
                      res.status < 500 &&
                      res.status !== 429
                    ) {
                      console.log("Client side error ", res);
                    }
                  },
                  onmessage(event) {
                    let notifications = Array.from(JSON.parse(event.data));

                    handleClose();
                    getUserDetails(data);

                    // console.log(event.data);
                  },
                  onclose() {
                    console.log(
                      "Connection closed by the server retrying ... "
                    );
                  },
                  onerror(err) {
                    console.log("There was an error from server", err);
                  },
                }
              );
            } catch (e) {
              console.log(e);
            }
          } else {
            handleClose();
            getUserDetails(data);
          }
        } else if (response.status === 401) {
          setLoading(false);
          props.snackBar({
            text: "Maximum concurrent logins reached",
            type: "warning",
          });
        } else if (response.status === 400) {
          props.snackBar({
            text: "Invalid Credentials Provided.",
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Try Again Later",
          type: "error",
        });
      });
  };

  const getUserDetails = (userDetails) => {
    //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/dashboard/get/details", {
      method: "POST",
      body: JSON.stringify(userDetails),
    })
      .then(async (response) => {
        // console.log(userDetails);
        let data = await response.json();
        if (response.status === 200) {
          setLoading(false);
          // props.topBar();
          const map1 = new Map(Object.entries(data));
          const details = new Map(Object.entries(userDetails));

          let myMap = CryptoJS.AES.encrypt(
            JSON.stringify(Array.from(details.entries())),
            KEY
          ).toString();
          let portfolioDetails = CryptoJS.AES.encrypt(
            JSON.stringify(Array.from(map1.entries())),
            KEY
          ).toString();

          // let decrypted = CryptoJS.AES.decrypt(myMap, KEY);
          // var originalText = decrypted.toString(CryptoJS.enc.Utf8);
          // console.log(originalText);

          localStorage.setItem("myMap", myMap);
          // localStorage.portfolioDetails = JSON.stringify(Array.from(map1.entries()));

          localStorage.setItem("isNew", userDetails.new);
          localStorage.portfolioDetails = portfolioDetails;

          // return;
          navigate("/home", {
            state: {
              userDetails: userDetails,
            },
          });
          props.refresh(true);
          props.snackBar({ text: "Login Successful", type: "success" });
          props.fn();
        } else {
          setLoading(false);
          props.snackBar({ text: "Failed Fetching Details", type: "error" });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  localStorage.clear();
  const [loginPage, setLoginPage] = useState();
  return (
    <Dialog maxWidth={"md"} open={open}>
      {/*<DialogContent dividers>*/}
      {proceed ? (
        <>{loginPage}</>
      ) : (
        <div
          style={{
            height: "70vh",
            width: "60vw",
          }}
        >
          <div
            className="register"
            style={{
              overflow: "hidden",
            }}
          >
            <div className="col-1-auth">
              <h2>Log In</h2>
              <span
                style={{
                  color: "#adb5bd",
                }}
              >
                Welcome back!
              </span>

              <form
                style={{ width: "100%" }}
                id="form"
                className="flex flex-col"
              >
                <label
                  htmlFor="login_email"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  Email Address
                </label>
                <TextField
                  type="email"
                  id="login_email"
                  variant={"outlined"}
                  placeholder="example@gmail.com"
                />
                <label
                  htmlFor="login_email"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  Password
                </label>
                <TextField
                  type="password"
                  id="login_password"
                  variant={"outlined"}
                  placeholder="password"
                />
                <LoadingButton
                  endIcon={<Login />}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  onClick={async (event) => {
                    onSubmit(event);
                  }}
                >
                  Log In
                </LoadingButton>
                <span>
                  Don't have an account? <a href="/signup">Signup </a>
                </span>
              </form>
            </div>
            <div className="col-2-auth">
              <img src={require("../../assets/img1.jpg")} alt="" />
            </div>
          </div>
        </div>
      )}
      {/*</DialogContent>*/}
    </Dialog>
  );
}
