import React from "react";
import "./Error.css";
export default function Error404() {
    let image = require("../../assets/img/3747371.jpg");
    return (
        <>
            <div
                id={"fullScreenBlurrBackground"}
                style={{
                    display: "block",
                    zIndex: "8999",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                }}
            ></div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                }}
            >
                <div
                    className={"error404"}
                    style={{
                        backgroundImage: `url(${image})`,
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-end",
                        }}
                    >
                        <div
                            style={{
                                marginRight: "10px",
                                fontSize: "15px",
                            }}
                        >
                            Page not found{" "}
                        </div>

                        <a href="/">Login </a>
                    </div>
                </div>
            </div>
        </>
    );
}