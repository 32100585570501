import React, {useEffect, useRef, useState} from "react";
import CustomerPage from "./tenant components/CustomerDetails";
import {useLocation} from "react-router-dom";

export default function TenantV2(props) {
    let {state} = useLocation();
    let {tenant_id} = state;

    return (
        <div>
            <CustomerPage tenant_id={tenant_id}/>
        </div>
    );
}
