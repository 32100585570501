import React, {useEffect, useRef, useState} from "react";
import * as yup from "yup";
import "yup-phone";
import "./Tenant.css";
import "../../../node_modules/draft-js/dist/Draft.css";
import {DeleteOutline, PublishedWithChangesOutlined, RememberMeOutlined, WarningAmber,} from "@mui/icons-material";
import {useLocation} from "react-router-dom";
import {InputLabel, TextField, Typography} from "@material-ui/core";
import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Tooltip from "@mui/material/Tooltip";
import {
    Avatar,
    FormControl,
    FormHelperText,
    Menu,
    MenuItem,
    Select,
    TextareaAutosize,
    useMediaQuery,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {ApiDetails, KEY} from "../../dummyData";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import draftToHtml from "draftjs-to-html";
import NonEditableRichTextEditor from "../../components/RichTextEditorNotEditable/NonEditableRichTextEditor";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import DialogContentText from "@mui/material/DialogContentText";
import RichTextEditor from "../../components/RichTextEditorEditable/RichTextEditor";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import CryptoJS from "crypto-js";
import Textarea from "@mui/joy/Textarea";
import {PulseLoader} from "react-spinners";
import Switch from "@mui/material/Switch";
import {useConfirm} from "material-ui-confirm";
import {BarWave} from "react-cssfx-loading";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import imageAvatar from "../../assets/Tenant_avatar.jpg";
import {
    AccountBalanceOutlined,
    AccountBalanceWallet,
    AttachMoneyOutlined,
    BarChart,
    BugReport,
    CloudDownloadOutlined,
    EditOutlined,
    EmailOutlined,
    EventNote,
    ExpandMoreOutlined,
    GavelRounded,
    HomeOutlined,
    LocalPhoneOutlined,
    Lock,
    MessageOutlined,
    PersonOutlineOutlined,
    PlaylistAddCheckOutlined,
    Receipt,
    StarHalfOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined,
} from "@material-ui/icons";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FloatingButton from "../../components/Add arrear drawer/AddArrear";

const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Tenant(props) {
    const matches = useMediaQuery("(max-width:700px)");

    const [dropDownMenu] = useState(<ExpandMoreOutlined/>);

    const confirm = useConfirm();

    let {state} = useLocation();
    const textRef = useRef();

    let myMapDetailsDecrypted = "";
    let portfolioDetailsDecrypted = "";
    let subscriptionDetails = "";

    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);

        portfolioDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.portfolioDetails, KEY);
        subscriptionDetails = CryptoJS.AES.decrypt(localStorage.subscriptionDetails, KEY);
    } catch (e) {
        console.log(e);
    }

    const [subscription, setSubscription] = useState(subscriptionDetails.toString(CryptoJS.enc.Utf8));
    const [originalText, setOriginalText] = useState(myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8));
    const [originalText2, setOriginalText2] = useState(portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8));

    const landlordId = JSON.parse(originalText)[0][1];

    // console.log("This is the landlord id ")
    // console.log(landlordId)
    // console.log(state.landlord_id)

    // let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
    //     localStorage.getItem("myMap"),
    //     KEY
    // );
    // let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(
    //     localStorage.portfolioDetails,
    //     KEY
    // );
    // let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    // let { tenant, arrears, transactions, propertyUnits } = state;

    const label = {inputProps: {"aria-label": "Switch demo"}};

    //TenantBills
    const [bills, setBills] = useState([]);

    const divRef = useRef(null);

    //Property Image
    const [tenantImage, setTenantImage] = useState(null);

    let {tenant_id} = state;
    const [dataSet, setDataSet] = useState(false);
    const [tenant, setTenant] = useState({
        tenant_id: "",
        landlord_id: "",
        first_name: "",
        house_number: "",
        occupation_or_profession: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        national_id: "",
        next_of_kin_relationship: "",
        next_of_kin_first_name: "",
        next_of_kin_last_name: "",
        next_of_kin_national_id: "",
        next_of_kin_contacts: "",
        is_student: "",
        institution_name: "",
        place_of_work: "",
        reason_for_relocating: "",
        previous_residence: "",
        landlord_email_address: "",
        onboarding_secret: "SECRET",
        property_name: "",
        rent: "",
        property_id: "",
        arrears: "",
        amount: "",
        account_number: "",
        account_balance: "",
        bill: "",
        status: "",
        tenant_photo: "",
        inventory_checklist: "",
        copy_of_contract: "",
        photo_of_national_id: "",
        deposit_held: "",
        secondary_phone_number: "",
        bill_details: false,
        notice: {
            reasons_for_vacating: "",
        },
    });
    const [tenantList, setTenantList] = useState([tenant]);
    const [arrears, setArrears] = useState({});
    const [transactions, setTransactions] = useState({});
    const [notices, setNotices] = useState({});

    //const { tenant, transactions } = state;

    //Loading Screen
    //Loading Screen
    let [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{
                backgroundColor: "rgba(0, 31, 63, 0.7)",
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            onClick={null}
        >
            <BarWave color="#FFA500" width="100px" height="50px" duration="3s"/>
        </Backdrop>
        // <></>
    );

    const [electricityBill, setElectricityBill] = React.useState(
        tenant.electricity_bill
    );
    const [minimumElecBill, setMinimumElecBIll] = React.useState(
        parseFloat(tenant.minimum_electricity_bill)
    );

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);

        return date.toLocaleString("en-US", {month: "long"});
    }

    //Units State
    const [units, setUnits] = React.useState([]);
    const [selectedUnit, setSelectedUnit] = React.useState({});

    const [isStudent, setIsStudent] = React.useState();

    const [waterBill, setWaterBill] = useState();
    const [minimumWaterBill, setMinimumWaterBill] = useState();

    const [addTenantPropertyName, setAddTenantPropertyName] = React.useState();
    let [propertyID, setPropertyID] = React.useState(state && state.property_id);
    let [properties, updateProperties] = React.useState([]);

    const [propertyName, setPropertyName] = useState(
        state && state.property_name
    );

    //Tenant Photo
    const [tenantPhoto, setTenantPhoto] = useState("");
    const [photo, setPhoto] = useState(null);

    //Tenant National ID
    const [photoOfNationalID, setPhotoOfNationalID] = useState("");
    const [nationalID, setNationalID] = useState(null);

    //Tenant Contract
    const [copyOfContract, setCopyOfContract] = useState("");
    const [contract, setContract] = useState(null);

    //Tenant Checklist
    const [inventoryChecklist, setInventoryChecklist] = useState("");
    const [checklist, setChecklist] = useState(null);

    const getTenantBills = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/bills", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: tenant_id,
            }),
        })
            .then(async (response) => {
                let bills = await response.json();
                // console.log(bills);
                if (response.status === 200) {
                    //Fetch tenant transactions
                    setBills(bills);
                    setInvoicesModal(true);
                } else if (response.status === 404) {
                    props.snackBar({
                        text: "Billing record not found",
                        type: "error",
                    });
                } else {
                    props.snackBar({
                        text: "Failed fetching latest bill.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };
    const resendBill = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/resend/bill", {
            method: "POST",
            body: JSON.stringify({
                message: bills[0].invoice_details,
                phone_number: tenant.phone_number,
                secondary_phone_number: tenant.secondary_phone_number,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setInvoicesModal(false);
                    props.snackBar({
                        text: "Bill resent successfully",
                        type: "success",
                    });
                } else {
                    props.snackBar({
                        text: "Failed resending bill.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };

    function handleFile1(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            setPhoto(filename);
            setTenantPhoto(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload An Image File",
                type: "warning",
            });
        }
    }

    function handleFile2(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            setNationalID(filename);
            setPhotoOfNationalID(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload An Image File",
                type: "warning",
            });
        }
    }

    function handleFile3(e) {
        let filename = getFileName(e);
        if (checkTextFile(filename)) {
            setContract(filename);
            setCopyOfContract(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload A text,doc,docx or pdf File",
                type: "warning",
            });
        }
    }

    function handleFile4(e) {
        let filename = getFileName(e);
        if (checkTextFile(filename)) {
            setChecklist(filename);
            setInventoryChecklist(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload A text,doc,docx or pdf File",
                type: "warning",
            });
        }
    }

    const getFileName = (e) => {
        let startIndex =
            e.target.value.indexOf("\\") >= 0
                ? e.target.value.lastIndexOf("\\")
                : e.target.value.lastIndexOf("/");
        let filename = e.target.value.substring(startIndex);
        if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
            filename = filename.substring(1);
        }
        return filename;
    };

    function getExtension(filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
    }

    function checkImageFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "jpg":
            case "jpeg":
            case "png":
            case "pdf":
                //etc
                return true;
        }
        return false;
    }

    function checkTextFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "txt":
            case "doc":
            case "docx":
            case "pdf":
                //etc
                return true;
        }
        return false;
    }

    const [updateState, setUpdateState] = useState(false);

    //Tenant Files in base 64
    const [tenantPhotoBase64, setTenantPhotoBase64] = useState("");
    const [photoOfNationalIDBase64, setPhotoOfNationalIDBase64] = useState("");
    const [copyOfContractBase64, setCopyOfContractBase64] = useState("");
    const [inventoryChecklistBase64, setInventoryChecklistBase64] = useState("");

    const [removedWaterBilling, setRemovedWaterBilling] = useState([]);
    const [removedElectricityBilling, setRemovedElectricityBilling] = useState(
        []
    );

    //Trying To Use UseEffect To Set Component Data
    useEffect(() => {
        fetch(ApiDetails + "pegasus/visionary/tenant/get/specific/tenant", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: tenant_id,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                // console.log(data);
                if (response.status === 200) {
                    //Fetch tenant arrears
                    fetch(ApiDetails + "pegasus/visionary/tenant/get/tenant/arrears", {
                        method: "POST",
                        body: JSON.stringify({
                            tenant_id: tenant_id,
                        }),
                    })
                        .then(async (response) => {
                            let arrears = await response.json();
                            if (response.status === 200) {
                                //Fetch tenant transactions
                                fetch(
                                    ApiDetails +
                                    "pegasus/visionary/tenant/get/tenant/transactions",
                                    {
                                        method: "POST",
                                        body: JSON.stringify({
                                            tenant_id: tenant_id,
                                        }),
                                    }
                                )
                                    .then(async (response) => {
                                        let transactions = await response.json();
                                        if (response.status === 200) {
                                            //fetch property units
                                            fetch(
                                                ApiDetails + "pegasus/visionary/property/getUnits",
                                                {
                                                    method: "POST",
                                                    body: JSON.stringify({
                                                        property_id: data.property_id,
                                                    }),
                                                }
                                            )
                                                .then(async (response) => {
                                                    let units = await response.json();
                                                    if (response.status === 200) {
                                                        setTenant(data);
                                                        setRefundableDeposit(data.deposit_held);
                                                        setDepositDetails(data.deposits);
                                                        setTenantList([data]);
                                                        setArrears(arrears);
                                                        // console.log(new Date(transactions[0].date))
                                                        // console.log((transactions[0].date))
                                                        setTransactions(transactions);
                                                        setDisplayDetails(transactions);
                                                        setUnits(units);
                                                        setWaterBill(data.water_bill);
                                                        setMinimumWaterBill(data.minimum_water_bill);

                                                        setElectricityBill(data.electricity_bill);
                                                        setMinimumElecBIll(
                                                            parseFloat(data.minimum_electricity_bill)
                                                        );

                                                        // console.log(data)

                                                        if (data.tenant_photo.trim() !== "") {
                                                            setPhoto(
                                                                data.tenant_photo
                                                                    .toString()
                                                                    .split("FILE_EXTENSION")[0]
                                                                    .split("/")[
                                                                data.tenant_photo
                                                                    .toString()
                                                                    .split("FILE_EXTENSION")[0]
                                                                    .split("/").length - 1
                                                                    ]
                                                            );
                                                            setTenantPhoto(data.tenant_photo);
                                                            // setTenantPhoto(data.tenant_photo);
                                                        }

                                                        if (data.photo_of_national_id.trim() !== "") {
                                                            setNationalID(
                                                                data.photo_of_national_id
                                                                    .toString()
                                                                    .split("FILE_EXTENSION")[0]
                                                                    .split("/")[
                                                                data.photo_of_national_id
                                                                    .toString()
                                                                    .split("FILE_EXTENSION")[0]
                                                                    .split("/").length - 1
                                                                    ]
                                                            );
                                                            setPhotoOfNationalID(data.photo_of_national_id);
                                                            // setPhotoOfNationalID(data.photo_of_national_id)
                                                        }

                                                        if (data.copy_of_contract.trim() !== "") {
                                                            setContract(
                                                                data.copy_of_contract
                                                                    .toString()
                                                                    .split("FILE_EXTENSION")[0]
                                                                    .split("/")[
                                                                data.copy_of_contract
                                                                    .toString()
                                                                    .split("FILE_EXTENSION")[0]
                                                                    .split("/").length - 1
                                                                    ]
                                                            );
                                                            setCopyOfContract(data.copy_of_contract);
                                                            // setCopyOfContract(data.copy_of_contract)
                                                        }

                                                        if (data.inventory_checklist.trim() !== "") {
                                                            setChecklist(
                                                                data.inventory_checklist
                                                                    .toString()
                                                                    .split("FILE_EXTENSION")[0]
                                                                    .split("/")[
                                                                data.inventory_checklist
                                                                    .toString()
                                                                    .split("FILE_EXTENSION")[0]
                                                                    .split("/").length - 1
                                                                    ]
                                                            );
                                                            setInventoryChecklist(data.inventory_checklist);
                                                            // setInventoryChecklist(data.inventory_checklist)
                                                        }

                                                        setInitialValues2({
                                                            tenant_id: data.tenant_id,
                                                            first_name: data.first_name,
                                                            house_number: data.house_number,
                                                            account_number: data.account_number,
                                                            occupation_or_profession:
                                                            data.occupation_or_profession,
                                                            last_name: data.last_name,
                                                            phone_number: data.phone_number,
                                                            national_id: data.national_id,
                                                            next_of_kin_relationship:
                                                            data.next_of_kin_relationship,
                                                            next_of_kin_first_name:
                                                            data.next_of_kin_first_name,
                                                            next_of_kin_last_name: data.next_of_kin_last_name,
                                                            next_of_kin_national_id:
                                                            data.next_of_kin_national_id,
                                                            next_of_kin_contacts: data.next_of_kin_contacts,
                                                            is_student: data.is_student,
                                                            institution_name: data.institution_name,
                                                            place_of_work: data.place_of_work,
                                                            reason_for_relocating: data.reason_for_relocating,
                                                            previous_residence: data.previous_residence,
                                                            email_address: data.email_address,
                                                            landlord_email_address: "",
                                                            onboarding_secret: "SECRET",
                                                            property_name: data.property_name,
                                                            rent: data.rent,
                                                            deposit_held: data.deposit_held,
                                                            secondary_phone_number:
                                                            data.secondary_phone_number,
                                                            property_id: data.property_id,
                                                        });
                                                        setAddTenantPropertyName(
                                                            <MenuItem value="">
                                                                <em>{propertyName}</em>
                                                            </MenuItem>
                                                        );
                                                        // setPropertyName(
                                                        //   new Map(
                                                        //     Object.entries(
                                                        //       properties.filter(
                                                        //         (item) => item.id === data.property_id
                                                        //       )[0]
                                                        //     )
                                                        //   ).get("property_name")
                                                        // );

                                                        setPropertyID({propertyID});

                                                        setVacatingModal(
                                                            new Map(Object.entries(data)).get("state") ===
                                                            "NOTICE"
                                                        );

                                                        setDataSet(true);
                                                        setBackDrop(<></>);
                                                        //Get All Properties
                                                    } else if (response.status === 401) {
                                                        props.snackBar({
                                                            text: "Maximum concurrent logins reached",
                                                            type: "warning",
                                                        });
                                                    } else if (response.status === 400) {
                                                        props.snackBar({
                                                            text: "Invalid Credentials Provided.",
                                                            type: "error",
                                                        });
                                                    } else if (response.status === 500) {
                                                        if (data["Message"].includes("duplicate key")) {
                                                            if (
                                                                data["Message"].includes(
                                                                    "owners_email_address_uindex"
                                                                )
                                                            ) {
                                                                props.snackBar({
                                                                    text: "Email Address Already Exists",
                                                                    type: "error",
                                                                });
                                                                return;
                                                            }
                                                        }
                                                        props.snackBar({
                                                            text: "Something went wrong. Please try again later.",
                                                            type: "error",
                                                        });
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    props.snackBar({
                                                        text: "Something Went Wrong. Try Again Later",
                                                        type: "error",
                                                    });
                                                });
                                        } else {
                                            props.snackBar({
                                                text: "Failed fetching tenant details.",
                                                type: "error",
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        props.snackBar({
                                            text: "Something Went Wrong. Call Customer Care For Assistance.",
                                            type: "error",
                                        });
                                    });
                            } else {
                                props.snackBar({
                                    text: "Failed fetching tenant details.",
                                    type: "error",
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            props.snackBar({
                                text: "Something Went Wrong. Call Customer Care For Assistance.",
                                type: "error",
                            });
                        });
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant details.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });

        fetch(
            ApiDetails +
            "pegasus/visionary/property/get/all/properties",
            {
                method: "POST",
                body: JSON.stringify({
                    landlord_id: JSON.parse(originalText)[0][1],
                }),
            }
        )
            .then(async (response) => {
                let properties = await response.json();

                if (response.status === 200) {
                    // console.log(data);
                    updateProperties(properties);
                    // setDetailsFetched(true);
                } else {
                    props.snackBar({
                        text: "Failed Fetching Details",
                        type: "error",
                    });
                }
            })
            .catch((err) => console.log(err));


        fetch(
            ApiDetails +
            "pegasus/visionary/tenant/fetch/account/status",
            {
                method: "POST",
                body: JSON.stringify({
                    tenant_id: tenant_id,
                }),
            }
        )
            .then(async (response) => {
                let properties = await response.json();

                if (response.status === 200) {
                    console.log("Fetching tenant account details");
                } else {
                    props.snackBar({
                        text: "Failed Fetching Details",
                        type: "error",
                    });
                }
            })
            .catch((err) => console.log(err));

        getNotices();
    }, [updateState]);

    const [depositDetails, setDepositDetails] = useState("");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleCloseMenu1 = (event) => {
        setAnchorEl1(null);
    };
    const handleCloseMenu2 = (event) => {
        setAnchorEl2(null);
    };
    const handleCloseMenu3 = (event) => {
        setAnchorEl3(null);
    };

    const [dateToday] = useState(new Date());
    const [dateToVacate, setDateToVacate] = useState(new Date());
    const [dateFrom, setDateFrom] = React.useState(new Date());
    const [dateTo, setDateTo] = React.useState(new Date());

    let [tenantDetails, setTenantDetails] = useState(null);
    let [tenantArrears, setTenantArrears] = useState(null);

    //Modal states
    const [arrearsModal, setArrearsModal] = useState(false);
    const [editTenantModal, setEditTenantModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [vacatingModal, setVacatingModal] = useState(false);
    const [billTenantsModal, setBillTenantsModal] = useState(false);
    const [forceVacatingModal, setForceVacatingModal] = useState(false);
    const [changeUnitModal, setChangeUnitModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [invoicesModal, setInvoicesModal] = useState(false);
    const [transactionModal, setTransactionModal] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [billingNotFoundModal, setBillingNotFoundModal] = useState(false);
    const [generatingReportModal, setGeneratingReportModal] = useState(false);
    const [depositModal, setDepositModal] = useState(false);
    const [addDepositModal, setAddDepositModal] = useState(false);

    const [refundableDeposit, setRefundableDeposit] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);

    const [displayAddArrear, setDisplayAddArrear] = useState(false);

    const [expensesIncurred, setExpensesIncurred] = useState(1);
    let [depositList, setDepositList] = useState([new Map()]);
    const [expenseItem] = useState(new Map());
    const calculateRefundableDeposit = () => {
        try {
            expenseItem.forEach((value, key) => {
                let array = key.split(" ");
                let uniqueString = array[array.length - 1];

                if (expenseItem.has("refundable " + uniqueString)) {
                    let refundableValue = expenseItem.get("refundable " + uniqueString);

                    if (
                        !(
                            refundableValue.toString().trim().toUpperCase() === "YES" ||
                            refundableValue.toString().trim().toUpperCase() === "NO"
                        )
                    ) {
                        throw new Error("Fill in either YES OR NO for refundable");
                    }


                } else {
                    throw new Error("Fill in if refundable for all entries");
                }
            });
        } catch (e) {
            // console.log(e)
            props.snackBar({
                text: e.message,
                type: "error",
            });
            return;
        }

        let depositAmount = 0;

        expenseItem.forEach((value, key) => {
            if (key.toString().includes("value")) {
                depositAmount += parseFloat(value);
            }
        });

        setTotalDeposit(depositAmount);

        expenseItem.set("Total deposit", depositAmount);

        let refundableDepositAmount = 0;

        let handledKeys = [];

        expenseItem.forEach((value, key) => {
            let array = key.split(" ");
            let uniqueString = array[array.length - 1];

            if (handledKeys.includes(uniqueString)) {
                return;
            }

            if (expenseItem.has("refundable " + uniqueString)) {
                let refundableValue = expenseItem.get("refundable " + uniqueString);

                if (refundableValue.toString().toUpperCase().trim() === "YES") {
                    refundableDepositAmount += parseFloat(
                        expenseItem.get("value for description " + uniqueString)
                    );
                }
            }
            handledKeys.push(uniqueString);
        });

        setRefundableDeposit(refundableDepositAmount);
        expenseItem.set("Refundable deposit", refundableDepositAmount);

        // console.log(expenseItem)

        setDepositList(expenseItem);

        setDepositSet(true);
    };
    const handleChange = (props) => (event) => {
        // kkk;
        expenseItem.set(event.target.name, event.target.value);
        setDepositSet(false);

        // setDepositList(depositMap)
    };
    const handleTabChange = async (event, newValue) => {
        // kkk;
        if (newValue === "arrears") {
            setDisplayAddArrear(true)
            setDisplayDetails(arrears);
            setDisplayColumns(arrearsColumn);
        } else if (newValue === "transactions") {
            setDisplayAddArrear(false)
            setDisplayDetails(transactions);
            setDisplayColumns(transactionColumns);
        } else if (newValue === "notices") {
            setDisplayAddArrear(false)
            setDisplayDetails(notices);
            setDisplayColumns(noticeColumns);
        }
        setTabValue(newValue);
        // setDepositList(depositMap)
    };

    const [loading1, setLoading1] = React.useState(false);

    const [depositSet, setDepositSet] = useState(false);

    const [inputs, setInputs] = useState(
        <div>
            <br/>
            <div
                style={{
                    display: "none",
                    justifyContent: "space-between",
                }}
            >
                <TextField
                    label="Description"
                    type={"text"}
                    variant="outlined"
                    required
                    style={{marginTop: "10px", width: "49%"}}
                />
                <TextField
                    label="Amount"
                    type={"number"}
                    variant="outlined"
                    style={{marginTop: "10px", width: "49%"}}
                />
            </div>
        </div>
    );

    let [tenantInfo, setTenantInfo] = React.useState({
        tenant_id: "",
        name: "",
        last_name: "",
        unit: "",
        property_id: "",
        account_number: "",
    });
    let initialValues4 = {
        id: "",
        first_name: "",
        date: dateFrom,
        amount: "",
    };

    const [editorState, setEditorState] = React.useState(() =>
        EditorState.createEmpty()
    );
    const [broadcastMessage, setBroadcastMessage] = useState("");

    const [valuesAfterWarning, setValuesAfterWarning] = useState({});

    //Loading states
    const [tenantLoading, setTenantLoading] = React.useState(false);
    const [transactionLoading, setTransactionLoading] = React.useState(false);
    const [messageLoading, setMessageLoading] = React.useState(false);
    // transactions.map((transaction) => {
    //   transactionsAmount += parseInt(transaction.amount);
    // });
    let initialValues = {
        id: "",
        first_name: "",
        description: "",
        amount: "",
    };

    const [rangeReport, setRangeReport] = React.useState(false);

    const [initialValues2, setInitialValues2] = useState({
        property_id: "",
        house_number: "",
    });
    const initialValues3 = {
        first_name: tenant.first_name,
        last_name: tenant.last_name,
        phone_number: tenant.phone_number,
        national_id: tenant.national_id,
        unit: tenant.house_number,
        property_name: tenant.property_name,
        landlord_id: tenant.landlord_id,
        tenant_id: tenant.tenant_id,
    };

    const [billLoading, setBillLoading] = React.useState(false);

    const handleClose = () => {
        setReportModal(false);
        setWarningModal(false);
        setDepositModal(false);
        setArrearsModal(false);
        setMessageModal(false);
        setVacatingModal(false);
        setInvoicesModal(false);
        setAddDepositModal(false);
        setChangeUnitModal(false);
        setEditTenantModal(false);
        setBillTenantsModal(false);
        setTransactionModal(false);
        setForceVacatingModal(false);
        setBillingNotFoundModal(false);
    };

    let [currentTenants] = useState(null);

    let [elecBilledTenants, setElecBilledTenants] = useState([]);

    let [meterReadings, setMeterReadings] = useState([]);
    let [electricityMeterReadings, setElectricityMeterReadings] = useState([]);

    const [month, setMonth] = useState(new Date(2020, 11, 17, 0, 0, 0, 0));
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [warning, setWarning] = useState("");

    let [tenantsLength, setTenantsLength] = useState(1);

    function downloadTenantFile(file) {
        let fileNameExtension;

        // //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(
            ApiDetails +
            "pegasus/visionary/files/tenant/download/" +
            tenant.tenant_id +
            "/" +
            file,
            {
                method: "GET",
            }
        )
            .then(async (res) => {
                if (res.status === 200) {
                    for (let pair of res.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileNameExtension = pair[1]; // saving that value where I can use it
                        }
                    }

                    fileNameExtension = fileNameExtension.toString().split(".")[1];

                    props.snackBar({
                        text: "File Download Has Started",
                        type: "success",
                    });

                    let blob = await res.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", file + "." + fileNameExtension); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } else if (res.status === 404) {
                    props.snackBar({
                        text: "File Not Found",
                        type: "error",
                    });
                } else if (res.status === 500) {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    //Excel Base64 to Blob
    function base64ToBlob(base64) {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return new Blob([bytes], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
    }

    const addArrears = (values) => {
        //Add arrear to database
        let body = {
            tenant_id: tenant.tenant_id,
            description: values.description,
            amount: values.amount,
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/add/arrears", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    // getUserDetails({
                    //   landlord_id: JSON.parse(originalText)[0][1],
                    // });
                    // getArrears();
                    // handleClose();
                    props.snackBar({text: "Arrear Added Successful", type: "success"});

                    setBackDrop(
                        <Backdrop
                            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={true}
                            onClick={null}
                        >
                            <BarWave color="inherit"/>
                        </Backdrop>
                    );
                    window.location.reload();
                } else {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };
    const deleteArrears = (id) => {
        //Add arrear to database
        let body = {
            tenant_id: tenant.tenant_id,
            id: id,
        };
        fetch(ApiDetails + "pegasus/visionary/tenant/delete/arrears", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    window.location.reload(false);
                    // handleClose();
                } else {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };
    const markArrearDirty = (id, dirty) => {
        //Add arrear to database
        let body = {
            id: id,
            dirty: dirty,
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/arrear/dirty", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    window.location.reload();
                } else {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };
    const getArrears = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/get/tenant/arrears", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: tenant.tenant_id,
            }),
        })
            .then(async (response) => {
                let arrears = await response.json();
                if (response.status === 200) {
                    setTenantArrears(arrears);
                    props.snackBar({
                        text: "Arrears updated successfully",
                        type: "success",
                    });
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant arrears.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };
    const updateTenant = (values) => {
        if (values.first_name === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Enter valid first name",
                type: "error",
            });
            return;
        }
        if (values.last_name === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Enter valid last name",
                type: "error",
            });
            return;
        }
        if (values.rent === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Please Input Rent",
                type: "error",
            });
            return;
        }
        if (values.phone_number === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Please Input valid phone number",
                type: "error",
            });
            return;
        }
        // console.log("This is the landlord id ")
        // console.log(JSON.parse(originalText)[0][1],)
        // return

        let formData = new FormData();
        //Add values to formData
        try {
            formData.append(
                "details",
                JSON.stringify({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    national_id: values.national_id,
                    email_address: values.email_address,
                    phone_number: values.phone_number,
                    occupation_or_profession: values.occupation_or_profession,
                    next_of_kin_relationship: values.next_of_kin_relationship,
                    next_of_kin_first_name: values.next_of_kin_first_name,
                    next_of_kin_last_name: values.next_of_kin_last_name,
                    next_of_kin_national_id: values.next_of_kin_national_id,
                    next_of_kin_contacts: values.next_of_kin_contacts,
                    is_student: values.is_student,
                    institution_name: values.institution_name,
                    place_of_work: values.place_of_work,
                    reason_for_relocating: values.reason_for_relocating,
                    previous_residence: values.previous_residence,
                    rent: values.rent,
                    // deposit_held: values.deposit_held,
                    landlord_id: JSON.parse(originalText)[0][1],
                    property_name: propertyName,
                    property_id: propertyID,
                    // landlord_email_address: JSON.parse(originalText)[5][1],
                    tenant_id: tenant.tenant_id,
                    secondary_phone_number: values.secondary_phone_number,
                    deposit: Array.from(depositList.entries()),
                    deposit_held: depositList.get("Refundable deposit"),
                })
            );
        } catch (e) {
            formData.append(
                "details",
                JSON.stringify({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    national_id: values.national_id,
                    email_address: values.email_address,
                    phone_number: values.phone_number,
                    occupation_or_profession: values.occupation_or_profession,
                    next_of_kin_relationship: values.next_of_kin_relationship,
                    next_of_kin_first_name: values.next_of_kin_first_name,
                    next_of_kin_last_name: values.next_of_kin_last_name,
                    next_of_kin_national_id: values.next_of_kin_national_id,
                    next_of_kin_contacts: values.next_of_kin_contacts,
                    is_student: values.is_student,
                    institution_name: values.institution_name,
                    place_of_work: values.place_of_work,
                    reason_for_relocating: values.reason_for_relocating,
                    previous_residence: values.previous_residence,
                    rent: values.rent,
                    // deposit_held: values.deposit_held,
                    landlord_id: JSON.parse(originalText)[0][1],
                    property_name: propertyName,
                    property_id: propertyID,
                    // landlord_email_address: JSON.parse(originalText)[5][1],
                    tenant_id: tenant.tenant_id,
                    secondary_phone_number: values.secondary_phone_number,
                    // deposit: Array.from(depositList.entries()),
                    // deposit_held: (depositList.get("Refundable deposit")),
                })
            );
        }

        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/update/tenant", {
            method: "POST",
            body: formData,
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    props.snackBar({
                        text: "Details Updated Successful",
                        type: "success",
                    });
                    setBackDrop(
                        <Backdrop
                            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={true}
                            onClick={null}
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    );
                    handleClose();
                    setUpdateState(!updateState);
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                } else if (response.status === 500) {
                    if (data["Message"].includes("duplicate key")) {
                        if (data["Message"].includes("owners_email_address_uindex")) {
                            props.snackBar({
                                text: "Email Address Already Exists",
                                type: "error",
                            });
                            return;
                        }
                    }
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const updateTenantFiles = (values) => {
        let formData = new FormData();
        //Add values to formData
        formData.append(
            "details",
            JSON.stringify({
                tenant_id: tenant.tenant_id,
                landlord_id: JSON.parse(originalText)[0][1],
                property_name: propertyName,
                property_id: propertyID,
                photo_of_national_id:
                    typeof photoOfNationalID === "string" ? photoOfNationalID : "",
                copy_of_contract:
                    typeof copyOfContract === "string" ? copyOfContract : "",
                inventory_checklist:
                    typeof inventoryChecklist === "string" ? inventoryChecklist : "",
                tenant_photo: typeof tenantPhoto === "string" ? tenantPhoto : "",
            })
        );

        formData.append("photo_of_national_id", photoOfNationalID);
        formData.append("copy_of_contract", copyOfContract);
        formData.append("inventory_checklist", inventoryChecklist);
        formData.append("tenant_photo", tenantPhoto);

        // return ;

        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/update/tenant/files", {
            method: "POST",
            body: formData,
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    props.snackBar({
                        text: "Details Updated Successful",
                        type: "success",
                    });
                    setBackDrop(
                        <Backdrop
                            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={true}
                            onClick={null}
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    );
                    handleClose();
                    setUpdateState(!updateState);
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                } else if (response.status === 500) {
                    if (data["Message"].includes("duplicate key")) {
                        if (data["Message"].includes("owners_email_address_uindex")) {
                            props.snackBar({
                                text: "Email Address Already Exists",
                                type: "error",
                            });
                            return;
                        }
                    }
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const getReport = () => {
        handleClose();
        setGeneratingReportModal(true);

        let propertyName = properties.filter(
            (property) => property.property_id === tenant.property_id
        );
        let fileName;

        // return;
        let data = {
            tenant_id: tenant.tenant_id,
            year: dateFrom.getFullYear().toString(),
            month: parseInt(dateFrom.getMonth()) + parseInt(1),
            // email_address: JSON.parse(originalText)[5][1],
            first_name: tenant.first_name,
            property_name: propertyName[0].property_name,
        };

        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/reports/get/tenant/report", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then(async (response) => {
                // console.log(response.status);
                if (response.status === 200) {
                    for (let pair of response.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileName = pair[1]; // saving that value where I can use it
                        }
                    }

                    props.snackBar({
                        text: "File Download Will Start In A Few ",
                        type: "success",
                    });

                    let blob = await response.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    setGeneratingReportModal(false);
                } else if (response.status === 404) {
                    setBillingNotFoundModal(true);
                    setGeneratingReportModal(false);
                    props.snackBar({
                        text: "File Not Found",
                        type: "error",
                    });
                } else if (response.status === 500) {
                    setGeneratingReportModal(false);

                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setGeneratingReportModal(false);

                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };
    const getRangeReport = () => {
        setGeneratingReportModal(true);

        if (
            dateFrom.getFullYear().toString() === dateTo.getFullYear().toString() &&
            parseInt(dateFrom.getMonth()) + parseInt(1) ===
            parseInt(dateTo.getMonth()) + parseInt(1)
        ) {
            setGeneratingReportModal(false);
            props.snackBar({
                text: "Start date and End date cannot be the same",
                type: "error",
            });
            return;
        }

        handleClose();

        let propertyName = properties.filter(
            (property) => property.property_id === tenant.property_id
        );

        console.log(properties)
        let fileName;

        // return;
        let data = {
            tenant_id: tenant.tenant_id,
            year: dateFrom.getFullYear().toString(),
            month: parseInt(dateFrom.getMonth()) + parseInt(1),
            end_year: dateTo.getFullYear().toString(),
            end_month: parseInt(dateTo.getMonth()) + parseInt(1),
            // email_address: JSON.parse(originalText)[5][1],
            first_name: tenant.first_name,
            property_name: propertyName[0].property_name,
        };

        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/reports/get/tenant/range/report", {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then(async (response) => {
                // console.log(response.status);
                if (response.status === 200) {
                    for (let pair of response.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileName = pair[1]; // saving that value where I can use it
                        }
                    }

                    props.snackBar({
                        text: "File Download Will Start In A Few ",
                        type: "success",
                    });

                    let blob = await response.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    setGeneratingReportModal(false);
                } else if (response.status === 404) {
                    setBillingNotFoundModal(true);
                    setGeneratingReportModal(false);
                    props.snackBar({
                        text: "File Not Found",
                        type: "error",
                    });
                } else if (response.status === 500) {
                    setGeneratingReportModal(false);

                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setGeneratingReportModal(false);

                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const getTenantDetails = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/get/specific/tenant", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: tenant_id,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                console.log(data);
                if (response.status === 200) {
                    //Fetch tenant arrears
                    fetch(ApiDetails + "pegasus/visionary/tenant/get/tenant/arrears", {
                        method: "POST",
                        body: JSON.stringify({
                            tenant_id: tenant_id,
                        }),
                    })
                        .then(async (response) => {
                            let arrears = await response.json();
                            if (response.status === 200) {
                                //Fetch tenant transactions
                                fetch(
                                    ApiDetails +
                                    "pegasus/visionary/tenant/get/tenant/transactions",
                                    {
                                        method: "POST",
                                        body: JSON.stringify({
                                            tenant_id: tenant_id,
                                        }),
                                    }
                                )
                                    .then(async (response) => {
                                        let transactions = await response.json();
                                        if (response.status === 200) {
                                            //fetch property units
                                            fetch(
                                                ApiDetails + "pegasus/visionary/property/getUnits",
                                                {
                                                    method: "POST",
                                                    body: JSON.stringify({
                                                        property_id: data.property_id,
                                                    }),
                                                }
                                            )
                                                .then(async (response) => {
                                                    let units = await response.json();
                                                    if (response.status === 200) {
                                                        //Get All Properties
                                                        fetch(
                                                            ApiDetails +
                                                            "pegasus/visionary/property/get/all/properties",
                                                            {
                                                                method: "POST",
                                                                body: JSON.stringify({
                                                                    landlord_id: JSON.parse(originalText)[0][1],
                                                                }),
                                                            }
                                                        )
                                                            .then(async (response) => {
                                                                let properties = await response.json();

                                                                if (response.status === 200) {
                                                                    // console.log(data);

                                                                    //Set Base64 Files
                                                                    try {
                                                                        setCopyOfContractBase64(
                                                                            new Map(
                                                                                Object.entries(
                                                                                    new Map(Object.entries(data)).get(
                                                                                        "copy_of_contract"
                                                                                    )
                                                                                )
                                                                            )
                                                                        );
                                                                    } catch (e) {
                                                                    }
                                                                    try {
                                                                        setInventoryChecklistBase64(
                                                                            new Map(
                                                                                Object.entries(
                                                                                    new Map(Object.entries(data)).get(
                                                                                        "inventory_checklist"
                                                                                    )
                                                                                )
                                                                            )
                                                                        );
                                                                    } catch (e) {
                                                                    }
                                                                    try {
                                                                        setPhotoOfNationalIDBase64(
                                                                            new Map(
                                                                                Object.entries(
                                                                                    new Map(Object.entries(data)).get(
                                                                                        "photo_of_national_id"
                                                                                    )
                                                                                )
                                                                            )
                                                                        );
                                                                    } catch (e) {
                                                                    }
                                                                    try {
                                                                        setTenantPhotoBase64(
                                                                            new Map(
                                                                                Object.entries(
                                                                                    new Map(Object.entries(data)).get(
                                                                                        "tenant_photo"
                                                                                    )
                                                                                )
                                                                            )
                                                                        );
                                                                    } catch (e) {
                                                                    }

                                                                    if (
                                                                        new Map(
                                                                            Object.entries(
                                                                                new Map(Object.entries(data)).get(
                                                                                    "tenant_photo"
                                                                                )
                                                                            )
                                                                        ).get("base64") !== "No File Found"
                                                                    ) {
                                                                        setTenantImage(
                                                                            "data:image/jpeg;base64," +
                                                                            new Map(
                                                                                Object.entries(
                                                                                    new Map(Object.entries(data)).get(
                                                                                        "tenant_photo"
                                                                                    )
                                                                                )
                                                                            ).get("base64")
                                                                        );
                                                                    }

                                                                    updateProperties(properties);
                                                                    console.log(properties);
                                                                    setTenant(data);
                                                                    setTenantList([data]);
                                                                    setArrears(arrears);
                                                                    setTransactions(transactions);
                                                                    setUnits(units);
                                                                    setWaterBill(data.water_bill);
                                                                    setMinimumWaterBill(data.minimum_water_bill);

                                                                    setInitialValues2({
                                                                        tenant_id: data.tenant_id,
                                                                        first_name: data.first_name,
                                                                        house_number: data.house_number,
                                                                        account_number: data.account_number,
                                                                        occupation_or_profession:
                                                                        data.occupation_or_profession,
                                                                        last_name: data.last_name,
                                                                        phone_number: data.phone_number,
                                                                        national_id: data.national_id,
                                                                        next_of_kin_relationship:
                                                                        data.next_of_kin_relationship,
                                                                        next_of_kin_first_name:
                                                                        data.next_of_kin_first_name,
                                                                        next_of_kin_last_name:
                                                                        data.next_of_kin_last_name,
                                                                        next_of_kin_national_id:
                                                                        data.next_of_kin_national_id,
                                                                        next_of_kin_contacts:
                                                                        data.next_of_kin_contacts,
                                                                        is_student: data.is_student,
                                                                        institution_name: data.institution_name,
                                                                        place_of_work: data.place_of_work,
                                                                        reason_for_relocating:
                                                                        data.reason_for_relocating,
                                                                        previous_residence: data.previous_residence,
                                                                        email_address: data.email_address,
                                                                        landlord_email_address: "",
                                                                        onboarding_secret: "SECRET",
                                                                        property_name: data.property_name,
                                                                        rent: data.rent,
                                                                        deposit_held: data.deposit_held,
                                                                        secondary_phone_number:
                                                                        data.secondary_phone_number,
                                                                        property_id: data.property_id,
                                                                    });
                                                                    setAddTenantPropertyName(
                                                                        <MenuItem value="">
                                                                            <em>
                                                                                {new Map(
                                                                                    Object.entries(
                                                                                        properties.filter(
                                                                                            (item) =>
                                                                                                item.id === data.property_id
                                                                                        )[0]
                                                                                    )
                                                                                ).get("property_name")}
                                                                            </em>
                                                                        </MenuItem>
                                                                    );
                                                                    // setPropertyName(
                                                                    //   new Map(
                                                                    //     Object.entries(
                                                                    //       properties.filter(
                                                                    //         (item) => item.id === data.property_id
                                                                    //       )[0]
                                                                    //     )
                                                                    //   ).get("property_name")
                                                                    // );

                                                                    setPropertyID(
                                                                        new Map(
                                                                            Object.entries(
                                                                                properties.filter(
                                                                                    (item) => item.id === data.property_id
                                                                                )[0]
                                                                            )
                                                                        ).get("property_id")
                                                                    );
                                                                    setPropertyName(
                                                                        new Map(
                                                                            Object.entries(
                                                                                properties.filter(
                                                                                    (item) => item.id === data.property_id
                                                                                )[0]
                                                                            )
                                                                        ).get("property_name")
                                                                    );
                                                                    setVacatingModal(
                                                                        new Map(Object.entries(data)).get(
                                                                            "state"
                                                                        ) === "NOTICE"
                                                                    );

                                                                    setDataSet(true);
                                                                    setBackDrop(<></>);
                                                                    // setDetailsFetched(true);
                                                                } else {
                                                                    props.snackBar({
                                                                        text: "Failed Fetching Details",
                                                                        type: "error",
                                                                    });
                                                                }
                                                            })
                                                            .catch((err) => console.log(err));
                                                    } else if (response.status === 401) {
                                                        props.snackBar({
                                                            text: "Maximum concurrent logins reached",
                                                            type: "warning",
                                                        });
                                                    } else if (response.status === 400) {
                                                        props.snackBar({
                                                            text: "Invalid Credentials Provided.",
                                                            type: "error",
                                                        });
                                                    } else if (response.status === 500) {
                                                        if (data["Message"].includes("duplicate key")) {
                                                            if (
                                                                data["Message"].includes(
                                                                    "owners_email_address_uindex"
                                                                )
                                                            ) {
                                                                props.snackBar({
                                                                    text: "Email Address Already Exists",
                                                                    type: "error",
                                                                });
                                                                return;
                                                            }
                                                        }
                                                        props.snackBar({
                                                            text: "Something went wrong. Please try again later.",
                                                            type: "error",
                                                        });
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    props.snackBar({
                                                        text: "Something Went Wrong. Try Again Later",
                                                        type: "error",
                                                    });
                                                });
                                        } else {
                                            props.snackBar({
                                                text: "Failed fetching tenant details.",
                                                type: "error",
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        props.snackBar({
                                            text: "Something Went Wrong. Call Customer Care For Assistance.",
                                            type: "error",
                                        });
                                    });
                            } else {
                                props.snackBar({
                                    text: "Failed fetching tenant details.",
                                    type: "error",
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            props.snackBar({
                                text: "Something Went Wrong. Call Customer Care For Assistance.",
                                type: "error",
                            });
                        });
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant details.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };

    let [billedTenants, setBilledTenants] = useState([]);
    const billTenants = () => {
        // console.log(billedTenants);

        setBillLoading(true);
        if (billedTenants.length === 0) {
            setBillLoading(false);
            props.snackBar({
                text: "Please Make Sure To Bill AllTenants",
                type: "error",
            });
            return;
        }

        //Make Billing API Call

        fetch(ApiDetails + "pegasus/visionary/tenant/invoice", {
            method: "POST",
            body: JSON.stringify([
                ...billedTenants,
                {
                    property_id: tenant.property_id,
                },
            ]),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    getTenantDetails();
                    props.snackBar({
                        text: "Invoice Sent Successfully.",
                        type: "success",
                    });
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant details.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });

        handleClose();
    };

    const billTenant = async () => {
        // console.log(billedTenants);
        // console.log(elecBilledTenants);

        setBillLoading(true);

        if (currentTenants !== null) {
            if (
                // billedTenants.length < currentTenants.length ||
                // elecBilledTenants.length < currentTenants.length
                billedTenants.length < tenantsLength ||
                elecBilledTenants.length < tenantsLength
            ) {
                props.snackBar({
                    text: "Please Make sure to bill all tenants both water and electricity",
                    type: "error",
                });
                setBillLoading(false);

                return;
            }
        } else {
            // console.log("Billed tenants :" + billedTenants.length);
            // console.log("All tenants :" + tenantsLength);
            if (
                // billedTenants.length < tenants.length ||
                // elecBilledTenants.length < tenants.length
                billedTenants.length < tenantsLength ||
                elecBilledTenants.length < tenantsLength
            ) {
                console.log("here");
                props.snackBar({
                    text: "Please Make sure to bill all tenants both water and electricity",
                    type: "error",
                });
                setBillLoading(false);

                return;
            }
        }

        let finalBill = [];

        if (billedTenants > elecBilledTenants) {
            billedTenants.forEach((tenant) => {
                elecBilledTenants.forEach((tenant2) => {
                    if (tenant.tenant_id === tenant2.tenant_id) {
                        finalBill = [
                            ...finalBill,
                            {
                                tenant_id: tenant.tenant_id,
                                water_bill: tenant.finalWaterBill,
                                electricity_bill: tenant2.electricity_bill,
                                total_bill:
                                    parseInt(tenant.rent.replace(",", "")) +
                                    parseInt(tenant.arrears) +
                                    parseFloat(tenant2.electricity_bill) +
                                    parseFloat(tenant.finalWaterBill),
                                meter_reading: tenant.meter_reading,
                                electricity_meter_reading: tenant2.electricity_meter_reading,
                                landlord_id: JSON.parse(originalText)[0][1],
                            },
                        ];
                        billedTenants = billedTenants.filter(
                            (tenant) => tenant.tenant_id !== tenant2.tenant_id
                        );
                        elecBilledTenants = elecBilledTenants.filter(
                            (tenant) => tenant.tenant_id !== tenant2.tenant_id
                        );
                    }
                });
            });
        } else {
            elecBilledTenants.forEach((tenant) => {
                billedTenants.forEach((tenant2) => {
                    if (tenant.tenant_id === tenant2.tenant_id) {
                        finalBill = [
                            ...finalBill,
                            {
                                tenant_id: tenant.tenant_id,
                                water_bill: tenant2.finalWaterBill,
                                electricity_bill: tenant.electricity_bill,
                                total_bill:
                                    parseInt(tenant.rent) +
                                    parseInt(tenant.arrears) +
                                    parseFloat(tenant.electricity_bill) +
                                    parseFloat(tenant2.finalWaterBill),
                                meter_reading: tenant2.meter_reading,
                                electricity_meter_reading: tenant.electricity_meter_reading,
                                landlord_id: JSON.parse(originalText)[0][1],
                            },
                        ];
                        billedTenants = billedTenants.filter(
                            (tenant) => tenant.tenant_id !== tenant2.tenant_id
                        );
                        elecBilledTenants = elecBilledTenants.filter(
                            (tenant) => tenant.tenant_id !== tenant2.tenant_id
                        );
                    }
                });
            });
        }

        let finalWaterBills = [];
        billedTenants.forEach((reading) => {
            reading = {
                tenant_id: reading.tenant_id,
                water_bill: reading.finalWaterBill,
                electricity_bill: 0,
                total_bill:
                    parseInt(reading.rent) +
                    parseInt(reading.arrears) +
                    parseFloat(reading.finalWaterBill),
                meter_reading: reading.meter_reading,
                electricity_meter_reading: 0,
                landlord_id: JSON.parse(originalText)[0][1],
            };
            finalWaterBills = [...finalWaterBills, reading];
        });

        let finalElectricityBills = [];
        elecBilledTenants.forEach((reading) => {
            reading = {
                tenant_id: reading.tenant_id,
                water_bill: 0,
                electricity_bill: reading.electricity_bill,
                total_bill:
                    parseInt(reading.rent) +
                    parseInt(reading.arrears) +
                    parseFloat(reading.electricity_bill),
                meter_reading: 0,
                electricity_meter_reading: reading.electricity_meter_reading,
                landlord_id: JSON.parse(originalText)[0][1],
            };
            finalElectricityBills = [...finalElectricityBills, reading];
        });

        finalBill = [...finalBill, ...finalWaterBills, ...finalElectricityBills];

        if (currentTenants !== null) {
            if (finalBill.length < currentTenants.length) {
                props.snackBar({
                    text: "Please Make Sure To Bill AllTenants",
                    type: "error",
                });
                setBillLoading(false);

                return;
            }
        } else {
            // console.log("Billed tenants :" + billedTenants.length);
            // console.log("All tenants :" + tenantsLength);
            if (finalBill.length < tenantsLength) {
                // console.log("here");
                props.snackBar({
                    text: "Please Make Sure To Bill AllTenants",
                    type: "error",
                });
                setBillLoading(false);

                return;
            }
        }

        if (selectedMonth === null) {
            props.snackBar({
                text: "Kindly select billing for month",
                type: "error",
            });
            setBillLoading(false);
            return;
        }

        let continueBilling = false;

        // eslint-disable-next-line no-restricted-globals
        await confirm({
            description: `You are about to bill for the month of 
         ${getMonthName(parseInt(month.getMonth())).toUpperCase()} , ${parseInt(
                month.getFullYear()
            )}`,
        })
            .then(() => {
                continueBilling = true;
            })
            .catch(() => (continueBilling = false));

        if (!continueBilling) {
            fetch(ApiDetails + "pegasus/visionary/property/enableBilling", {
                method: "POST",
                body: JSON.stringify({
                    property_id: tenant.property_id,
                }),
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        window.location.reload(false);
                    } else {
                        props.snackBar({
                            text: "Failed updating tenant details.",
                            type: "error",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    props.snackBar({
                        text: "Something Went Wrong. Call Customer Care For Assistance.",
                        type: "error",
                    });
                });
            return;
        }

        //Make Billing API Call
        fetch(ApiDetails + "pegasus/visionary/property/bill/tenants", {
            method: "POST",
            body: JSON.stringify([
                ...finalBill,
                {
                    month: parseInt(month.getMonth()),
                    year: parseInt(month.getFullYear()),
                    property_id: tenant.property_id,
                    property_name: tenant.property_name,
                    // email_address: JSON.parse(originalText)[5][1],
                },
            ]),
        })
            .then(async (response) => {
                // let data = await response.json();
                if (response.status === 200) {
                    // await getPropertyDetails();
                    // setUpdateState(!updateState);

                    props.snackBar({
                        text: "Property Tenants Billed Successfully.",
                        type: "success",
                    });
                    setBillLoading(false);
                    window.location.reload(false);

                    // handleClose();
                    // window.location.reload(false);
                } else {
                    props.snackBar({
                        text: "Failed Billing tenants.",
                        type: "error",
                    });
                    setBillLoading(false);

                    handleClose();
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
                setBillLoading(false);

                handleClose();
            });
    };

    const getUnits = () => {
        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",

        // console.log(initialValues2)
        fetch(ApiDetails + "pegasus/visionary/property/getUnits", {
            method: "POST",
            body: JSON.stringify({property_id: initialValues2.property_id}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    console.log(data);
                    setUnits(data);
                    props.snackBar({
                        text: "Property Units Retreived Successful",
                        type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                } else if (response.status === 500) {
                    if (data["Message"].includes("duplicate key")) {
                        if (data["Message"].includes("owners_email_address_uindex")) {
                            props.snackBar({
                                text: "Email Address Already Exists",
                                type: "error",
                            });
                            return;
                        }
                    }
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };
    const updateTenantUnit = () => {
        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/property/change/unit", {
            method: "POST",
            body: JSON.stringify({
                previous_unit: initialValues2.house_number,
                new_unit: selectedUnit,
                tenant_id: initialValues2.tenant_id,
                account_number: initialValues2.account_number,
                property_id: initialValues2.property_id,
                phone_number: initialValues2.phone_number,
                secondary_phone_number: initialValues2.secondary_phone_number,
                first_name: initialValues2.first_name,
                last_name: initialValues2.last_name,
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    props.snackBar({
                        text: "Unit Change Successful",
                        type: "success",
                    });
                    setBackDrop(
                        <Backdrop
                            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={true}
                            onClick={null}
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    );
                    handleClose();
                    setUpdateState(!updateState);
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    function toMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);

        return date.toLocaleString("en-US", {
            month: "long",
        });
    }

    const sendVacatingNotice = (values) => {
        setTenantLoading(true);
        const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // const data = htmlToDraft;

        values = {
            ...values,
            // date_to_vacate_house: new Date(dateToVacate.toJSON()),
            date_to_vacate_house:
                toMonthName(new Date(dateToVacate.toJSON()).getMonth()) +
                " " +
                new Date(dateToVacate.toJSON()).getDate() +
                ", " +
                new Date(dateToVacate.toJSON()).getFullYear(),
            date_notice_given: new Date(),
            reasons_for_vacating: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
            ),
            landlord_notice: true,
        };

        // //Check if year is valid
        // if (
        //   new Date(dateToVacate.toJSON()).getFullYear() < dateToday.getFullYear()
        // ) {
        //   props.snackBar({
        //     text: "Invalid date to vacate.",
        //     type: "error",
        //   });
        //   setTenantLoading(false);
        //   return;
        // }
        // //Check if date is valid
        // else if (
        //   new Date(dateToVacate.toJSON()).getFullYear() ===
        //     dateToday.getFullYear() &&
        //   new Date(dateToVacate.toJSON()).getMonth() < dateToday.getMonth()
        // ) {
        //   props.snackBar({
        //     text: "Invalid date to vacate.",
        //     type: "error",
        //   });
        //   setTenantLoading(false);
        //   return;
        // }
        // //Check if date is valid
        // else if (new Date(dateToVacate.toJSON()).getDate() < 28) {
        //   props.snackBar({
        //     text: "You can only vacate property after the 28th of the month",
        //     type: "error",
        //   });
        //   setTenantLoading(false);
        //   return;
        // }
        // //Check if date to day is past 5th and notice is for the same month
        // else if (
        //   dateToday.getDate() >= 3 &&
        //   new Date(dateToVacate.toJSON()).getMonth() === dateToday.getMonth()
        // ) {
        //   //Show warning of risking not getting deposit back
        //   setValuesAfterWarning(values);
        //   handleClose();
        //   setWarningModal(true);
        //   setTenantLoading(false);
        //   props.snackBar({
        //     text: "Notice to vacate are given before the 5th of the month. You risk not being refunded your deposit",
        //     type: "warning",
        //   });
        //   return;
        // } else {
        //   // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        //   fetch(ApiDetails + "pegasus/visionary/tenant/app/AddNotice", {
        //     method: "POST",
        //     body: JSON.stringify(values),
        //   })
        //     .then(async (response) => {
        //       if (response.status === 200) {
        //         props.snackBar({
        //           text: "Notice Successfully Uploaded",
        //           type: "success",
        //         });
        //         setBackDrop(
        //           <Backdrop
        //             sx={{
        //               color: "#fff",
        //               zIndex: (theme) => theme.zIndex.drawer + 1,
        //             }}
        //             open={true}
        //             onClick={null}
        //           >
        //             <CircularProgress color="inherit" />
        //           </Backdrop>
        //         );
        //         // handleClose();
        //         setUpdateState(!updateState);
        //       } else if (response.status === 500) {
        //         props.snackBar({
        //           text: "Something Went Wrong. Try Again Later",
        //           type: "error",
        //         });
        //       }
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //       props.snackBar({
        //         text: "Something Went Wrong. Try Again Later",
        //         type: "error",
        //       });
        //     });
        // }

        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/app/AddNotice", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    props.snackBar({
                        text: "Notice Successfully Uploaded",
                        type: "success",
                    });
                    setBackDrop(
                        <></>
                    );
                    await getNotices();
                    handleTabChange('notices');
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });

        setTenantLoading(false);
        handleClose();
    };

    const removeVacatingNotice = (values) => {
        setTenantLoading(true);

        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/app/cancel/notice", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    props.snackBar({
                        text: "Notice Successfully Cancelled",
                        type: "success",
                    });
                    setBackDrop(
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={true}
                            onClick={null}
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    );
                    // handleClose();
                    setUpdateState(!updateState);
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });

        setTenantLoading(false);
        handleClose();
    };
    const sendVacatingNoticeAfterWarning = () => {
        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/app/AddNotice", {
            method: "POST",
            body: JSON.stringify(valuesAfterWarning),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    props.snackBar({
                        text: "Notice Successfully Uploaded",
                        type: "success",
                    });
                    setBackDrop(
                        <Backdrop
                            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={true}
                            onClick={null}
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    );
                    // handleClose();
                    setUpdateState(!updateState);
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
        setTenantLoading(false);
        handleClose();
    };

    const addTransaction = (values) => {
        setTransactionLoading(true);
        // return;
        //Add arrear to database
        /*
            "TransactionType": "Pay Bill",
                    "TransID": "QIA3PD6BCB",
                    "TransTime": "20220910102100",
                    "TransAmount": "50.00",
                    "BusinessShortCode": "4103275",
                    "BillRefNumber": "M1",
                    "InvoiceNumber": "",
                    "OrgAccountBalance": "170.00",
                    "ThirdPartyTransID": "",
                    "MSISDN": "2547 ***** 249",
                    "FirstName": "DEREK"
            */
        let body = {
            TransactionType: "Pay Bill",
            TransID: "**********",
            // TransTime: "20220910102100",
            TransTime: dateFrom,
            TransAmount: values.amount,
            BusinessShortCode: "4103275",
            BillRefNumber: tenantInfo.account_number,
            InvoiceNumber: "",
            OrgAccountBalance: "170.00",
            ThirdPartyTransID: "",
            MSISDN: "2547 ***** 249",
            FirstName: tenantInfo.name,
            tenant_id: tenantInfo.id,
            landlord_id: JSON.parse(originalText)[0][1],
        };
        if (values.amount === "") {
            props.snackBar({text: "Amount Can't Be Empty", type: "error"});
            return;
        }

        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/payments/", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    props.snackBar({
                        text: "Transaction Added",
                        type: "success",
                    });
                    setBackDrop(
                        <Backdrop
                            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={true}
                            onClick={null}
                        >
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                    );
                    window.location.reload();
                    // props.snackBar({ text: "RESPONSE 200", type: "success" });
                } else {
                    handleClose();
                    props.snackBar({
                        text: "Something Went Wrong, Please Try Again Later ",
                        type: "error",
                    });
                }
                setTransactionLoading(false);
            })
            .catch((err) => {
                console.log(err);
                handleClose();
                props.snackBar({
                    text: "Something Went Wrong, Please Try Again Later ",
                    type: "error",
                });
            });
    };

    const sendMessage = () => {
        setMessageLoading(true);
        if (broadcastMessage === "") {
            props.snackBar({
                text: "Can not send an empty message",
                type: "error",
            });
            setMessageLoading(false);
            return;
        }
        let values = {
            message: broadcastMessage,
            tenant_id: tenant.tenant_id,
            first_name: tenant.first_name,
            phone_number: tenant.phone_number,
            property_id: tenant.property_id,
            secondary_phone_number: tenant.secondary_phone_number,
            landlord_id: JSON.parse(originalText)[0][1],
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/message", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    props.snackBar({
                        text: "Message Sent Successfully",
                        type: "success",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
                setMessageLoading(false);
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
                setMessageLoading(false);
            });
    };

    const sendCredentials = () => {
        // setMessageLoading(true);
        let values = {
            tenant_id: tenant.tenant_id,
            property_id: tenant.property_id,
            phone_number: tenant.phone_number,
            secondary_phone_number: tenant.secondary_phone_number,
            first_name: tenant.first_name,
            account_number: tenant.account_number,
            landlord_id: JSON.parse(originalText)[0][1],
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/credentials", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    props.snackBar({
                        text: "Credentials Sent Successfully",
                        type: "success",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
                // setMessageLoading(false);
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
                // setMessageLoading(false);
            });
    };

    const deleteNotice = (id) => {
        fetch(
            ApiDetails +
            "pegasus/visionary/authorization/admin/delete/specific/tenant/notices",
            {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                    tenant_id: tenant_id
                }),
            }
        )
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    // console.log(data)
                    setDisplayDetails(data);
                    props.snackBar({
                        text: "Notice deleted successfully.",
                        type: "success",
                    });
                } else {
                    props.snackBar({
                        text: "Failed deleting notice.",
                        type: "error",
                    });
                }
            })
            .catch((error) => console.error("Error fetching image:", error));
    };

    const getNotices = () => {
        fetch(
            ApiDetails +
            "pegasus/visionary/authorization/admin/get/specific/tenant/notices",
            {
                method: "POST",
                body: JSON.stringify({
                    tenant_id: tenant_id,
                }),
            }
        )
            .then(async (response) => {
                let data = await response.json();
                // console.log(data);
                if (response.status === 200) {
                    //Fetch tenant notices
                    setNotices(data);
                    // console.log(data)

                    if (tabValue === "notices") {
                        setDisplayDetails(data);
                    }
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant details.",
                        type: "error",
                    });
                }
            })
            .catch((error) => console.error("Error fetching image:", error));
    };

    let dateSchema = yup.object().shape({
        phone_number: yup.string().phone("KE", true).required("Required"),
    });

    const classes = useStyles();

    const billingColumns = [
        {
            field: "user",
            headerName: "Name",
            flex: 1,
            minWidth: 120,
            sortOrder: "asc",
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.first_name} {params.row.last_name}
                    </div>
                );
            },
        },
        {
            field: "house_number", // align: "center",
            headerName: "HSE NO",
            flex: 1,
            minWidth: 80,
        },
        {
            field: "rent",
            headerName: "Rent",
            flex: 1,
            minWidth: 50, // align: "center",
            renderCell: (params) => {
                return (
                    <div style={{color: "green"}}>
                        {parseInt(
                            params.row.rent.toString().replace(",", "")
                        ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "arrears",
            headerName: "Arrears",
            flex: 1,
            minWidth: 50, // align: "center",
            renderCell: (params) => {
                if (params.row.arrears === 0) {
                    return (
                        <div
                            style={{
                                width: "100px",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div style={{color: "green"}}>
                                {params.row.arrears.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div
                            style={{
                                width: "100px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div style={{color: "red"}}>
                                {params.row.arrears.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>
                    );
                }
            },
        },

        {
            field: "meter_reading",
            headerName: "Prior Water Reading",
            flex: 1,
            minWidth: 120, // align: "center",
            renderCell: (params) => {
                return <div>{params.row.meter_reading}</div>;
            },
        },
        {
            field: "current_meter_reading",
            headerName: "Water Reading",
            flex: 1,
            minWidth: 100, // align: "center",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        <TextField
                            type={"number"}
                            placeholder="Enter Reading ..."
                            onChange={(e) => {
                                let filteredTenantArray = billedTenants.filter((tenant) => {
                                    return tenant.tenant_id !== params.row.tenant_id;
                                });

                                if (
                                    e.target.value === undefined ||
                                    e.target.value === null ||
                                    e.target.value === ""
                                ) {
                                    return;
                                }

                                let consumedUnits = e.target.value - params.row.meter_reading;
                                consumedUnits = consumedUnits.toFixed(1);

                                let finalWaterBill =
                                    parseFloat(consumedUnits) * parseInt(waterBill);

                                if (finalWaterBill < minimumWaterBill) {
                                    finalWaterBill = minimumWaterBill;
                                }

                                setBilledTenants([
                                    ...filteredTenantArray,
                                    {
                                        tenant_id: params.row.tenant_id,
                                        rent: parseInt(params.row.rent.replace(",", "")),
                                        arrears: parseInt(params.row.arrears),
                                        finalWaterBill: finalWaterBill,
                                        total_bill:
                                            parseInt(params.row.rent.replace(",", "")) +
                                            parseInt(params.row.arrears) +
                                            parseFloat(finalWaterBill),
                                        meter_reading: e.target.value,
                                        landlord_id: JSON.parse(originalText)[0][1],
                                    },
                                ]);
                            }}
                            disabled={!params.row.billing_enabled}
                        />
                    </div>
                );
            },
        },

        {
            field: "electricity_meter_reading",
            headerName: "Prior Elec Reading",
            flex: 1,
            minWidth: 80,
        },
        {
            field: "current_electricity_meter_reading",
            headerName: "Electricity Reading",
            flex: 1,
            minWidth: 120,
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        <TextField
                            type={"number"}
                            placeholder="Enter Reading ..."
                            onChange={(e) => {
                                let filteredTenantArray = elecBilledTenants.filter((tenant) => {
                                    return tenant.tenant_id !== params.row.tenant_id;
                                });
                                if (
                                    e.target.value === undefined ||
                                    e.target.value === null ||
                                    e.target.value === ""
                                ) {
                                    return;
                                }
                                let consumedUnits =
                                    e.target.value - params.row.electricity_meter_reading;
                                consumedUnits = consumedUnits.toFixed(1);

                                // console.log(
                                //   "Prior elec reading: " + params.row.electricity_meter_reading
                                // );
                                // console.log("Current elec reading: " + e.target.value);
                                // console.log("Consumed units: " + consumedUnits);
                                let finalElecBill =
                                    parseFloat(consumedUnits) * parseInt(electricityBill);

                                // console.log("Final elec bill: " + finalElecBill);

                                if (finalElecBill < minimumElecBill) {
                                    finalElecBill = minimumElecBill;
                                }

                                setElecBilledTenants([
                                    ...filteredTenantArray,
                                    {
                                        tenant_id: params.row.tenant_id,
                                        rent: parseInt(params.row.rent.replace(",", "")),
                                        arrears: parseInt(params.row.arrears),
                                        electricity_bill: finalElecBill,
                                        total_bill:
                                            parseInt(params.row.rent.replace(",", "")) +
                                            parseInt(params.row.arrears) +
                                            parseFloat(finalElecBill),
                                        electricity_meter_reading: e.target.value,
                                        landlord_id: JSON.parse(originalText)[0][1],
                                    },
                                ]);
                            }}
                            disabled={!params.row.billing_enabled}
                        />
                    </div>
                );
            },
        },

        {
            field: "billing_enabled",
            headerName: "Bill tenant?",
            flex: 1,
            minWidth: 100, // align: "center",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {params.row.billing_enabled ? (
                            <Switch
                                onChange={() => {
                                    // console.log("Before changing : " + tenantsLength);
                                    //API call to disable tenant billing

                                    fetch(ApiDetails + "pegasus/visionary/tenant/billing", {
                                        method: "POST",
                                        body: JSON.stringify({
                                            id: params.row.tenant_id,
                                            value: false,
                                        }),
                                    })
                                        .then(async (response) => {
                                            if (response.status === 200) {
                                                // getPropertyDetails();

                                                //Remove electricity billing if any
                                                let removedElecBill;
                                                let filteredTenantArray = elecBilledTenants.filter(
                                                    (tenant) => {
                                                        if (tenant.tenant_id !== params.row.tenant_id) {
                                                            return tenant;
                                                        } else {
                                                            removedElecBill = tenant;
                                                        }
                                                        // return tenant.tenant_id !== params.row.tenant_id;
                                                    }
                                                );
                                                setElecBilledTenants([...filteredTenantArray]);

                                                // console.log("Stima billed number : "+filteredTenantArray.length)

                                                //Add to removed electricity billings
                                                setRemovedElectricityBilling([
                                                    ...removedElectricityBilling,
                                                    removedElecBill,
                                                ]);

                                                //Remove water billing if any
                                                let removedWaterBill;
                                                let filteredTenantArray2 = billedTenants.filter(
                                                    (tenant) => {
                                                        if (tenant.tenant_id !== params.row.tenant_id) {
                                                            return tenant;
                                                        } else {
                                                            removedWaterBill = tenant;
                                                        }
                                                    }
                                                );
                                                setBilledTenants([...filteredTenantArray2]);
                                                // console.log("Water billed number : "+filteredTenantArray2.length)

                                                //Add to removed water billings
                                                setRemovedWaterBilling([
                                                    ...removedWaterBilling,
                                                    removedWaterBill,
                                                ]);

                                                setTenantsLength(tenantsLength - 1);
                                                tenantsLength = tenantsLength - 1;
                                                // console.log("After changing : " + tenantsLength);

                                                setUpdateState(!updateState);
                                            } else {
                                                props.snackBar({
                                                    text: "Failed updating tenant details.",
                                                    type: "error",
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                            props.snackBar({
                                                text: "Something Went Wrong. Call Customer Care For Assistance.",
                                                type: "error",
                                            });
                                        });
                                }}
                                {...label}
                                defaultChecked
                            />
                        ) : (
                            <Switch
                                onChange={() => {
                                    //API call to enable tenant billing
                                    // console.log(tenantsLength);
                                    // setTenantsLength = tenantsLength + 1;

                                    fetch(ApiDetails + "pegasus/visionary/tenant/billing", {
                                        method: "POST",
                                        body: JSON.stringify({
                                            id: params.row.tenant_id,
                                            value: true,
                                        }),
                                    })
                                        .then(async (response) => {
                                            if (response.status === 200) {
                                                // getPropertyDetails();

                                                //Get removed electricity billing if any
                                                let removedElecBill;
                                                let filteredTenantArray =
                                                    removedElectricityBilling.filter((tenant) => {
                                                        if (tenant.tenant_id !== params.row.tenant_id) {
                                                            return tenant;
                                                        } else {
                                                            removedElecBill = tenant;
                                                        }
                                                        // return tenant.tenant_id !== params.row.tenant_id;
                                                    });

                                                //Return electricity bill to map
                                                setElecBilledTenants([
                                                    ...elecBilledTenants,
                                                    removedElecBill,
                                                ]);

                                                //Set removed electricity bill to new value
                                                setRemovedElectricityBilling([...filteredTenantArray]);

                                                //Get removed water billing if any
                                                let removedWaterBill;
                                                let filteredTenantArray2 = removedWaterBilling.filter(
                                                    (tenant) => {
                                                        if (tenant.tenant_id !== params.row.tenant_id) {
                                                            return tenant;
                                                        } else {
                                                            removedWaterBill = tenant;
                                                        }
                                                        // return tenant.tenant_id !== params.row.tenant_id;
                                                    }
                                                );

                                                //Return water bill to map
                                                setBilledTenants([...billedTenants, removedWaterBill]);

                                                //Set removed water bill to new value
                                                setRemovedWaterBilling([...filteredTenantArray2]);

                                                setUpdateState(!updateState);
                                                tenantsLength = tenantsLength + 1;
                                            } else {
                                                props.snackBar({
                                                    text: "Failed updating tenant details.",
                                                    type: "error",
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                            props.snackBar({
                                                text: "Something Went Wrong. Call Customer Care For Assistance.",
                                                type: "error",
                                            });
                                        });
                                }}
                                {...label}
                            />
                        )}
                    </div>
                );
            },
        },
    ];
    const arrearsColumn = [
        {
            field: "description",
            headerName: "Description",
            headerAlign: "center",
            // width: 180,
            minWidth: 180,
            flex: 2,
            sortOrder: "asc",
        },
        {
            field: "amount",
            headerName: "Amount",
            headerAlign: "center",
            // width: 120,
            minWidth: 120,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.balance.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "date_created",
            headerAlign: "center",
            align: "center",
            headerName: "Date",
            // width: 170,
            minWidth: 170,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            fontSize: "12px",
                        }}
                    >
                        {params.row.date_created}
                    </div>
                );
            },
        },
        {
            field: "",
            headerName: "Action",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {params.row.dirty === "TRUE" ? (
                            <>
                                <Tooltip title="Remove Issue" placement="bottom-start">
                                    <BugReport
                                        style={{
                                            color: "red",
                                        }}
                                        onClick={() => {
                                            //open confirm delete modal
                                            //Delete arrear
                                            markArrearDirty(params.row.id, "FALSE");
                                        }}
                                    />
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title="Raise Issue" placement="bottom-start">
                                    <BugReport
                                        style={{color: "green"}}
                                        onClick={() => {
                                            //open confirm delete modal
                                            //Delete arrear
                                            markArrearDirty(params.row.id, "TRUE");
                                        }}
                                    />
                                </Tooltip>
                            </>
                        )}
                        <Tooltip title="Delete" placement="bottom-start">
                            <DeleteOutline
                                className="userListDelete"
                                style={{
                                    paddingLeft: "10px",
                                    color: "red",
                                }}
                                onClick={() => {
                                    //open confirm delete modal
                                    //Delete arrear
                                    deleteArrears(params.row.id);
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];
    const transactionColumns = [
        {
            field: "transaction_id",
            headerName: "ID",
            headerAlign: "center",
            // width: 180,
            minWidth: 120,
            flex: 1,
        },
        {
            field: "amount",
            headerName: "Amount",
            headerAlign: "center",
            // width: 150,
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "date",
            align: "center",
            headerAlign: "center",
            headerName: "Date Paid",
            // width: 200,
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                const dateString = params.row.date;
                const isISO8601Format = dateString.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/);

                let dateObject;
                if (!isISO8601Format) {
                    // Parse the string into individual components
                    const year = dateString.substring(0, 4);
                    const month = dateString.substring(4, 6);
                    const day = dateString.substring(6, 8);
                    const hour = dateString.substring(8, 10);
                    const minute = dateString.substring(10, 12);
                    const second = dateString.substring(12, 14);

                    // Create a Date object
                    // dateObject = new Date(year, month - 1, day, hour, minute, second).toString();
                    dateObject = new Date(year, month - 1, day, hour, minute, second).toString().substring(4, 24);

                } else {
                    const convertToCustomFormat = (isoDateString) => {
                        const dateObject = new Date(isoDateString);
                        const options = {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                            timeZone: 'UTC'
                        };
                        return dateObject.toLocaleString('en-US', options);
                    };
                    dateObject = convertToCustomFormat(dateString);
                }


                return (
                    <div>{dateObject}</div>
                );
            },
        },
        {
            field: "date_created",
            align: "center",
            headerAlign: "center",
            headerName: "Date Updated",
            // width: 200,
            minWidth: 200,
            flex: 1,
        },
    ];
    const noticeColumns = [
        {
            field: "date_to_vacate_house",
            headerName: "Date to vacate",
            headerAlign: "center",
            // width: 150,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "date_created",
            align: "center",
            headerAlign: "center",
            headerName: "Date notice given",
            // width: 200,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "",
            headerName: "Action",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                        className="userListAction"
                    >
                        <Button
                            onClick={(event) => {
                                setVacatingModal(true);
                            }}
                            variant="contained"
                        >
                            View notice details
                        </Button>

                        <Tooltip title="Delete" placement="bottom-start">
                            <DeleteOutline
                                className="userListDelete"
                                style={{
                                    paddingLeft: "10px",
                                    color: "red",
                                }}
                                onClick={async () => {
                                    //open confirm delete modal
                                    //Delete arrear
                                    await deleteNotice(params.row.id);
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const [displayDetails, setDisplayDetails] = useState(transactions);
    const [displayColumns, setDisplayColumns] = useState(transactionColumns);
    const [tabValue, setTabValue] = React.useState("transactions");
    const [value, setValue] = React.useState(null);

    const [imageSrc, setImageSrc] = useState("");

    useEffect(() => {
        // Fetch the image from the server
        fetch(
            ApiDetails +
            "pegasus/visionary/authorization/admin/get/specific/tenant/profile/pic",
            {
                method: "POST",
                body: JSON.stringify({
                    tenant_id: tenant_id,
                }),
            }
        )
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    let pic;
                    pic = data.tenant_photo;

                    if (
                        pic.base64 !== "No File Found" &&
                        pic.base64.toString().trim() !== ""
                    ) {
                        // console.log("TRUE")
                        setImageSrc("data:image/jpeg;base64," + pic.base64);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const [modalOpen, setModalOpen] = useState(false);
    const MAX_IMAGE_SIZE_BYTES = 5 * 1024 * 1024; // 5 MB (adjust as needed)

    const updateProfileImage = (file) => {
        let formData = new FormData();
        formData.append(
            "details",
            JSON.stringify({
                tenant_id: tenant.tenant_id,
                landlord_id: JSON.parse(originalText)[0][1],
                property_id: tenant.property_id,
            })
        );
        formData.append("tenant_photo", file);

        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/update/photo", {
            method: "POST",
            body: formData,
        })
            .then(async (response) => {
                await response.json();
                if (response.status === 200) {
                    props.snackBar({
                        text: "Photo updated",
                        type: "success",
                    });
                } else {
                    props.snackBar({
                        text: "Failed uploading photo.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const [profPicLoading, setProfPicLoading] = useState(false);

    const handleDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            // Check if the file size is within the allowed limit
            if (file.size <= MAX_IMAGE_SIZE_BYTES) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    setImageSrc(e.target.result);
                };

                reader.readAsDataURL(file);
                await updateProfileImage(file);
            } else {
                alert("Selected image is too large. Please choose a smaller image.");
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleMouseEnter = () => {
        // Add your fade effect logic or any other styling changes on mouse enter
    };

    const handleMouseLeave = () => {
        // Add your fade effect logic or any other styling changes on mouse leave
    };

    const handleImageClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div className="user">
            {backDrop}

            {dataSet ? (
                <>
                    <Dialog
                        fullWidth
                        maxWidth="md"
                        open={depositModal}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            style={{
                                color: "#398DD2",
                            }}
                        >
                            Deposit details
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {depositDetails.map((deposit) => {
                                        return (
                                            <>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <TextField
                                                        label="Description"
                                                        variant="outlined"
                                                        disabled={true}
                                                        value={deposit.Description}
                                                        InputLabelProps={{shrink: true}}
                                                        style={{marginTop: "10px", width: "35%"}}
                                                    />

                                                    <TextField
                                                        label="Amount"
                                                        variant="outlined"
                                                        disabled={true}
                                                        value={deposit.Amount}
                                                        InputLabelProps={{shrink: true}}
                                                        style={{marginTop: "10px", width: "35%"}}
                                                    />

                                                    <div
                                                        style={{
                                                            marginTop: "10px",
                                                            width: "20%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Switch
                                                            checked={
                                                                deposit.Refundable.toString()
                                                                    .trim()
                                                                    .toLowerCase() === "yes"
                                                            }
                                                            disabled={true}
                                                            inputProps={{"aria-label": "controlled"}}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}

                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            alignContent: "center",
                                        }}
                                    >
                                        <TextField
                                            label="date Joined"
                                            variant="standard"
                                            value={tenant.date_created}
                                            disabled={true}
                                            InputLabelProps={{shrink: true}}
                                            style={{marginTop: "20px", width: "40%"}}
                                        />
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                <span style={{color: "red"}}>Close</span>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={warningModal}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            style={{
                                color: "#398DD2",
                            }}
                        >
                            Warning
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            margin: 10,
                                            textAlign: "center",
                                        }}
                                    >
                                        <WarningAmber
                                            style={{
                                                scale: "3",
                                                color: "#EEB931",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            flex: 3,
                                        }}
                                    >
                                        Notice to vacate are given before the 3rd of the month. If
                                        you proceed, you risk not being refunded your deposit
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    handleClose();
                                    sendVacatingNoticeAfterWarning();
                                }}
                            >
                                <span style={{color: "green"}}>Proceed</span>
                            </Button>
                            <Button onClick={handleClose}>
                                <span style={{color: "red"}}>Cancel</span>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        maxWidth={"md"}
                        open={forceVacatingModal}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            style={{
                                width: "90%",
                            }}
                        >
                            <div
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    color: "#398DD2",
                                }}
                            >
                                Vacating Notice
                            </div>
                        </DialogTitle>
                        <DialogContent
                            style={{
                                padding: "10px",
                            }}
                        >
                            <Formik
                                initialValues={initialValues3}
                                validationSchema={dateSchema}
                                onSubmit={sendVacatingNotice}
                            >
                                {(props) => (
                                    <Form noValidate>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Field
                                                as={TextField}
                                                name="first_name"
                                                label="First Name"
                                                variant="outlined"
                                                error={
                                                    props.errors.first_name && props.touched.first_name
                                                }
                                                helperText={
                                                    <ErrorMessage className="error" name="first_name"/>
                                                }
                                                required
                                                disabled={true}
                                                style={{marginTop: "10px", width: "35%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="last_name"
                                                label="Last Name"
                                                error={
                                                    props.errors.last_name && props.touched.last_name
                                                }
                                                helperText={
                                                    <ErrorMessage className="error" name="last_name"/>
                                                }
                                                required
                                                variant="outlined"
                                                disabled={true}
                                                style={{marginTop: "10px", width: "35%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="national_id"
                                                label="National ID"
                                                type="number"
                                                required
                                                variant="outlined"
                                                disabled={true}
                                                style={{marginTop: "10px", width: "25%"}}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Field
                                                as={TextField}
                                                name="phone_number"
                                                label="Phone Number"
                                                error={
                                                    props.errors.phone_number &&
                                                    props.touched.phone_number
                                                }
                                                className={
                                                    props.errors.phone_number &&
                                                    props.touched.phone_number
                                                        ? "input-error"
                                                        : ""
                                                }
                                                helperText={
                                                    <ErrorMessage className="error" name="phone_number"/>
                                                }
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="unit"
                                                label="House Number"
                                                error={props.errors.unit && props.touched.unit}
                                                helperText={
                                                    <ErrorMessage className="error" name="unit"/>
                                                }
                                                required
                                                disabled={true}
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    disabled={true}
                                                    value={dateToday}
                                                    onChange={() => {
                                                    }}
                                                    variant={"outlined"}
                                                    label="Date Today"
                                                    inputFormat="MM/DD/YYYY"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            variant={"outlined"}
                                                            name="date_notice_given"
                                                            error={
                                                                props.errors.date_notice_given &&
                                                                props.touched.date_notice_given
                                                            }
                                                            helperText={
                                                                <ErrorMessage
                                                                    className="error"
                                                                    name="date_notice_given"
                                                                />
                                                            }
                                                            style={{marginTop: "10px", width: "49%"}}
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Date To Vacate"
                                                    inputFormat="MM/DD/YYYY"
                                                    value={dateToVacate}
                                                    onChange={(newValue) => setDateToVacate(newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            variant={"outlined"}
                                                            name="date_to_vacate_House"
                                                            error={
                                                                props.errors.date_to_vacate_House &&
                                                                props.touched.date_to_vacate_House
                                                            }
                                                            helperText={
                                                                <ErrorMessage
                                                                    className="error"
                                                                    name="date_notice_given"
                                                                />
                                                            }
                                                            style={{marginTop: "10px", width: "49%"}}
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <RichTextEditor
                                                richTextValue={editorState}
                                                change={(value) => {
                                                    setEditorState(value);
                                                }}
                                            />
                                            {/*<Editor editorState={editorState} onChange={setEditorState}/>*/}
                                        </div>

                                        <br/>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "end",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <Button onClick={handleClose}>
                                                <span style={{color: "red"}}>Cancel</span>
                                            </Button>
                                            <LoadingButton
                                                type={"submit"}
                                                size="small"
                                                // onClick={addTenant}
                                                loading={tenantLoading}
                                                startIcon={<SendIcon/>}
                                                style={{color: "green"}}
                                                loadingPosition="start"
                                            >
                                                Submit
                                            </LoadingButton>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </DialogContent>
                    </Dialog>
                </>
            ) : (
                <></>
            )}

            <Dialog
                open={addDepositModal}
                fullWidth
                maxWidth="sm"
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Add deposit
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography>Deposit details</Typography>
                            <Button
                                onClick={() => {
                                    setExpensesIncurred(expensesIncurred + 1);
                                    setInputs(
                                        [...Array(expensesIncurred)].map(
                                            (elementInArray, index) => (
                                                <div key={index}>
                                                    <br/>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <TextField
                                                            label="Description"
                                                            type={"text"}
                                                            variant="outlined"
                                                            name={"description " + (index + 1)}
                                                            required
                                                            inputRef={textRef}
                                                            style={{marginTop: "10px", width: "35%"}}
                                                            onChange={handleChange()}
                                                        />
                                                        <TextField
                                                            label="Amount"
                                                            type={"number"}
                                                            name={"value for description " + (index + 1)}
                                                            variant="outlined"
                                                            style={{marginTop: "10px", width: "35%"}}
                                                            onChange={handleChange()}
                                                        />

                                                        <TextField
                                                            label="refundable"
                                                            type={"text"}
                                                            name={"refundable " + (index + 1)}
                                                            variant="outlined"
                                                            style={{marginTop: "10px", width: "20%"}}
                                                            onChange={handleChange()}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )
                                    );
                                }}
                            >
                                <span style={{color: "green"}}>Add Deposit</span>
                            </Button>
                        </div>
                        <div>{inputs}</div>
                        <br/>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    calculateRefundableDeposit();
                                    // console.log("Adding deposit")
                                }}
                                variant="outlined"
                            >
                                Calculate
                            </Button>
                        </div>

                        <br/>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Total deposit :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "right",
                                    fontSize: "30sp",
                                    fontWeight: "bold",
                                }}
                            >
                                {totalDeposit.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>

                        <div
                            style={{
                                marginTop: "10px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Refundable deposit :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "right",
                                    fontSize: "30sp",
                                    fontWeight: "bold",
                                }}
                            >
                                {refundableDeposit.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            // window.location.reload(false);

                            handleClose();
                            setEditTenantModal(true);
                        }}
                    >
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={() => {
                            // console.log("Adding expenses")
                            handleClose();
                            setEditTenantModal(true);
                        }}
                        disabled={!depositSet}
                        loading={loading1}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Agree
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={generatingReportModal}
                // open={true}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent
                    style={{
                        padding: "30px",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        <h2
                            style={{
                                marginRight: "20px",
                            }}
                        >
                            Generating report
                        </h2>
                        <PulseLoader color={"#6BD9BC"} loading={true}/>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={billingNotFoundModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            color: "red",
                        }}
                    >
                        Error generating report
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    margin: "20px",
                                }}
                            >
                                <h2
                                    style={{
                                        paddingBottom: "10px",
                                    }}
                                >
                                    Billing file not found
                                </h2>
                                <span
                                    style={{
                                        color: "#858585",
                                    }}
                                >
                  This could be as a result of late billing. Kindly ensure to
                  always bill on time
                </span>
                            </div>
                            {/*<FadeLoader color={"#123abc"} loading={true} />*/}
                        </div>
                        <div>
                            <img
                                style={{
                                    width: "200px",
                                    height: "200px",
                                }}
                                src={require("../../assets/file_not_found.jpg")}
                                alt=""
                            />
                            <span
                                style={{
                                    display: "none",
                                }}
                            >
                <a href="http://www.freepik.com">
                  Designed by stories / Freepik
                </a>
              </span>
                        </div>
                    </div>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <span style={{color: "red"}}>Close</span>
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog
                open={transactionModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Payment Details
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues4} onSubmit={addTransaction}>
                        {() => (
                            <Form noValidate>
                                <Field
                                    as={TextField}
                                    name="first_name"
                                    label="Tenant Name"
                                    value={tenantInfo.name + " " + tenantInfo.last_name}
                                    variant="outlined"
                                    fullwidth
                                    required
                                    disabled={true}
                                    style={{marginTop: "8px", width: "100%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    fullwidth
                                    required
                                    variant="outlined"
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                />
                                <div
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disableFuture
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Date "
                                            openTo="year"
                                            views={["year", "month", "day"]}
                                            value={dateFrom}
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <LoadingButton
                                        size="small"
                                        type={"submit"}
                                        // onClick={sendBroadcastMessage}
                                        loading={transactionLoading}
                                        startIcon={<SendIcon/>}
                                        style={{color: "green"}}
                                        loadingPosition="start"
                                    >
                                        Agree
                                    </LoadingButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            {/*Vacating Details MODAL*/}
            <Dialog
                open={invoicesModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Tenant latest bill
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div id="messages">
                        {bills.map((bill, index) => {
                            return (
                                <div className="message my_message">
                                    <p>
                                        <div
                                            style={{
                                                whiteSpace: "pre-wrap",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                            onContextMenu={() => {
                                                // console.log("Registered Right click");
                                            }}
                                        >
                                            {bill.invoice_details}
                                        </div>
                                    </p>
                                </div>
                            );
                        })}
                        <div ref={divRef}></div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "20px",
                        }}
                    >
                        <Button onClick={handleClose}>
                            <span style={{color: "red"}}>Close</span>
                        </Button>
                        <Button onClick={resendBill}>
                            <span style={{color: "green"}}>Resend</span>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            {/*Vacating Details MODAL*/}
            <Dialog
                open={changeUnitModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Update Unit
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <br/>
                    <>
                        <FormControl
                            name="house_number"
                            required
                            style={{
                                marginTop: "10px",
                                width: "100%",
                            }}
                        >
                            <InputLabel id="demo-simple-select-required-label">
                                Select House NO
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={selectedUnit}
                                label="Unit *"
                                onChange={(e) => {
                                    setSelectedUnit(e.target.value);
                                }}
                            >
                                {units.map((unit) => {
                                    if (unit.state === "VACANT") {
                                        return (
                                            <MenuItem value={unit.unit}>
                                                <em>{unit.unit}</em>
                                            </MenuItem>
                                        );
                                    }
                                })}
                            </Select>
                            <FormHelperText>Required</FormHelperText>
                        </FormControl>
                        <br/>
                    </>

                    <br/>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "20px",
                        }}
                    >
                        <Button onClick={handleClose}>
                            <span style={{color: "red"}}>Close</span>
                        </Button>
                        <Button onClick={updateTenantUnit}>
                            <span style={{color: "green"}}>Update</span>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            {/*Vacating Details MODAL*/}
            <Dialog
                open={vacatingModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Vacating Form
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <small
                        style={{
                            color: "red",
                        }}
                    >
                        Kindly refresh if details are not clear
                    </small>
                    <br/>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        value={new Map(Object.entries(tenant)).get("first_name")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        value={new Map(Object.entries(tenant)).get("last_name")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Date To Vacate"
                        variant="outlined"
                        value={new Map(
                            Object.entries(new Map(Object.entries(tenant)).get("notice"))
                        ).get("date_to_vacate_house")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Date Notice Given"
                        variant="outlined"
                        value={new Map(
                            Object.entries(new Map(Object.entries(tenant)).get("notice"))
                        ).get("date_created")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <br/>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                        }}
                    >
                        {dataSet &&
                        new Map(Object.entries(tenant)).get("state") === "NOTICE" ? (
                            <NonEditableRichTextEditor
                                richTextValue={EditorState.createWithContent(
                                    convertFromRaw(
                                        JSON.parse(
                                            new Map(
                                                Object.entries(
                                                    new Map(Object.entries(tenant)).get("notice")
                                                )
                                            ).get("reasons_for_vacating")
                                        )
                                    )
                                )}
                            />
                        ) : (
                            <></>
                        )}
                        {/*<Editor editorState={editorState} onChange={setEditorState}/>*/}
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "20px",
                        }}
                    >
                        <Button onClick={handleClose}>
                            <span style={{color: "green"}}>Close</span>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            {/*Report MODAL*/}
            <Dialog
                fullWidth
                maxWidth="sm"
                open={reportModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Tenant Report
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues} onSubmit={getReport}>
                        {() => (
                            <Form noValidate>
                                <div
                                    style={{
                                        paddingLeft: "10px",
                                        paddingTop: "10px",
                                        paddingBottom: "30px",
                                        display: "flex",
                                        margin: "auto",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: "50px",
                                        }}
                                    >
                                        Generate report for multiple months?
                                    </div>

                                    <Switch
                                        checked={rangeReport}
                                        onChange={() => setRangeReport(!rangeReport)}
                                        inputProps={{"aria-label": "controlled"}}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "calc(100% - 20px)",
                                        margin: "auto",
                                    }}
                                >
                                    <div>
                                        <InputLabel> Select start Date</InputLabel>
                                        <br/>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disableFuture
                                                id="outlined-basic"
                                                variant="outlined"
                                                label="Date"
                                                openTo="year"
                                                views={["year", "month"]}
                                                value={dateFrom}
                                                onChange={(newValue) => {
                                                    setDateFrom(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div>
                                        <InputLabel> Select end Date</InputLabel>
                                        <br/>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                disabled={!rangeReport}
                                                disableFuture
                                                id="outlined-basic"
                                                variant="outlined"
                                                label="Date"
                                                openTo="year"
                                                views={["year", "month"]}
                                                value={dateTo}
                                                onChange={(newValue) => {
                                                    setDateTo(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            if (rangeReport) {
                                                getRangeReport();
                                            } else {
                                                getReport();
                                            }
                                        }}
                                    >
                                        <span style={{color: "green"}}>Submit</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            {/*Arrears MODAL*/}
            <Dialog
                open={arrearsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Add Arrear
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues} onSubmit={addArrears}>
                        {() => (
                            <Form noValidate>
                                {tenantDetails === null ? (
                                    <Field
                                        as={TextField}
                                        name="first_name"
                                        label="Tenant Name"
                                        value={tenant.first_name + " " + tenant.last_name}
                                        variant="outlined"
                                        fullwidth
                                        required
                                        disabled={true}
                                        style={{marginTop: "8px", width: "100%"}}
                                    />
                                ) : (
                                    <Field
                                        as={TextField}
                                        name="first_name"
                                        label="Tenant Name"
                                        value={
                                            tenantDetails.first_name + " " + tenantDetails.last_name
                                        }
                                        variant="outlined"
                                        fullwidth
                                        required
                                        disabled={true}
                                        style={{marginTop: "8px", width: "100%"}}
                                    />
                                )}
                                <Field
                                    as={TextField}
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    fullwidth
                                    required
                                    variant="outlined"
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                />
                                <Field
                                    as={TextareaAutosize}
                                    name="description"
                                    required
                                    fullwidth
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Arrear description ... "
                                    style={{width: "100%"}}
                                />
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <Button type="submit">
                                        <span style={{color: "green"}}>Submit</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            {/*Edit Tenant MODAL*/}
            <Dialog
                maxWidth={"md"}
                open={editTenantModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{
                        width: "90%",
                    }}
                >
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Edit Tenant
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    {dataSet ? (
                        <>
                            <div
                                style={{
                                    padding: "10px",
                                    color: "#959EAD",
                                    borderRadius: "5px",
                                    border: "2px solid blue",
                                }}
                            >
                                <Formik
                                    initialValues={initialValues2}
                                    // validationSchema={validationSchema}
                                    onSubmit={updateTenant}
                                >
                                    {(props) => (
                                        <Form noValidate>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Field
                                                    as={TextField}
                                                    name="first_name"
                                                    label="First Name"
                                                    variant="outlined"
                                                    error={
                                                        props.errors.first_name && props.touched.first_name
                                                    }
                                                    helperText={<ErrorMessage name="first_name"/>}
                                                    required
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="last_name"
                                                    label="Last Name"
                                                    error={
                                                        props.errors.last_name && props.touched.last_name
                                                    }
                                                    helperText={<ErrorMessage name="last_name"/>}
                                                    required
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Field
                                                    as={TextField}
                                                    name="phone_number"
                                                    label="Phone Number"
                                                    error={
                                                        props.errors.phoneNumber &&
                                                        props.touched.phoneNumber
                                                    }
                                                    helperText={<ErrorMessage name="phoneNumber"/>}
                                                    required
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "32%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="secondary_phone_number"
                                                    label="Secondary Number"
                                                    error={
                                                        props.errors.secondary_phone_number &&
                                                        props.touched.secondary_phone_number
                                                    }
                                                    helperText={
                                                        <ErrorMessage name="secondary_phone_number"/>
                                                    }
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "32%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="national_id"
                                                    label="National ID"
                                                    type="number"
                                                    required
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "32%"}}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <FormControl
                                                    required
                                                    style={{
                                                        marginTop: "10px",
                                                        width: "49%",
                                                    }}
                                                    label="Property *"
                                                    name="is_student"
                                                >
                                                    <InputLabel id="demo-simple-select-required-label">
                                                        Student
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-required-label"
                                                        id="demo-simple-select-required"
                                                        value={isStudent}
                                                        label="Property *"
                                                        onChange={(e) => {
                                                            setIsStudent(e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value="YES">
                                                            <em>YES</em>
                                                        </MenuItem>
                                                        <MenuItem value="NO">
                                                            <em>NO</em>
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>Required</FormHelperText>
                                                </FormControl>
                                                {isStudent === "YES" ? (
                                                    <>
                                                        <Field
                                                            as={TextField}
                                                            name="institution_name"
                                                            label="Institution Name"
                                                            variant="outlined"
                                                            error={
                                                                props.errors.first_name &&
                                                                props.touched.first_name
                                                            }
                                                            helperText={<ErrorMessage name="first_name"/>}
                                                            style={{marginTop: "10px", width: "49%"}}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Field
                                                            as={TextField}
                                                            name="institution_name"
                                                            label="Institution Name"
                                                            variant="outlined"
                                                            disabled={true}
                                                            error={
                                                                props.errors.first_name &&
                                                                props.touched.first_name
                                                            }
                                                            helperText={<ErrorMessage name="first_name"/>}
                                                            style={{marginTop: "10px", width: "49%"}}
                                                        />
                                                    </>
                                                )}
                                                <br/>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Field
                                                    as={TextField}
                                                    name="occupation_or_profession"
                                                    label="Occupation"
                                                    variant="outlined"
                                                    error={
                                                        props.errors.first_name && props.touched.first_name
                                                    }
                                                    helperText={<ErrorMessage name="first_name"/>}
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="place_of_work"
                                                    label="Place Of Work"
                                                    error={
                                                        props.errors.last_name && props.touched.last_name
                                                    }
                                                    helperText={<ErrorMessage name="last_name"/>}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Field
                                                    as={TextField}
                                                    name="reason_for_relocating"
                                                    label="Reason For Relocation"
                                                    variant="outlined"
                                                    error={
                                                        props.errors.first_name && props.touched.first_name
                                                    }
                                                    helperText={<ErrorMessage name="first_name"/>}
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="previous_residence"
                                                    label="Previous Residence"
                                                    error={
                                                        props.errors.last_name && props.touched.last_name
                                                    }
                                                    helperText={<ErrorMessage name="last_name"/>}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Field
                                                    as={TextField}
                                                    name="rent"
                                                    label="Rent"
                                                    type="number"
                                                    required
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="deposit_held"
                                                    label="Refundable Deposit"
                                                    type="number"
                                                    required
                                                    value={refundableDeposit}
                                                    disabled={true}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        setEditTenantModal(false);
                                                        setAddDepositModal(true);
                                                        // console.log("Adding deposit")
                                                    }}
                                                >
                                                    <span style={{color: "green"}}>Add deposit</span>
                                                </Button>
                                            </div>

                                            <br/>
                                            <Typography style={{textAlign: "center"}}>
                                                NEXT OF KIN DETAILS
                                            </Typography>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Field
                                                    as={TextField}
                                                    name="next_of_kin_first_name"
                                                    label="First Name"
                                                    error={
                                                        props.errors.first_name && props.touched.first_name
                                                    }
                                                    helperText={<ErrorMessage name="first_name"/>}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "32%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="next_of_kin_last_name"
                                                    label="Last Name"
                                                    error={
                                                        props.errors.first_name && props.touched.first_name
                                                    }
                                                    helperText={<ErrorMessage name="first_name"/>}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "32%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="next_of_kin_relationship"
                                                    label="Relationship"
                                                    error={
                                                        props.errors.first_name && props.touched.first_name
                                                    }
                                                    helperText={<ErrorMessage name="first_name"/>}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "32%"}}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Field
                                                    as={TextField}
                                                    name="next_of_kin_contacts"
                                                    label="Phone Number"
                                                    error={
                                                        props.errors.phoneNumber &&
                                                        props.touched.phoneNumber
                                                    }
                                                    helperText={<ErrorMessage name="phoneNumber"/>}
                                                    required
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                                <Field
                                                    as={TextField}
                                                    name="next_of_kin_national_id"
                                                    label="National ID"
                                                    type="number"
                                                    required
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "49%"}}
                                                />
                                            </div>

                                            <br/>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    // marginTop: "10px",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <Button type="submit">
                                                    <span style={{color: "green"}}>Submit</span>
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div
                                style={{
                                    padding: "10px",
                                    marginTop: "5px",
                                    color: "#959EAD",
                                    borderRadius: "5px",
                                    border: "2px solid blue",
                                }}
                            >
                                <Formik
                                    initialValues={initialValues2}
                                    // validationSchema={validationSchema}
                                    onSubmit={updateTenantFiles}
                                >
                                    {(props) => (
                                        <Form noValidate>
                                            <br/>
                                            <Typography style={{textAlign: "center"}}>
                                                TENANT FILES
                                            </Typography>
                                            <br/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                        <span
                            style={{
                                width: "49%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                          {tenantPhoto === "" ? (
                              <>
                                  <span>Tenant Photo </span>
                                  <Button variant="outlined" component="label">
                                      Upload File
                                      <input
                                          type="file"
                                          onChange={(e) => handleFile1(e)}
                                          hidden
                                      />
                                  </Button>
                              </>
                          ) : (
                              <>
                                  <div
                                      style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                      }}
                                  >
                                      <span>{photo}</span>
                                      <DeleteOutline
                                          className="userListDelete"
                                          onClick={() => {
                                              setTenantPhoto("");
                                              setPhoto(null);
                                          }}
                                      />
                                  </div>
                              </>
                          )}
                        </span>
                                                <span
                                                    style={{
                                                        width: "49%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                          {photoOfNationalID === "" ? (
                              <>
                                  <span>Photo Of National ID </span>
                                  <Button variant="outlined" component="label">
                                      Upload File
                                      <input
                                          type="file"
                                          onChange={(e) => handleFile2(e)}
                                          hidden
                                      />
                                  </Button>
                              </>
                          ) : (
                              <>
                                  <>
                                      <div
                                          style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%",
                                          }}
                                      >
                                          <span>{nationalID}</span>
                                          <DeleteOutline
                                              className="userListDelete"
                                              onClick={() => {
                                                  setPhotoOfNationalID("");
                                                  setNationalID(null);
                                              }}
                                          />
                                      </div>
                                  </>
                              </>
                          )}
                        </span>





                                            </div>
                                            <br/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                        <span
                            style={{
                                width: "49%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                          {copyOfContract === "" ? (
                              <>
                                  {" "}
                                  <span>Copy Of Contract</span>
                                  <Button variant="outlined" component="label">
                                      Upload File
                                      <input
                                          type="file"
                                          onChange={(e) => handleFile3(e)}
                                          hidden
                                      />
                                  </Button>
                              </>
                          ) : (
                              <>
                                  <>
                                      <div
                                          style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%",
                                          }}
                                      >
                                          <span>{contract}</span>
                                          <DeleteOutline
                                              className="userListDelete"
                                              onClick={() => {
                                                  setCopyOfContract("");
                                                  setContract(null);
                                              }}
                                          />
                                      </div>
                                  </>
                              </>
                          )}
                        </span>
                                                <span
                                                    style={{
                                                        width: "49%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                          {inventoryChecklist === "" ? (
                              <>
                                  <span>Inventory Checklist</span>
                                  <Button variant="outlined" component="label">
                                      Upload File
                                      <input
                                          type="file"
                                          onChange={(e) => handleFile4(e)}
                                          hidden
                                      />
                                  </Button>
                              </>
                          ) : (
                              <>
                                  <>
                                      <div
                                          style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              width: "100%",
                                          }}
                                      >
                                          <span>{checklist}</span>
                                          <DeleteOutline
                                              className="userListDelete"
                                              onClick={() => {
                                                  setInventoryChecklist("");
                                                  setChecklist(null);
                                              }}
                                          />
                                      </div>
                                  </>
                              </>
                          )}
                        </span>
                                            </div>

                                            <br/>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <Button type="submit">
                                                    <span style={{color: "green"}}>Submit</span>
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                </DialogActions>
            </Dialog>

            {/*Bill Tenants MODAL*/}
            <Dialog
                fullWidth
                maxWidth="xl"
                open={billTenantsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Billing Tenants
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        height: "400px",
                    }}
                >
                    <div
                        style={{
                            height: "300px", // width: "fit-content",
                            width: "95vw",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    marginRight: "10px",
                                }}
                            >
                                Billing for which month?
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    // disableFuture
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Date"
                                    openTo="year"
                                    views={["year", "month"]}
                                    value={month}
                                    onChange={(newValue) => {
                                        setMonth(newValue);
                                        setSelectedMonth(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <>
                            {warning ? (
                                <div
                                    style={{
                                        color: "red",
                                    }}
                                >
                                    Kindly fill all units' meter readings before billing..
                                </div>
                            ) : (
                                <></>
                            )}
                            <DataGrid
                                getRowId={(row) => row.tenant_id}
                                className={classes.root}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field: "house_number", sort: "asc"}],
                                    },
                                }}
                                rows={tenantList}
                                disableSelectionOnClick
                                rowHeight={45}
                                columns={billingColumns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                            />
                        </>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            fetch(ApiDetails + "pegasus/visionary/property/enableBilling", {
                                method: "POST",
                                body: JSON.stringify({
                                    property_id: tenant.property_id,
                                }),
                            })
                                .then(async (response) => {
                                    if (response.status === 200) {
                                        window.location.reload(false);
                                    } else {
                                        props.snackBar({
                                            text: "Failed updating tenant details.",
                                            type: "error",
                                        });
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                    props.snackBar({
                                        text: "Something Went Wrong. Call Customer Care For Assistance.",
                                        type: "error",
                                    });
                                });
                            handleClose();
                        }}
                    >
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={billTenant}
                        loading={billLoading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                        disabled={!!warning}
                    >
                        Bill
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                classes={{paper: classes.dialogPaper}}
                open={messageModal}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>{tenant.first_name} message</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            width: "calc(100% - 20px)",
                            padding: "10px",
                        }}
                    ></div>
                    <Textarea
                        minRows={5}
                        placeholder="Type message .."
                        onChange={(event) => {
                            setBroadcastMessage(event.target.value);
                        }}
                        style={{width: "100%"}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={sendMessage}
                        loading={messageLoading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Send
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <div
                style={
                    {
                        // alignItems: "center",
                        // alignContent: "center"
                    }
                }
                className="userShowDetails"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            width: "220px",
                            height: "220px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            marginRight: "10px",
                            border: "2px dashed #ccc",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                        }}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleImageClick}
                    >
                        {profPicLoading && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <CircularProgress/>
                            </div>
                        )}

                        <Avatar
                            style={{
                                width: "220px",
                                height: "220px",
                                objectFit: "cover",
                            }}
                            alt="Admin profile picture"
                            src={imageSrc === "" ? imageAvatar : imageSrc}
                        />
                    </div>

                    {modalOpen && (
                        <div
                            style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1000,
                                transition: "opacity 0.3s ease-in-out",
                            }}
                            onClick={handleCloseModal}
                        >
                            <div
                                style={{
                                    opacity: modalOpen ? 1 : 0,
                                }}
                                className={"pictureModal"}
                            >
                                <img
                                    src={imageSrc === "" ? imageAvatar : imageSrc}
                                    style={{width: "100%", height: "100%"}}
                                    alt={"Tenant photo"}/>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className="featuredItemBox"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginLeft: "10px",
                        boxShadow: "5px 5px 9px 2px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <div
                        style={{
                            fontFamily: "'DM Serif Display', serif",
                            letterSpacing: 1,
                        }}
                    >
                        <div
                            style={{
                                color: "#9A005A",
                                letterSpacing: 2,
                                marginBottom: "20px",
                                textTransform: "uppercase",
                                fontFamily: "'DM Serif Display', serif",
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                            }}
                        >
                            <h2> {tenant.first_name + "  " + tenant.last_name}</h2>

                            <Tooltip
                                title="Edit details"
                                style={{
                                    marginLeft: "40px",
                                }}
                            >
                                <IconButton
                                    onClick={() => {
                                        setEditTenantModal(true);
                                    }}
                                >
                                    <EditOutlined
                                        style={{
                                            color: "blue",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div
                            style={{
                                fontFamily: "'Roboto', sans-serif",
                                paddingLeft: "20px",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    paddingBottom: "10px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                    }}
                >
                  <LocalPhoneOutlined
                      style={{
                          color: "#00ADA5",
                          marginRight: "10px",
                      }}
                  />
                  Phone:
                </span>
                                <span style={{flex: 1}}>
                  {tenant.phone_number}
                                    {tenant.secondary_phone_number.toString().trim() !== "" &&
                                    tenant.secondary_phone_number.toString().trim() !== "0" &&
                                    tenant.secondary_phone_number.toString().trim() !== "254" ? (
                                        <>
                                            <span> / </span> {tenant.secondary_phone_number}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                </span>
                            </div>
                            <div
                                style={{
                                    paddingBottom: "10px",
                                    display: "flex",
                                    justifyItems: "space-between",
                                    alignItems: "center",
                                }}
                            >
                <span
                    style={{
                        display: "flex",
                        alignContent: "center",
                        flex: 1,
                    }}
                >
                  <EmailOutlined
                      style={{
                          color: "#00ADA5",
                          marginRight: "10px",
                      }}
                  />
                  Property
                </span>

                                <span style={{flex: 1}}>{tenant.property_name}</span>
                            </div>

                            <div
                                style={{
                                    paddingBottom: "10px",
                                    display: "flex",
                                    justifyItems: "space-between",
                                    alignItems: "center",
                                }}
                            >
                <span
                    style={{
                        display: "flex",
                        alignContent: "center",
                        flex: 1,
                    }}
                >
                   <AccountBalanceOutlined
                       style={{
                           color: "#00ADA5",
                           marginRight: "10px",
                       }}
                   />
                                  Account number
                </span>

                                <span style={{flex: 1}}>{tenant.account_number}</span>
                            </div>

                            <div
                                style={{
                                    paddingBottom: "10px",
                                    display: "flex",
                                    justifyItems: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        alignContent: "center",
                                        flex: 1,
                                    }}
                                >
                                  <AttachMoneyOutlined
                                      style={{
                                          color: "#00ADA5",
                                          marginRight: "10px",
                                      }}
                                  />
                                  Rent
                                </span>
                                <span style={{flex: 1}}>
                  {parseInt(tenant.rent).toLocaleString("en-US", {
                      style: "currency",
                      currency: "KSH",
                  })}
                </span>
                            </div>

                            <div
                                style={{
                                    paddingBottom: "10px",
                                    display: "flex",
                                    justifyItems: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        alignContent: "center",
                                        flex: 1,
                                    }}
                                >
                                  <AttachMoneyOutlined
                                      style={{
                                          color: "#00ADA5",
                                          marginRight: "10px",
                                      }}
                                  />
                                  Deposit
                                </span>
                                <span style={{flex: 1}}>
                  {parseInt(tenant.deposit_held).toLocaleString("en-US", {
                      style: "currency",
                      currency: "KSH",
                  })}
                </span>
                            </div>

                            <div
                                style={{
                                    paddingBottom: "10px",
                                    display: "flex",
                                    justifyItems: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        display: "flex",
                                        alignContent: "center",
                                        flex: 1,
                                    }}
                                >
                                  <EventNote
                                      style={{
                                          color: "#00ADA5",
                                          marginRight: "10px",
                                      }}
                                  />
                                  Joined
                                </span>

                                <span style={{flex: 1}}>
                  {new Date(tenant.date_created).toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric'
                  })}
                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        flex: "1",
                        marginRight: "10px",
                        marginLeft: "10px",
                        // padding: "10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                        className="featuredItemBox"
                    >
                        <div
                            style={{
                                paddingBottom: "20px",
                            }}
                        >
              <span
                  className="featuredTitle"
                  style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      paddingBottom: "10px",
                  }}
              >
                <AccountBalanceWallet
                    style={{color: "#398DD2", marginRight: "10px"}}
                />
                Wallet balance
              </span>
                            <div className="featuredMoneyContainer">
                <span
                    className="featuredMoney"
                    style={{
                        width: "100%",
                    }}
                >
                  <div
                      style={{
                          // width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: "30px",
                      }}
                  >
                    {!showPassword ? (
                        <>
                            <div
                                style={{
                                    scale: "0.8",
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                <StarHalfOutlined/>
                                <StarHalfOutlined/>
                                <StarHalfOutlined/>
                                <StarHalfOutlined/>
                            </div>
                            <VisibilityOutlined
                                style={{
                                    scale: "1",
                                    color: "blue",
                                }}
                                onClick={() => {
                                    togglePasswordVisibility();
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <div
                                style={{
                                    fontFamily: "'EB Garamond', serif",
                                    fontSize: "20px",
                                    color: "green",
                                }}
                            >
                                {tenant.status === "COMPLETED" ? (
                                    tenant.Wallet.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "KSH",
                                    })
                                ) : (
                                    0.0.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "KSH",
                                    })
                                )}
                            </div>

                            <VisibilityOffOutlined
                                style={{
                                    scale: "1",
                                    color: "red",
                                }}
                                onClick={() => {
                                    togglePasswordVisibility();
                                }}
                            />
                        </>
                    )}
                  </div>
                </span>
                            </div>
                        </div>

                        <div>
              <span
                  className="featuredTitle"
                  style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                  }}
              >
                <AccessTimeIcon
                    style={{color: "#398DD2", marginRight: "10px"}}
                />
                Pending bill
              </span>
                            <div className="featuredMoneyContainer">
                <span
                    className="featuredMoney"
                    style={{
                        width: "100%",
                    }}
                >
                  <div
                      style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: "30px",
                      }}
                  >
                    {!showPassword ? (
                        <>
                            <div
                                style={{
                                    scale: "0.8",
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                <StarHalfOutlined/>
                                <StarHalfOutlined/>
                                <StarHalfOutlined/>
                                <StarHalfOutlined/>
                            </div>
                        </>
                    ) : (

                        <div
                            style={{
                                fontFamily: "'EB Garamond', serif",
                                fontSize: "20px",
                                color: "green",
                            }}
                        >
                            {tenant.status === "PENDING" ? (
                                tenant.Wallet.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })
                            ) : (
                                0.0.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })
                            )}
                        </div>
                    )}

                      <Button
                          variant="outlined"
                          onClick={() => {
                              setTenantInfo({
                                  id: tenant.tenant_id,
                                  name: tenant.first_name,
                                  last_name: tenant.last_name,
                                  account_number: tenant.account_number,
                              });
                              setTransactionModal(true);
                          }}
                      >
                      Pay now
                    </Button>
                  </div>
                </span>
                            </div>
                            <div className="featuredSub">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            getTenantBills();

                                        }}
                                        style={{
                                            color: "#398DD2",
                                            marginLeft: "30px",
                                            marginTop: "15px",
                                        }}
                                    >
                                        Latest bill details
                                    </Button>

                                    <Button
                                        style={{
                                            marginTop: "20px",
                                        }}
                                        onClick={(event) => {
                                            setAnchorEl(event.currentTarget);
                                        }}
                                        variant="outlined"
                                    >
                                        More
                                        <ExpandMoreOutlined/>
                                    </Button>

                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseMenu}
                                        // onClick={handleCloseMenu}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: "visible",
                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                mt: 1.5,
                                                "& .MuiAvatar-root": {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                "&:before": {
                                                    content: '""',
                                                    display: "block",
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: "background.paper",
                                                    transform: "translateY(-50%) rotate(45deg)",
                                                    zIndex: 0,
                                                },
                                            },
                                            style: {
                                                // maxHeight: 60 * 4.5,
                                                width: "20ch",
                                            },
                                        }}
                                        transformOrigin={{horizontal: "right", vertical: "top"}}
                                        anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                                    >
                                        <Menu
                                            anchorEl={anchorEl1}
                                            id="account-menu"
                                            open={Boolean(anchorEl1)}
                                            onClose={() => {
                                                setAnchorEl1(null);
                                                setAnchorEl(null);
                                            }
                                            }
                                            onClick={() => {
                                                setAnchorEl1(null);
                                                setAnchorEl(null);
                                            }
                                            }
                                            anchorOrigin={{vertical: "top", horizontal: "right"}}
                                            transformOrigin={{vertical: "top", horizontal: "left"}}
                                        >
                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    setMessageModal(true);
                                                }}
                                            >
                                                <MessageOutlined
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}
                                                />
                                                Send message
                                            </MenuItem>
                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    sendCredentials();
                                                }}
                                            >
                                                <Lock
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}
                                                />
                                                Send Credentials
                                            </MenuItem>
                                        </Menu>

                                        <Menu
                                            anchorEl={anchorEl2}
                                            id="account-menu"
                                            open={Boolean(anchorEl2)}
                                            onClose={() => {
                                                setAnchorEl2(null);
                                                setAnchorEl(null);
                                            }
                                            }
                                            onClick={() => {
                                                setAnchorEl2(null);
                                                setAnchorEl(null);
                                            }
                                            }
                                            anchorOrigin={{vertical: "top", horizontal: "right"}}
                                            transformOrigin={{vertical: "top", horizontal: "left"}}
                                        >
                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    setReportModal(true);
                                                }}
                                            >
                                                <BarChart
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}
                                                />{" "}
                                                Download report
                                            </MenuItem>

                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    downloadTenantFile("photo_of_national_id");

                                                }}
                                            >
                                                <RememberMeOutlined
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}
                                                />{" "}
                                                Download tenant ID
                                            </MenuItem>
                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    downloadTenantFile("tenant_photo");

                                                }}
                                            >
                                                <PersonOutlineOutlined
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}
                                                />{" "}
                                                Download tenant Photo
                                            </MenuItem>

                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    downloadTenantFile("copy_of_contract");

                                                }}
                                            >
                                                <GavelRounded
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}
                                                />{" "}
                                                Download tenant contract
                                            </MenuItem>

                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    downloadTenantFile("inventory_checklist");

                                                }}
                                            >
                                                <PlaylistAddCheckOutlined
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}
                                                />{" "}
                                                Download tenant checklist
                                            </MenuItem>
                                        </Menu>

                                        <Menu
                                            anchorEl={anchorEl3}
                                            id="account-menu"
                                            open={Boolean(anchorEl3)}
                                            onClose={() => {
                                                setAnchorEl3(null);
                                                setAnchorEl(null);
                                            }
                                            }
                                            onClick={() => {
                                                setAnchorEl3(null);
                                                setAnchorEl(null);
                                            }
                                            }
                                            anchorOrigin={{vertical: "top", horizontal: "right"}}
                                            transformOrigin={{vertical: "top", horizontal: "left"}}
                                        >
                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    setForceVacatingModal(true);
                                                }}
                                            >
                                                <Receipt
                                                    style={{
                                                        paddingRight: "10px",
                                                    }}
                                                />{" "}
                                                Send vacating notice
                                            </MenuItem>
                                            <MenuItem
                                                style={{
                                                    padding: "10px , 0px",
                                                }}
                                                onClick={() => {
                                                    removeVacatingNotice({tenant_id: tenant.tenant_id});
                                                }}
                                            >
                                                <Receipt
                                                    style={{
                                                        paddingRight: "10px",
                                                        color: "red",
                                                    }}
                                                />{" "}
                                                cancel vacating notice
                                            </MenuItem>
                                        </Menu>

                                        <MenuItem
                                            style={{
                                                padding: "10px , 0px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                            onClick={() => {
                                                setAnchorEl(null)
                                                setEditTenantModal(true);
                                            }}
                                        >
                                            <EditOutlined
                                                style={{
                                                    paddingRight: "10px",
                                                }}
                                            />
                                            Edit details
                                        </MenuItem>
                                        <MenuItem
                                            style={{
                                                padding: "10px , 0px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                            onClick={async () => {
                                                setAnchorEl(null)
                                                setBillTenantsModal(true);
                                            }}
                                        >
                                            <HomeOutlined
                                                style={{
                                                    paddingRight: "10px",
                                                }}
                                            />
                                            Bill Tenant
                                        </MenuItem>
                                        <MenuItem
                                            style={{
                                                padding: "10px , 0px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                            onClick={async () => {
                                                setAnchorEl(null)
                                                await getUnits();
                                                setChangeUnitModal(true);
                                            }}
                                        >
                                            <PublishedWithChangesOutlined
                                                style={{
                                                    paddingRight: "10px",
                                                }}
                                            />
                                            Change unit
                                        </MenuItem>

                                        <MenuItem
                                            style={{
                                                padding: "10px , 0px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                            onClick={async () => {
                                                setAnchorEl(null)
                                                setDepositModal(true);
                                            }}
                                        >
                                            <AccountBalanceOutlined
                                                style={{
                                                    paddingRight: "10px",
                                                }}
                                            />
                                            Deposit details
                                        </MenuItem>
                                        <Divider/>

                                        <MenuItem
                                            style={{
                                                padding: "10px , 0px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                            onClick={(event) => {
                                                setAnchorEl1(event.currentTarget);
                                            }}
                                        >
                                            <MessageOutlined
                                                style={{
                                                    paddingRight: "10px",
                                                }}
                                            />
                                            Messages
                                            {dropDownMenu}
                                        </MenuItem>

                                        <MenuItem
                                            style={{
                                                padding: "10px , 0px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                            onClick={(event) => {
                                                setAnchorEl2(event.currentTarget);
                                            }}
                                        >
                                            <CloudDownloadOutlined
                                                style={{
                                                    paddingRight: "10px",
                                                }}
                                            />
                                            Downloads
                                            {dropDownMenu}
                                        </MenuItem>

                                        <MenuItem
                                            style={{
                                                padding: "10px , 0px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                            onClick={(event) => {
                                                setAnchorEl3(event.currentTarget);
                                            }}
                                        >
                                            <BarChart
                                                style={{
                                                    paddingRight: "10px",
                                                }}
                                            />
                                            Notices
                                            {dropDownMenu}
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                        {/*<div className="featuredSub">Money in his/her account</div>*/}
                    </div>
                </div>
            </div>

            <Box sx={{width: "100%", marginTop: "10px"}}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <Tab value="transactions" label="Transactions"/>
                    <Tab value="arrears" label="Arrears"/>
                    <Tab value="notices" label="Vacating Notices"/>
                </Tabs>
            </Box>
            {/* Tenant arrears and transactions */}
            <div
                style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: matches ? "column" : "",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "40vh",
                    }}
                >

                    {displayAddArrear ?
                        <FloatingButton
                            onClick={() => setArrearsModal(true)}
                        />
                        :
                        <></>
                    }
                    <DataGrid
                        className={classes.root}
                        rows={displayDetails}
                        disableSelectionOnClick
                        rowHeight={45}
                        columns={displayColumns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </div>
            </div>
        </div>
    );
}
