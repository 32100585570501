import React, {useEffect, useState} from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
    Divider,
    Link,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    InputAdornment, Autocomplete,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import logo from "../../../../assets/react-logo-no-background.svg";
import {ApiDetails, KEY, SITE_KEY, SKY_AUTH_ApiDetails} from "../../../../dummyData";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {LockOutlined} from "@material-ui/icons";
import CryptoJS from "crypto-js";
import {useLocation, useNavigate} from "react-router-dom";
import {showSnackbar} from "../../../SnackBar/SnackBar";

const QRAuthentication = (props) => {

    let userData = 0;

    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(<VisibilityOutlined/>);

    const initialValues = {
        email_address: "",
        password: "",
    };

    const validationSchema = Yup.object().shape({
        email_address: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string().required('Password is required'),
    });


    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [loadingAuthentication, setLoadingAuthentication] =
        React.useState(false);

    const [code, setCode] = useState("");
    const [timeLeft, setTimeLeft] = useState(60);

    useEffect(() => {
        if (timeLeft === 0) {
            // Redirect to login page after timer expires
            window.location.href = "/";
        }
        const timer = setInterval(() => {
            setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);


    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md bg-white rounded-2xl shadow-md space-y-6"
                 style={{paddingLeft: "32px", paddingRight: "32px",}}>
                {/* QR Code Section */}
                {/* Countdown Timer Section */}

                <div className="flex flex-col items-center mt-4"
                     style={{paddingBottom: "5px",}}

                >
                    <p className={`font-medium text-lg ${timeLeft <= 20 ? 'text-red-600' : 'text-gray-700'}`}>Time
                        Remaining: {timeLeft}s</p>
                </div>

                <div className="flex flex-col items-center">
                    <img
                        src={"data:image/png;base64," + props.base_64_image}
                        alt="QR Code"
                        className="w-30 h-30 object-contain"
                    />
                    <p className="text-gray-600 mt-4 text-sm text-center">
                        Scan the QR code with your app or enter the authentication code manually.
                    </p>
                </div>

                {/* Authentication Code Section */}
                <form id="form" className="flex flex-col space-y-4">
                    <label htmlFor="auth_code" className="text-left text-gray-700 font-medium">
                        Sky Auth Code
                    </label>
                    <input
                        type="number"
                        id="auth_code"
                        className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                        placeholder="Enter authentication code"
                        onChange={(e) => {
                            setCode(e.target.value)
                        }}
                    />

                    <LoadingButton
                        endIcon={<LockOutlined/>}
                        loading={loadingAuthentication}
                        loadingPosition="end"
                        variant="contained"
                        onClick={(event) => {
                            props.validateCode(code)
                        }}
                        className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none"
                    >
                        Verify Code
                    </LoadingButton>

                    <span className="text-red-600 text-sm text-center">
            Kindly enter the auth code or scan the QR code before the timer expires.
          </span>
                </form>
            </div>
        </div>

    );
}

export default QRAuthentication;
