import React from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import "./FormPage.css";
import {Typography} from "@mui/material";
import {showSnackbar} from "../../../../../components/SnackBar/SnackBar";

export default function PaymentFormComponent(props) {

    const {propertyDetails, originalDetails} = props; // Access tenant files from props

    const validationSchema = Yup.object({
        consumer_secret: Yup.string().required("Consumer secret is required"),
        ho_number: Yup.number().required("HO number is required"),
        pass_key: Yup.string().required("Pass key is required"),
        short_code: Yup.string().required("Short code is required"),
        till_number: Yup.number().required("Till number is required"),
    });


    const initialValues = {
        consumer_secret: propertyDetails.consumer_secret,
        ho_number: propertyDetails.ho_number,
        pass_key: propertyDetails.pass_key,
        short_code: propertyDetails.short_code,
        till_number: propertyDetails.till_number,
    }

    const handleSubmit = (values) => {
        console.log("Form Data Submitted:", values);
        showSnackbar({
            title: "Success!",
            message: "Form submitted successfully.",
            type: "success",
            position: {vertical: "bottom", horizontal: "right"},
        });
    };

    if (propertyDetails.property_id === "") return <div>Loading...</div>;

    return (
        <div className="container">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form className="form">
                        {/* Editable Information */}
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Consumer Secret:</Typography>
                                <Field
                                    type="text"
                                    id="consumer_secret"
                                    name="consumer_secret"
                                    placeholder="Enter Consumer Secret"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="consumer_secret"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">HO Number:</Typography>
                                <Field
                                    type="number"
                                    id="ho_number"
                                    name="ho_number"
                                    placeholder="Enter HO Number"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="ho_number"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Pass Key:</Typography>
                                <Field
                                    type="text"
                                    id="pass_key"
                                    name="pass_key"
                                    placeholder="Enter Pass Key"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="pass_key"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">Short Code:</Typography>
                                <Field
                                    type="text"
                                    id="short_code"
                                    name="short_code"
                                    placeholder="Enter Short Code"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="short_code"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Till Number:</Typography>
                                <Field
                                    type="number"
                                    id="till_number"
                                    name="till_number"
                                    placeholder="Enter Till Number"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="till_number"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="form-row">
                            <button type="submit" className="button primary">
                                Submit Form
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
