import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    Divider, IconButton, TextField,
} from "@mui/material";
import {styled} from "@mui/system";
import {ApiDetails, KEY} from "../../../../dummyData";
import FormComponent from "../Form/Details Form/FormComponent";
import MessagesComponent from "../Message/MessagesComponent";
import TenantFiles from "../Download Files/TenantFiles";
import TenantReport from "../Report/TenantReport";
import CryptoJS from "crypto-js";
import Account from "../Account/Account";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import DialogActions from "@mui/material/DialogActions";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import PropertyTenants from "../Property Tenants/PropertyTenants";
import PaymentFormComponent from "../Form/Payment Details Form/FormComponent";

const themeColor = "#2C5283";

const StyledCard = styled(Card)(({theme}) => ({
    marginBottom: theme.spacing(2),
    border: "1px solid #DDE6ED",
    borderRadius: theme.spacing(1),
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const RightSide = (props) => {

    const {propertyDetails, tenants, setBillTenantsModal,billTenantsModal} = props;


    let myMapDetailsDecrypted = "";

    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);

    } catch (e) {
        console.log(e);
    }

    const originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };


    const broadCastCredentials = () => {

        let values = {
            property_id: propertyDetails.property_id, landlord_id: JSON.parse(originalText)[0][1],
        };

        fetch(ApiDetails + "pegasus/visionary/messages/broadcast/credentials", {
            method: "POST", body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    showSnackbar({
                        title: "Success!",
                        message: "Credentials resent successfully.",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                } else if (response.status === 500) {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later..",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later..",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });
    };

    return (
        <>
            <Grid item xs={15} md={8}>
                {/* Tabs Section */}
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{marginBottom: 2}}
                >
                    <Tab label="Tenants"/>
                    <Tab label="Property Details"/>
                    <Tab label="Payment Details"/>
                    <Tab label="Broadcast Message"/>
                    <Tab label="Report"/>
                </Tabs>

                {/* Tab Content */}

                {activeTab === 0 && (
                    <StyledCard>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >

                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    color={themeColor}
                                    gutterBottom
                                >
                                    View Property Tenants
                                </Typography>

                                <Box sx={{display: "flex", gap: 1}}>
                                    <Button variant="outlined"
                                            sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                            onClick={() => {
                                                setBillTenantsModal(true)
                                            }}>
                                        Bill Tenants
                                    </Button>
                                </Box>
                            </Box>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <PropertyTenants tenants={tenants} originalText={originalText}
                                             waterBill={propertyDetails.water_bill}
                                             minimumWaterBill={propertyDetails.minimum_water_bill}
                                             electricityBill={propertyDetails.electricity_bill}
                                             minimumElectricityBill={propertyDetails.minimum_electricity_bill}
                                             propertyDetails={propertyDetails}
                                             viewModal={billTenantsModal}
                            />

                        </CardContent>
                    </StyledCard>
                )}


                {activeTab === 1 && (
                    <StyledCard>
                        <CardContent>

                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={themeColor}
                                gutterBottom
                            >
                                Edit Property Details
                            </Typography>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <FormComponent propertyDetails={propertyDetails} originalDetails={originalText}/>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}
                {activeTab === 2 && (
                    <StyledCard>
                        <CardContent>

                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={themeColor}
                                gutterBottom
                            >
                                Edit Payment Details
                            </Typography>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <PaymentFormComponent propertyDetails={propertyDetails} originalDetails={originalText}/>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}


                {activeTab === 3 && (
                    <StyledCard>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >

                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    color={themeColor}
                                    gutterBottom
                                >
                                    Broadcast A Message
                                </Typography>

                                <Box sx={{display: "flex", gap: 1}}>
                                    <Button variant="outlined"
                                            sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                            onClick={broadCastCredentials}>
                                        Resend Credentials
                                    </Button>
                                </Box>
                            </Box>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <MessagesComponent propertyDetails={propertyDetails} originalText={originalText}/>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}


                {activeTab === 4 && (
                    <StyledCard>
                        <CardContent>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={themeColor}
                                gutterBottom
                            >
                                Tenant Report Generator
                            </Typography>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <TenantReport propertyDetails={propertyDetails}/>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}
            </Grid>
        </>
    );
};

export default RightSide;
