import React, {useEffect, useState} from "react";
import "./topbar.css";
import {NotificationsNone, Settings, Logout} from "@mui/icons-material";
import {
    Avatar,
    Menu,
    MenuItem,
    useMediaQuery,
    Divider,
    ListItemIcon,
    Box,
    Tooltip,
    IconButton,
    Typography,
} from "@mui/material";
import {Link} from "react-router-dom";
import CryptoJS from "crypto-js";
import {ApiDetails, KEY} from "../../dummyData";

export default function Topbar() {
    const [forceRefresh, setForceRefresh] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [landlordImage, setLandlordImage] = useState("");
    const [name, setName] = useState("New User");
    const [anchorEl, setAnchorEl] = useState(null);
    const matches = useMediaQuery("(max-width:700px)");
    const open = Boolean(anchorEl);

    // Helper to extract initials from a name
    const getInitials = (fullName) =>
        fullName
            .split(" ")
            .map((part) => part[0]?.toUpperCase())
            .join("");

    // Fetch user and image details
    const fetchUserData = async (decryptedData) => {
        const userDetails = JSON.parse(decryptedData);
        const [firstName, lastName, email, userType] = [
            userDetails[1][1],
            userDetails[2][1],
            userDetails[5][1],
            userDetails[21][1],
        ];

        setName(`${firstName} ${lastName}`);

        // Fetch landlord image
        try {
            const response = await fetch(`${ApiDetails}pegasus/visionary/authorization/photo`, {
                method: "POST",
                body: JSON.stringify({email_address: email}),
            });
            const data = await response.json();
            if (response.status === 200 && data.landlord_photo?.base64 !== "No File Found") {
                setLandlordImage(`data:image/jpeg;base64,${data.landlord_photo.base64}`);
            }
        } catch (error) {
            console.error("Error fetching landlord image:", error);
        }

        // Fetch user type
        try {
            const response = await fetch(`${ApiDetails}pegasus/visionary/extras/user_types`);
            const data = await response.json();
            if (response.status === 200) {
                const agentTypeId = data.find((type) => type.description === "AGENT")?.id;
                setIsAgent(userType === agentTypeId);
            }
        } catch (error) {
            console.error("Error fetching user type:", error);
            // snackBar({
            //     text: "Something Went Wrong. Try Again Later",
            //     type: "error",
            // });
        }
    };

    // Initial data load
    useEffect(() => {
        try {
            const encryptedData = localStorage.getItem("myMap");
            if (encryptedData) {
                const decryptedData = CryptoJS.AES.decrypt(encryptedData, KEY).toString(CryptoJS.enc.Utf8);
                fetchUserData(decryptedData);
            }
        } catch (error) {
            console.error("Error decrypting user data:", error);
        }
    }, [forceRefresh, matches]);

    // Handlers
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleLogout = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    return {
        setForceRefresh,
        render: (
            <div className="topbar">
                <div className="topbarWrapper">
                    <div className="topLeft">
                        <Typography variant="h6" className="topbarWelcomeText">
                            Welcome, {name.toUpperCase()}
                        </Typography>
                    </div>
                    <div className="topRight">
                        <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                            <Tooltip title="Notifications">
                                <IconButton>
                                    <NotificationsNone
                                        sx={{color: "white", fontSize: 28, "&:hover": {color: "#3799D6"}}}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{"&:hover": {transform: "scale(1.1)", transition: "0.3s"}}}
                                >
                                    {landlordImage ? (
                                        <Avatar src={landlordImage} sx={{width: 45, height: 45}}/>
                                    ) : (
                                        <Avatar
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                bgcolor: "linear-gradient(to right, #ff7e5f, #feb47b)", // Gradient background
                                                color: "#fff", // Text color
                                                fontWeight: "bold", // Bold initials
                                            }}
                                        >
                                            {getInitials(name)}
                                        </Avatar>
                                    )}
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            transformOrigin={{horizontal: "right", vertical: "top"}}
                            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                        >
                            {isAgent && (
                                <MenuItem onClick={handleClose}>
                                    <Link to="/userManagement" className="menuLink">
                                        <ListItemIcon>
                                            <Avatar/>
                                        </ListItemIcon>
                                        User Management
                                    </Link>
                                </MenuItem>
                            )}
                            <MenuItem onClick={handleClose}>
                                <Link to="/settings" className="menuLink">
                                    <ListItemIcon>
                                        <Avatar/>
                                    </ListItemIcon>
                                    My Account
                                </Link>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleClose}>
                                <Link to="/settings" className="menuLink">
                                    <ListItemIcon>
                                        <Settings fontSize="small"/>
                                    </ListItemIcon>
                                    Settings
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>)
    };
}
