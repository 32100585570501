import React, {useState} from "react";
import {
    Box,
} from "@mui/material";
import LoginLeft from "./Left/LoginLeft";
import LoginRight from "./Right/LoginRight";

const LoginPageV2 = (props) => {

    const [showOTP, setShowOTP] = useState(false)
    return (
        <Box
            sx={{
                height: "100vh",
                backgroundColor: "#FBF4F0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // padding: 2,
            }}
        >
            <Box
                sx={{
                    maxWidth: "900px",
                    width: "100%",
                    backgroundColor: "#E3F2FD",
                    display: "flex",
                    borderRadius: "16px",
                    overflow: "hidden",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    zoom: "90%"
                }}
            >
                {/* Left Panel */}
                <LoginLeft otp={showOTP}/>

                <LoginRight
                    refresh={() => props.refresh(true)}
                    fn={() => props.fn()}
                    showOtp={() => {
                        setShowOTP(true)
                    }}/>
            </Box>
        </Box>
    );
};

export default LoginPageV2;
