import React, { useState, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SnackbarService = (() => {
    let snackbarHandler = null;

    const SnackbarComponent = () => {
        const [snackbar, setSnackbar] = useState({
            open: false,
            title: "",
            message: "",
            type: "success", // success | error | warning | info
            position: { vertical: "bottom", horizontal: "right" }, // Default position
        });

        // Expose the function to show snackbar
        snackbarHandler = useCallback(
            ({ title, message, type, position }) => {
                setSnackbar({
                    open: true,
                    title: title || "",
                    message: message || "",
                    type: type || "success",
                    position: position || { vertical: "bottom", horizontal: "right" },
                });
            },
            [setSnackbar]
        );

        const handleClose = (_, reason) => {
            if (reason === "clickaway") return; // Ignore clickaway dismissals
            setSnackbar({ ...snackbar, open: false });
        };

        return (
            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000} // Auto hide after 4 seconds
                onClose={handleClose}
                anchorOrigin={snackbar.position}
            >
                <Alert
                    onClose={handleClose}
                    severity={snackbar.type}
                    sx={{ width: "100%" }}
                >
                    {snackbar.title && <strong>{snackbar.title}</strong>}
                    {snackbar.title && <br />}
                    {snackbar.message}
                </Alert>
            </Snackbar>
        );
    };

    const showSnackbar = (config) => {
        if (snackbarHandler) {
            snackbarHandler(config);
        } else {
            console.error("SnackbarComponent is not mounted.");
        }
    };

    return { SnackbarComponent, showSnackbar };
})();

export const { SnackbarComponent, showSnackbar } = SnackbarService;
