import React, {useState} from "react";
import {
    Box,
    TextareaAutosize,
    IconButton,
    Button,
    Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {ApiDetails} from "../../../../dummyData";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";

export default function MessagesComponent(props) {
    const {propertyDetails, originalText} = props;

    const [broadcastMessage, setBroadcastMessage] = useState("");

    // Handle sending a message
    const sendBroadcastMessage = () => {
        if (broadcastMessage.trim() === "") {
            showSnackbar({
                title: "Error!",
                message: "Cannot send an empty message",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            return;
        }

        let values = {
            message: broadcastMessage,
            property_id: propertyDetails.property_id,
            landlord_id: JSON.parse(originalText)[0][1],
        };

        fetch(ApiDetails + "pegasus/visionary/messages/broadcast", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    showSnackbar({
                        title: "Success!",
                        message: "Message sent successfully.",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                } else if (response.status === 500) {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later..",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "55vh",
                padding: "20px",
                backgroundColor: "#F7F9FC",
                borderRadius: "10px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
        >
            {/* Text Area */}
            <TextareaAutosize
                minRows={15}
                maxRows={30}
                placeholder="Type your message..."
                value={broadcastMessage}
                onChange={(e) => setBroadcastMessage(e.target.value)}
                style={{
                    width: "100%",
                    padding: "15px",
                    border: "1px solid #E0E0E0",
                    borderRadius: "10px",
                    outline: "none",
                    resize: "none",
                    fontSize: "16px",
                    fontFamily: "Arial, sans-serif",
                    marginBottom: "10px",
                }}
            />

            {/* Action Buttons */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                }}
            >
                <Box sx={{display: "flex", gap: 1}}>
                    <Button variant="outlined"
                            sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                            onClick={sendBroadcastMessage}>
                        Send Message
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
