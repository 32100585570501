import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    IconButton,
    Avatar,
    Divider,
    Fade, MenuItem,
} from "@mui/material";
import {styled} from "@mui/system";
import {ApiDetails} from "../../../../dummyData";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";
import Tooltip from "@mui/material/Tooltip";
import {BugReport} from "@material-ui/icons";
import {DeleteOutline} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    "& .MuiDataGrid-columnHeaders": {
        fontSize: "0.9rem",
        fontWeight: 600,
        backgroundColor: "#FBF4F0", // Logo blue
        color: "black", // White text
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "#F4F6F8", // Subtle hover color
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "0.8rem",
        color: "#002961", // Top bar color
    },
}));


const Transactions = (props) => {

    const [transactions, setTransactions] = useState({});

    useEffect(() => {
        fetch(
            ApiDetails +
            "pegasus/visionary/tenant/get/tenant/transactions",
            {
                method: "POST",
                body: JSON.stringify({
                    tenant_id: props.tenantID,
                }),
            }
        )
            .then(async (response) => {
                let transactions = await response.json();
                if (response.status === 200) {
                    setTransactions(transactions);
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Could not fetch transactions.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later.",
                    type: "error", // success | error | warning | info
                    position: {vertical: "bottom", horizontal: "right"}, // Custom position
                });

            });
    }, [])

    const transactionColumns = [
        {
            field: "transaction_id",
            headerName: "ID",
            headerAlign: "center",
            // width: 180,
            minWidth: 120,
            flex: 1,
        },
        {
            field: "amount",
            headerName: "Amount",
            headerAlign: "center",
            // width: 150,
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "date",
            align: "center",
            headerAlign: "center",
            headerName: "Date Paid",
            // width: 200,
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                const dateString = params.row.date;
                const isISO8601Format = dateString.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/);

                let dateObject;
                if (!isISO8601Format) {
                    // Parse the string into individual components
                    const year = dateString.substring(0, 4);
                    const month = dateString.substring(4, 6);
                    const day = dateString.substring(6, 8);
                    const hour = dateString.substring(8, 10);
                    const minute = dateString.substring(10, 12);
                    const second = dateString.substring(12, 14);

                    // Create a Date object
                    // dateObject = new Date(year, month - 1, day, hour, minute, second).toString();
                    dateObject = new Date(year, month - 1, day, hour, minute, second).toString().substring(4, 24);

                } else {
                    const convertToCustomFormat = (isoDateString) => {
                        const dateObject = new Date(isoDateString);
                        const options = {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                            timeZone: 'UTC'
                        };
                        return dateObject.toLocaleString('en-US', options);
                    };
                    dateObject = convertToCustomFormat(dateString);
                }


                return (
                    <div>{dateObject}</div>
                );
            },
        },
        {
            field: "date_created",
            align: "center",
            headerAlign: "center",
            headerName: "Date Updated",
            // width: 200,
            minWidth: 200,
            flex: 1,
        },
    ];

    return (

        <StyledDataGrid
            rows={transactions}
            columns={transactionColumns}
            disableSelectionOnClick
            pageSize={5}
            rowsPerPageOptions={[5, 10]}
            autoHeight
        />
    );
};

export default Transactions;
