import React, { useEffect, useState } from "react";
import "./settings.css";
import { AccountCircleOutlined, LockOutlined } from "@material-ui/icons";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AccountDetails from "./settingsTabs/AccountDetails";
import Security from "./settingsTabs/Security";

export default function Settings(props) {
    const [activePage, setActivePage] = useState("accountDetails");
    const [loading, setLoading] = useState(true);

    // Handle page change
    const handlePageChange = (page) => {
        setLoading(true);
        setActivePage(page);
        setLoading(false);
    };

    // Set the active page component based on the selection
    const renderPage = () => {
        switch (activePage) {
            case "accountDetails":
                return <AccountDetails snackBar={props.snackBar} />;
            case "security":
                return <Security snackBar={props.snackBar} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        props.refreshSideBar();
        setLoading(false); // Disable loading when the component has mounted
    }, []);

    return (
        <div className="settings-container">
            {/* Loading Screen */}
            {loading && (
                <Backdrop open={loading} className="backdrop">
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}

            {/* Sidebar */}
            <div className="sidebar">
                <h2 className="settings-title">Settings</h2>
                <div className="sidebar-items">
                    <div
                        onClick={() => handlePageChange("accountDetails")}
                        className={`sidebar-item ${activePage === "accountDetails" ? "active" : ""}`}
                    >
                        <AccountCircleOutlined className="sidebar-icon" />
                        <span className="sidebar-item-text">Account Details</span>
                    </div>
                    <div
                        onClick={() => handlePageChange("security")}
                        className={`sidebar-item ${activePage === "security" ? "active" : ""}`}
                    >
                        <LockOutlined className="sidebar-icon" />
                        <span className="sidebar-item-text">Security</span>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="content">
                {renderPage()}
            </div>
        </div>
    );
}
