import React from "react";
import "./widgetSm.css";
import CryptoJS from "crypto-js";
import { KEY } from "../../dummyData";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { format } from "date-fns";

const StyledContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders": {
    fontSize: "1rem",
    fontWeight: 600,
    backgroundColor: "#f5f5f5",
    color: theme.palette.primary.main,
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
}));

export default function WidgetSm() {
  // Decrypt data from localStorage
  let decrypted = CryptoJS.AES.decrypt(localStorage.portfolioDetails, KEY);
  let originalText = decrypted.toString(CryptoJS.enc.Utf8);

  let [tenants] = React.useState(
      Array.from(new Map(JSON.parse(originalText)).get("tenants"))
  );

  const [status] = React.useState(localStorage.getItem("isNew"));
  if (status === "no") {
    tenants = Array.from(new Map(JSON.parse(originalText)).get("tenants"));
  }

  // Define columns for DataGrid
  const columns = [
    {
      field: "user",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                  src={params.row.profile_picture || ""}
                  alt={params.row.first_name}
              />
              <Typography>
                {params.row.first_name} {params.row.last_name}
              </Typography>
            </Stack>
        );
      },
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 180,
      align: "center",
    },
    {
      field: "date_created",
      headerName: "Date Added",
      width: 200,
      align: "center",
      valueFormatter: (params) =>
          format(new Date(params.value), "dd MMM yyyy"),
    },
  ];

  return (
      <StyledContainer>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600, color: "primary.dark" }}>
          New Tenants
        </Typography>
        <Box sx={{ height: 400, backgroundColor: "#fff" }}>
          <StyledDataGrid
              rows={tenants}
              columns={columns}
              disableSelectionOnClick
              pageSize={5}
              rowsPerPageOptions={[5, 10]}
          />
        </Box>
      </StyledContainer>
  );
}
