import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    IconButton, FormControl, Select, MenuItem, FormHelperText, useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ApiDetails, KEY} from "../../../dummyData";
import CryptoJS from "crypto-js";
import LeftSide from "./Left Side/LeftSide";
import RightSide from "./Right Side/RightSide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {InputLabel, TextField} from "@material-ui/core";
import Switch from "@mui/material/Switch";
import {DeleteOutline} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {showSnackbar} from "../../../components/SnackBar/SnackBar";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";
import useGoBack from "../../../components/useGoBack";

const Transition = React.forwardRef(function Transition(props: TransitionProps & {
    children: React.ReactElement<any, any>,
}, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PropertyDetails = (props) => {

    const matches = useMediaQuery("(max-width:700px)");

    let myMapDetailsDecrypted = "";

    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);

    } catch (e) {
        console.log(e);
    }

    const originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);


    const [tenants, setTenants] = useState([])
    const [property, setProperty] = useState({
            agency_id: "",
            apply_penalty: "",
            consumer_secret: "",
            electricity_bill: "",
            expected_income: "",
            ho_number: "",
            income_received: "",
            landlord_id: "",
            location: "",
            minimum_days_to_vacate: "",
            minimum_electricity_bill: "",
            minimum_water_bill: "",
            occupancy: "",
            other_charges: "",
            pass_key: "",
            payment_details: "",
            penalty_date: "",
            penalty_percentage: "",
            property_id: "",
            property_name: "",
            query_number: "",
            reminder_date: "",
            short_code: "",
            till_number: "",
            total_property_units: "",
            transaction_type: "",
            water_bill: "",
        }
    );


    let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.portfolioDetails, KEY);
    portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const [propertyImage, setPropertyImage] = useState(null);
    const [billTenantsModal, setBillTenantsModal] = useState(false);


    useEffect(() => {
        fetch(ApiDetails + "pegasus/visionary/property/get/Specific/property", {
            method: "POST", body: JSON.stringify({
                property_id: props.property_id, landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setProperty(data);
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Failed fetching property details.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Failed fetching property details.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });

            });

        getPropertyPhoto()
    }, []);


    // Fetch tenants
    useEffect(() => {

        console.log("property id")
        console.log(props.property_id)


        fetch(ApiDetails + "pegasus/visionary/tenant/get/property/tenants", {
            method: "POST",
            body: JSON.stringify({property_id: props.property_id}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setTenants(data); // ✅ This ensures tenants update correctly
                }
            })
            .catch((err) => console.log(err));
    }, [props.property_id]);

    const getPropertyPhoto = () => {
        fetch(ApiDetails + "pegasus/visionary/property/get/Specific/property/photo", {
            method: "POST", body: JSON.stringify({
                property_id: props.property_id,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    let pic = data.property_image;

                    if (pic.base64 !== "No File Found") {
                        setPropertyImage("data:image/jpeg;base64," + pic.base64);
                    }
                    // console.log(pic);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const [addTenantModal, setAddTenantModal] = useState(false);

    const initialValues2 = {
        first_name: "",
        landlord_id: JSON.parse(originalText)[0][1],
        occupation_or_profession: "",
        last_name: "",
        phone_number: "",
        secondary_phone_number: "",
        national_id: "",
        next_of_kin_relationship: "",
        next_of_kin_first_name: "",
        next_of_kin_last_name: "",
        next_of_kin_national_id: "",
        next_of_kin_contacts: "",
        institution_name: "",
        place_of_work: "",
        reason_for_relocating: "",
        previous_residence: "",
        email_address: "",
        rent: "",
        deposit_held: "",
    };

    //Units State
    const [allUnits, setAllUnits] = useState([]);
    const [units, setUnits] = React.useState(null);
    const [selectedUnit, setSelectedUnit] = React.useState(null);

    const [isStudent, setIsStudent] = React.useState("NO");
    const textRef = useRef();


    const [inputs, setInputs] = useState();

    const [refundableDeposit, setRefundableDeposit] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);

    const [depositSet, setDepositSet] = useState(false);

    //Tenant Photo
    const [tenantPhoto, setTenantPhoto] = useState(null);
    const [photo, setPhoto] = useState(null);

    //Tenant National ID
    const [photoOfNationalID, setPhotoOfNationalID] = useState(null);
    const [nationalID, setNationalID] = useState(null);

    //Tenant Contract
    const [copyOfContract, setCopyOfContract] = useState(null);
    const [contract, setContract] = useState(null);

    //Tenant Checklist
    const [inventoryChecklist, setInventoryChecklist] = useState(null);
    const [checklist, setChecklist] = useState(null);

    const [tenantLoading, setTenantLoading] = React.useState(false);

    const [depositAsArrears, setDepositAsArrears] = React.useState(true);
    const [rentAsArrears, setRentAsArrears] = React.useState(true);

    const [expensesIncurred, setExpensesIncurred] = useState(1);
    let [depositList, setDepositList] = useState([new Map()]);
    const [expenseItem, setExpenseItem] = useState(new Map());

    const [addTenantPropertyName, setAddTenantPropertyName] = React.useState("Select Property");
    let [propertyID, setPropertyID] = React.useState(null);

    const [depositModal, setDepositModal] = useState(false);

    const [loading1, setLoading1] = React.useState(false);


    function handleFile1(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            setPhoto(filename);
            setTenantPhoto(e.target.files[0]);
        } else {
            showSnackbar({
                title: "Error!",
                message: "Please upload an image file.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
        }
    }

    function handleFile2(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            setNationalID(filename);
            setPhotoOfNationalID(e.target.files[0]);
        } else {
            showSnackbar({
                title: "Error!",
                message: "Please upload an image file.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
        }
    }

    function handleFile3(e) {
        let filename = getFileName(e);
        if (checkTextFile(filename)) {
            setContract(filename);
            setCopyOfContract(e.target.files[0]);
        } else {
            showSnackbar({
                title: "Error!",
                message: "Please upload an text, doc, docx file.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
        }
    }

    function handleFile4(e) {
        let filename = getFileName(e);
        if (checkTextFile(filename)) {
            setChecklist(filename);
            setInventoryChecklist(e.target.files[0]);
        } else {
            showSnackbar({
                title: "Error!",
                message: "Please upload an image file.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
        }
    }

    const getFileName = (e) => {
        let startIndex = e.target.value.indexOf("\\") >= 0 ? e.target.value.lastIndexOf("\\") : e.target.value.lastIndexOf("/");
        let filename = e.target.value.substring(startIndex);
        if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
            filename = filename.substring(1);
        }
        return filename;
    };

    function getExtension(filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
    }

    function checkImageFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "jpg":
            case "jpeg":
            case "png":
            case "jepg":
                //etc
                return true;
        }
        return false;
    }

    function checkTextFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "txt":
            case "doc":
            case "docx":
            case "pdf":
                //etc
                return true;
        }
        return false;
    }

    const calculateRefundableDeposit = () => {

        try {
            expenseItem.forEach((value, key) => {
                let array = key.split(" ");
                let uniqueString = array[array.length - 1];

                if (expenseItem.has("refundable " + uniqueString)) {
                    let refundableValue = expenseItem.get("refundable " + uniqueString);

                    if (!(refundableValue.toString().trim().toUpperCase() === "YES" || refundableValue.toString().trim().toUpperCase() === "NO")) {
                        throw new Error("Fill in either YES OR NO for refundable")
                    }

                } else {
                    throw new Error("Fill in if refundable for all entries")
                }
            });
        } catch (e) {
            // console.log(e)
            showSnackbar({
                title: "Error!",
                message: e.message,
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            return;
        }

        let depositAmount = 0;

        expenseItem.forEach((value, key) => {
            if (key.toString().includes("value")) {
                depositAmount += parseFloat(value);
            }
        });

        setTotalDeposit(depositAmount)

        expenseItem.set("Total deposit", depositAmount)

        let refundableDepositAmount = 0;

        let handledKeys = [];

        expenseItem.forEach((value, key) => {
            let array = key.split(" ");
            let uniqueString = array[array.length - 1];


            if (handledKeys.includes(uniqueString)) {
                return
            }

            if (expenseItem.has("refundable " + uniqueString)) {
                let refundableValue = expenseItem.get("refundable " + uniqueString);

                if (refundableValue.toString().toUpperCase().trim() === "YES") {
                    refundableDepositAmount += parseFloat(expenseItem.get("value for description " + uniqueString))
                }

            }
            handledKeys.push(uniqueString)

        });

        setRefundableDeposit(refundableDepositAmount)
        expenseItem.set("Refundable deposit", refundableDepositAmount)

        setDepositList(new Map([...expenseItem]))

        expenseItem.delete("Refundable deposit")
        expenseItem.delete("Total deposit")

        setDepositSet(true)
    };

    const handleChange = (props) => (event) => {
        // kkk;
        expenseItem.set(event.target.name, event.target.value);
        setDepositSet(false)

        // setDepositList(depositMap)
    };

    const handleClose = () => {
        setDepositModal(false);
        setAddTenantModal(false);
    };


    const getUnits = () => {
        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/property/getUnits", {
            method: "POST", body: JSON.stringify({property_id: propertyID}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setUnits(data);
                    showSnackbar({
                        title: "Success!",
                        message: "Units retrieved successfully.",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });

                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Please try again later.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Pleast try again later",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });

            });
    };

    const addTenant = (values) => {

        const replacer = (key, value) => {
            if (value instanceof Map) {
                return {
                    dataType: 'Map', value: Array.from(value.entries()), // or with spread: value: [...value]
                };
            } else {
                return value;
            }
        }

        setTenantLoading(true);
        if (values.first_name === "" || values.last_name === "") {
            setTenantLoading(false);
            showSnackbar({
                title: "Error!",
                message: "First and last names are required",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            return;
        }
        if (values.phone_number === "" || values.phone_number.length < 10) {
            setTenantLoading(false);
            showSnackbar({
                title: "Error!",
                message: "Enter a valid phone number",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            return;
        }

        if (selectedUnit === null) {
            setTenantLoading(false);
            showSnackbar({
                title: "Error!",
                message: "Kindly select unit",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            return;
        }

        if (values.rent === "") {
            setTenantLoading(false);
            showSnackbar({
                title: "Error!",
                message: "Rent can not be empty",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            return;
        }

        values.rent_as_arrear = rentAsArrears;
        values.deposit_as_arrears = depositAsArrears;
        values.is_student = isStudent;
        values.is_student = isStudent;
        values.landlord_email_address = JSON.parse(originalText)[5][1];
        values.landlord_id = JSON.parse(originalText)[0][1];
        values.property_name = property.property_name;
        values.property_id = property.property_id;
        values.house_number = selectedUnit;
        values.deposit = Array.from(depositList.entries());
        values.deposit_held = (depositList.get("Refundable deposit"));

        let formData = new FormData();
        //Add values to formData
        //formData.append("details", [detailsMap]);
        formData.append("body", JSON.stringify(values));
        formData.append("photo_of_national_id", photoOfNationalID);
        formData.append("copy_of_contract", copyOfContract);
        formData.append("inventory_checklist", inventoryChecklist);
        formData.append("tenant_photo", tenantPhoto);

        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/add/tenant", {
            method: "POST", body: formData,
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setRentAsArrears(true);
                    setDepositAsArrears(true);
                    showSnackbar({
                        title: "Success!",
                        message: "Tenant added successfully.",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });

                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
                setTenantLoading(false);
            })
            .catch((err) => {
                setTenantLoading(false);
                console.log(err)
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });
    };

    const validationSchema = Yup.object().shape({
        // Tenant Details
        first_name: Yup.string()
            .matches(/^[A-Za-z]+$/, "First name should contain only letters")
            .min(2, "First name must be at least 2 characters")
            .max(50, "First name cannot exceed 50 characters")
            .required("First name is required"),

        last_name: Yup.string()
            .matches(/^[A-Za-z]+$/, "Last name should contain only letters")
            .min(2, "Last name must be at least 2 characters")
            .max(50, "Last name cannot exceed 50 characters")
            .required("Last name is required"),

        phone_number: Yup.string()
            .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
            .required("Phone number is required"),

        secondary_phone_number: Yup.string()
            .matches(/^\d{10}$/, "Secondary phone number must be exactly 10 digits")
            .notRequired(),

        national_id: Yup.string()
            .matches(/^\d+$/, "National ID should contain only numbers")
            .min(6, "National ID must be at least 6 digits")
            .max(12, "National ID cannot exceed 12 digits")
            .required("National ID is required"),

        // Property Details
        property_name: Yup.string()
            .required("Property selection is required"),

        house_number: Yup.string()
            .required("House number is required"),

        rent: Yup.number()
            .min(1, "Rent amount must be greater than zero")
            .required("Rent is required"),

    });

    const goBack = useGoBack();

    return (
        <>

            <Dialog
                open={depositModal}
                fullWidth
                maxWidth="sm"
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    <div
                        style={{
                            display: "flex", justifyContent: "center", alignItems: "center",
                        }}
                    >
                        Add deposit
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: "flex", justifyContent: "space-between",
                            }}
                        >
                            <Typography>Deposit details</Typography>
                            <Button
                                onClick={() => {
                                    setExpensesIncurred(expensesIncurred + 1);
                                    setInputs([...Array(expensesIncurred)].map((elementInArray, index) => (
                                        <div key={index}>
                                            <br/>
                                            <div
                                                style={{
                                                    display: "flex", justifyContent: "space-between",
                                                }}
                                            >
                                                <TextField
                                                    label="Description"
                                                    type={"text"}
                                                    variant="outlined"
                                                    name={"description " + (index + 1)}
                                                    required
                                                    inputRef={textRef}
                                                    style={{marginTop: "10px", width: "35%"}}
                                                    onChange={handleChange()}
                                                />
                                                <TextField
                                                    label="Amount"
                                                    type={"number"}
                                                    name={"value for description " + (index + 1)}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "35%"}}
                                                    onChange={handleChange()}
                                                />

                                                <TextField
                                                    label="refundable"
                                                    type={"text"}
                                                    name={"refundable " + (index + 1)}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "20%"}}
                                                    onChange={handleChange()}
                                                />

                                            </div>
                                        </div>)));
                                }}
                            >
                                <span style={{color: "green"}}>Add Deposit</span>
                            </Button>
                        </div>
                        <div>{inputs}</div>
                        <br/>
                        <div
                            style={{
                                width: "100%", display: "flex", justifyContent: "right", alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    calculateRefundableDeposit();
                                    // console.log("Adding deposit")
                                }}
                                variant="outlined"
                            >
                                Calculate
                            </Button>
                        </div>

                        <br/>
                        <div
                            style={{
                                width: "100%", display: "flex", justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green", flex: "2",
                                }}
                            >
                                Total deposit :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "right",
                                    fontSize: "30sp",
                                    fontWeight: "bold"
                                }}
                            >
                                {totalDeposit.toLocaleString("en-US", {
                                    style: "currency", currency: "KSH",
                                })}
                            </div>
                        </div>

                        <div
                            style={{
                                marginTop: "10px", width: "100%", display: "flex", justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green", flex: "2",
                                }}
                            >
                                Refundable deposit :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "right",
                                    fontSize: "30sp",
                                    fontWeight: "bold"
                                }}
                            >
                                {refundableDeposit.toLocaleString("en-US", {
                                    style: "currency", currency: "KSH",
                                })}
                            </div>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Button
                        onClick={() => {
                            // window.location.reload(false);

                            handleClose();
                            setAddTenantModal(true)
                        }}
                    >
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>

                    <div>
                        {/*<Button*/}
                        {/*    style={{*/}
                        {/*        marginRight: "10px"*/}
                        {/*    }}*/}
                        {/*    onClick={() => {*/}
                        {/*        // Handle form reset;*/}
                        {/*        setExpensesIncurred(1);*/}
                        {/*        setInputs();*/}
                        {/*        setExpenseItem(new Map())*/}
                        {/*        setDepositList(new Map())*/}
                        {/*        setTotalDeposit(0)*/}
                        {/*        setRefundableDeposit(0)*/}
                        {/*        setDepositSet(false);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <RestartAlt/>*/}

                        {/*    <span style={{color: "blue", marginLeft: "5px"}}>Reset</span>*/}
                        {/*</Button>*/}
                        <LoadingButton
                            size="small"
                            onClick={() => {
                                // console.log("Adding expenses")
                                handleClose();
                                setAddTenantModal(true)
                            }}
                            disabled={!depositSet}
                            loading={loading1}
                            startIcon={<SendIcon/>}
                            style={{color: "green"}}
                            loadingPosition="start"
                        >
                            Agree
                        </LoadingButton>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth={"md"}
                open={addTenantModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Add Tenant
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px", zoom: matches ? "65%" : "100%",
                    }}
                >
                    <Formik
                        initialValues={initialValues2}
                        // validationSchema={validationSchema}
                        onSubmit={addTenant}
                    >
                        {(props) => (
                            <Form noValidate>
                                <Grid container spacing={2}>
                                    {/* First Name & Last Name */}
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            as={TextField}
                                            name="first_name"
                                            label="First Name"
                                            variant="outlined"
                                            error={props.errors.first_name && props.touched.first_name}
                                            helperText={<ErrorMessage name="first_name"/>}
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            as={TextField}
                                            name="last_name"
                                            label="Last Name"
                                            variant="outlined"
                                            error={props.errors.last_name && props.touched.last_name}
                                            helperText={<ErrorMessage name="last_name"/>}
                                            required
                                            fullWidth
                                        />
                                    </Grid>

                                    {/* Phone Numbers & National ID */}
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            as={TextField}
                                            name="phone_number"
                                            label="Phone Number"
                                            variant="outlined"
                                            error={props.errors.phone_number && props.touched.phone_number}
                                            helperText={<ErrorMessage name="phone_number"/>}
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            as={TextField}
                                            name="secondary_phone_number"
                                            label="Secondary Number"
                                            variant="outlined"
                                            error={props.errors.secondary_phone_number && props.touched.secondary_phone_number}
                                            helperText={<ErrorMessage name="secondary_phone_number"/>}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            as={TextField}
                                            name="national_id"
                                            label="National ID"
                                            type="number"
                                            variant="outlined"
                                            error={props.errors.national_id && props.touched.national_id}
                                            helperText={<ErrorMessage name="national_id"/>}
                                            required
                                            fullWidth
                                        />
                                    </Grid>

                                    {/* Property Selection */}
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel id="property-label">Select Property</InputLabel>
                                            <Select
                                                labelId="property-label"
                                                name="property_name"
                                                value={addTenantPropertyName}
                                                onChange={(e) => {
                                                    setAddTenantPropertyName(e.target.value);
                                                    propertyID = e.target.value;
                                                    setPropertyID(e.target.value);
                                                    getUnits();
                                                }}
                                                label="Property *"
                                            >
                                                <MenuItem
                                                    value={property.property_id}>{property.property_name}</MenuItem>
                                            </Select>
                                            <FormHelperText>Required</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    {/* House Number Selection */}
                                    <Grid item xs={12} sm={6}>
                                        {units !== null ? (
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel id="house-label">Select House NO</InputLabel>
                                                <Select
                                                    labelId="house-label"
                                                    name="house_number"
                                                    value={selectedUnit}
                                                    onChange={(e) => setSelectedUnit(e.target.value)}
                                                    label="House NO"
                                                >
                                                    {units.map((unit, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={unit.unit}
                                                            disabled={unit.state === "OCCUPIED"}
                                                        >
                                                            <span>{unit.unit}</span>
                                                            <small
                                                                style={{color: unit.state === "OCCUPIED" ? "red" : "green"}}>
                                                                {unit.state.toLowerCase()}
                                                            </small>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>Required</FormHelperText>
                                            </FormControl>
                                        ) : (
                                            <FormControl fullWidth variant="outlined" required disabled>
                                                <InputLabel id="house-label">Select House NO</InputLabel>
                                                <Select labelId="house-label" name="house_number" value={selectedUnit}>
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>Required</FormHelperText>
                                            </FormControl>
                                        )}
                                    </Grid>

                                    {/* Rent & Add Deposit */}
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            as={TextField}
                                            name="rent"
                                            label="Rent"
                                            type="number"
                                            variant="outlined"
                                            required
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button fullWidth onClick={() => {
                                            setAddTenantModal(false);
                                            setDepositModal(true);
                                        }}>
                                            <span style={{color: "green"}}>Add deposit</span>
                                        </Button>
                                    </Grid>

                                    {/* Switches for Deposits & Arrears */}
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <span>Add Deposit As Arrear</span>
                                                    <Switch
                                                        checked={depositAsArrears}
                                                        onChange={() => setDepositAsArrears(!depositAsArrears)}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <span>Add Rent As Arrear</span>
                                                    <Switch
                                                        checked={rentAsArrears}
                                                        onChange={() => setRentAsArrears(!rentAsArrears)}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Next of Kin Details */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" align="center">
                                            NEXT OF KIN DETAILS
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            as={TextField}
                                            name="next_of_kin_first_name"
                                            label="First Name"
                                            variant="outlined"
                                            error={props.errors.next_of_kin_first_name && props.touched.next_of_kin_first_name}
                                            helperText={<ErrorMessage name="next_of_kin_first_name"/>}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            as={TextField}
                                            name="next_of_kin_last_name"
                                            label="Last Name"
                                            variant="outlined"
                                            error={props.errors.next_of_kin_last_name && props.touched.next_of_kin_last_name}
                                            helperText={<ErrorMessage name="next_of_kin_last_name"/>}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Field
                                            as={TextField}
                                            name="next_of_kin_relationship"
                                            label="Relationship"
                                            variant="outlined"
                                            error={props.errors.next_of_kin_relationship && props.touched.next_of_kin_relationship}
                                            helperText={<ErrorMessage name="next_of_kin_relationship"/>}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            as={TextField}
                                            name="next_of_kin_contacts"
                                            label="Phone Number"
                                            variant="outlined"
                                            error={props.errors.next_of_kin_contacts && props.touched.next_of_kin_contacts}
                                            helperText={<ErrorMessage name="next_of_kin_contacts"/>}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            as={TextField}
                                            name="next_of_kin_national_id"
                                            label="National ID"
                                            type="number"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    {/* Buttons */}
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <Button onClick={handleClose} color="secondary">
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            type="submit"
                                            size="small"
                                            loading={tenantLoading}
                                            startIcon={<SendIcon/>}
                                            style={{color: "green"}}
                                            loadingPosition="start"
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Box p={1} bgcolor="#F7F9FA">


                {/* Header Section */}
                <Box
                    sx={{
                        position: "sticky", // or "fixed" if you want it to always stay at the top
                        top: 50, // Ensures it sticks to the top of the viewport
                        zIndex: 1000, // Ensures it stays on top of other elements
                        backgroundColor: "#F5F5F5",
                        borderBottom: "2px solid #2C5283",
                        padding: "10px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {/* Left Section: Back button and Title */}
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <IconButton
                            sx={{color: "#2C5283", marginRight: 1, backgroundColor: "#F4F6F8"}}
                            onClick={goBack}
                        >
                            <ArrowBackIcon/>
                        </IconButton>
                        <Typography variant="h6" sx={{fontWeight: "bold", color: "#2C5283"}}>
                            View Property
                            - {property.property_name.toUpperCase()}
                        </Typography>
                    </Box>

                    {/* Right Section: Action Buttons */}
                    <Box sx={{display: "flex", gap: 1}}>
                        <Button variant="outlined" sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                onClick={() => setAddTenantModal(true)}>
                            Add Tenant
                        </Button>
                        <Button variant="outlined" sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                onClick={() => {
                                    window.location.reload();
                                }}>
                            Refresh Details
                        </Button>
                    </Box>
                </Box>

                {/* Main Layout */}
                <Grid container spacing={3}>

                    <LeftSide propertyDetails={property} propertyImage={propertyImage}/>

                    {/*    /!* Right Section: Tabs and Content *!/*/}
                    <RightSide propertyDetails={property} tenants={tenants} setBillTenantsModal={setBillTenantsModal}
                               billTenantsModal={billTenantsModal}/>

                </Grid>
            </Box>
        </>
    );
};

export default PropertyDetails;
