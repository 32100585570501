import React, {useEffect, useState} from "react";
import {
    Box,
    Grid,
    Typography,
    Avatar,
    Divider,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import {ApiDetails, KEY} from "../../../../dummyData";
import Chip from "@mui/material/Chip";
import CryptoJS from "crypto-js";
import imageAvatar from "../../../../assets/Tenant_avatar.jpg";

const LeftSide = (props) => {

    const [tenantData, setTenantData] = useState({
        first_name: "n/a",
        last_name: "n/a",
        phone_number: "",
        secondary_phone_number: "",
        email_address: "",
        account_number: "",
        house_number: "",
        Wallet: 0,
        status: "",
        state: ""
    });

    useEffect(() => {
        // Fetch the tenant data
        fetch(ApiDetails + "pegasus/visionary/tenant/get/specific/tenant", {
            method: "POST",
            body: JSON.stringify({tenant_id: props.tenantID}),
            headers: {"Content-Type": "application/json"},
        })
            .then((response) => response.json())
            .then((data) => {
                setTenantData(data);
            })
            .catch((err) => console.error(err));

    }, []);

    useEffect(() => {
        // Fetch the image from the server
        fetch(
            ApiDetails +
            "pegasus/visionary/tenant/fetch/tenant/files",
            {
                method: "POST",
                body: JSON.stringify({
                    tenant_id: props.tenantID,
                }),
            }
        )
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    let pic;
                    pic = data.tenant_photo;

                    if (
                        pic.base64 !== "No File Found" &&
                        pic.base64.toString().trim() !== ""
                    ) {
                        // console.log("TRUE")
                        setImageSrc("data:image/jpeg;base64," + pic.base64);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const [imageSrc, setImageSrc] = useState("");

    return (
        <Box
            sx={{
                margin: "20px",
                marginTop: "40px",
                marginLeft: "40px",
                padding: "20px",
                maxWidth: "300px",
                borderRadius: "8px",
                border: "1px solid #E0E0E0",
                backgroundColor: "#F9FAFB",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
            }}
        >
            {/* Customer Header */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                <Avatar
                    sx={{
                        width: 80,
                        height: 80,
                        backgroundColor: "#E0E0E0",
                        marginBottom: "10px",
                    }}
                    src={imageSrc === "" ? imageAvatar : imageSrc}
                />


                <Typography variant="subtitle2" color="textSecondary">
                    Acc: {tenantData.account_number}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{fontWeight: "bold", color: "#2C5283"}}
                >
                    {tenantData.first_name.toUpperCase()} {tenantData.last_name.toUpperCase()}
                </Typography>
                {tenantData.email_address && (
                    <Box sx={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <EmailIcon fontSize="small" sx={{color: "#2C5283"}}/>
                        <Typography variant="body2" color="textSecondary">
                            {tenantData.email_address}
                        </Typography>
                    </Box>
                )}
                <Box sx={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <PhoneIcon fontSize="small" sx={{color: "#2C5283"}}/>
                    <Typography variant="body2" color="textSecondary">
                        {tenantData.phone_number}
                    </Typography>
                </Box>
            </Box>

            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

            {/* Details Section */}
            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", marginBottom: "10px"}}>
                    Identification Details
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Names:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {tenantData.first_name.toUpperCase()} {tenantData.last_name.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            National ID:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {tenantData.national_id}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", marginBottom: "10px"}}>
                    Contact Information
                </Typography>
                <Grid container spacing={1}>
                    {tenantData.email_address && <><Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Email:
                        </Typography>
                    </Grid>
                        <Grid item xs={6} sx={{textAlign: "right"}}>
                            <Typography variant="body2" sx={{fontWeight: "bold"}}>
                                {tenantData.email_address}
                            </Typography>
                        </Grid></>
                    }

                    {tenantData.phone_number && <><Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Phone Number:
                        </Typography>
                    </Grid>
                        <Grid item xs={6} sx={{textAlign: "right"}}>
                            <Typography variant="body2" sx={{fontWeight: "bold"}}>
                                {tenantData.phone_number}
                            </Typography>
                        </Grid></>
                    }

                    {tenantData.secondary_phone_number && <>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{color: "#4A5568"}}>
                                Secondary Phone No.:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: "right"}}>
                            <Typography variant="body2" sx={{fontWeight: "bold"}}>
                                {tenantData.secondary_phone_number}
                            </Typography>
                        </Grid></>
                    }

                </Grid>
            </Box>


            <Divider sx={{backgroundColor: "#2C5283"}}/>

            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", marginBottom: "10px"}}>
                    Account Details
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Wallet Balance:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{
                            fontWeight: "bold",
                            color: tenantData.status === "COMPLETED" ? "green" : "red"
                        }}>
                            {parseInt(
                                tenantData.Wallet.toString().replace(",", "")
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "KSH",
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Payment Status:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Chip
                            label={tenantData.status}
                            color={tenantData.status === "COMPLETED" ? "success" : "error"}
                            variant="outlined"
                            sx={{fontWeight: "bold"}}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{backgroundColor: "#2C5283"}}/>

            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", marginBottom: "10px"}}>
                    Status Details
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Account Status:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Chip
                            label={tenantData.state}
                            color={tenantData.state === "ACTIVE" ? "success" : "error"}
                            variant="outlined"
                            sx={{fontWeight: "bold"}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Date Created:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {tenantData.date_created}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>);
};

export default LeftSide;
