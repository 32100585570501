import React, {useState, useEffect} from "react";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CryptoJS from "crypto-js";
import {KEY} from "../dummyData";

const ProgressBars = () => {
    // Safe decryption function
    const safeDecrypt = (key) => {
        try {
            return CryptoJS.AES.decrypt(localStorage.getItem(key), KEY).toString(CryptoJS.enc.Utf8) || "";
        } catch {
            return "";
        }
    };

    // Retrieve decrypted data from localStorage
    const [originalText2, setOriginalText2] = useState(safeDecrypt("portfolioDetails"));
    const parsedData = JSON.parse(originalText2);

    // Assuming parsedData is the structure that you shared earlier
    const properties = parsedData[1][1];
    const expectedIncomeMap = parsedData[8][1];
    const receivedIncomeMap = parsedData[9][1];
    const taxMap = parsedData[16][1];
    const depositHeldMap = parsedData[17][1];
    const expenseCostMap = parsedData[19][1];
    const occupancyMap = parsedData[20][1];
    const occupiedUnitsMap = parsedData[21][1];
    const vacantUnitsMap = parsedData[22][1];

    // Set the first property as the default selection
    const [selectedProperty, setSelectedProperty] = useState(properties[0]);
    const [progress, setProgress] = useState({
        incomePercentage: 0,
        occupancyPercentage: 0,
        profitPercentage: 0,
    });

    // Handle property change in dropdown
    const handlePropertyChange = (event) => {
        const propertyId = parseInt(event.target.value, 10);
        const property = properties.find((prop) => prop.id === propertyId);
        setSelectedProperty(property);
    };

    // Calculate progress bars when selected property changes
    useEffect(() => {

        const propertyId = selectedProperty.id;
        const expectedIncome = expectedIncomeMap[propertyId] || 0;
        const occupancy = occupancyMap[propertyId] || 0;
        const receivedIncome = receivedIncomeMap[propertyId] || 0;
        const expenseCost = expenseCostMap[propertyId] || 0;

        // Calculate percentages and handle NaN cases
        const incomePercentage = isNaN((receivedIncome / expectedIncome) * 100) ? 0 : Math.round((receivedIncome / expectedIncome) * 100);
        const occupancyPercentage = isNaN(occupancy) ? 0 : (occupancy);
        const profit = receivedIncome - expenseCost;
        const profitPercentage = isNaN((profit / receivedIncome) * 100) ? 0 : Math.round((profit / receivedIncome) * 100);

        // Progressive loading animation
        setProgress({incomePercentage: 0, occupancyPercentage: 0, profitPercentage: 0});

        const timer1 = setTimeout(() => {
            setProgress((prev) => ({...prev, incomePercentage}));
        }, 200);

        const timer2 = setTimeout(() => {
            setProgress((prev) => ({...prev, occupancyPercentage}));
        }, 400);

        const timer3 = setTimeout(() => {
            setProgress((prev) => ({...prev, profitPercentage}));
        }, 600);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
        };
    }, [selectedProperty]);

    // Use selected property ID to filter the maps

    const propertyId = selectedProperty.id;
    const vacant_property_units = vacantUnitsMap[propertyId] || 0;
    const occupied_property_units = occupiedUnitsMap[propertyId] || 0;
    const total_property_units = vacant_property_units + occupied_property_units;
    const expectedIncome = expectedIncomeMap[propertyId] || 0;
    const receivedIncome = receivedIncomeMap[propertyId] || 0;
    const tax = taxMap[propertyId] || 0;
    const depositHeld = depositHeldMap[propertyId] || 0;
    const expenseCost = expenseCostMap[propertyId] || 0;
    const profit = receivedIncome - expenseCost;

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "2rem",
                backgroundColor: "#f3f7fa",
                borderRadius: "5px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                margin: "10px",
            }}
        >
            {/* Main Progress Bars Section */}
            <div style={{flex: 2}}>
                {/* Dropdown for switching properties */}
                <div style={{marginBottom: "1.5rem", textAlign: "center"}}>
                    <label
                        htmlFor="propertySelect"
                        style={{
                            marginRight: "0.5rem",
                            fontSize: "16px",
                            color: "#002961",
                        }}
                    >
                        Select Property:
                    </label>
                    <select
                        id="propertySelect"
                        value={selectedProperty.id}
                        onChange={handlePropertyChange}
                        style={{
                            padding: "0.5rem",
                            fontSize: "16px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                        }}
                    >
                        {properties.map((property) => (
                            <option key={property.id} value={property.id}>
                                {property.property_name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Statistics Circles */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        gap: "2rem",
                    }}
                >
                    {/* Collected Circle */}
                    <div style={{textAlign: "center"}}>
                        <div style={{width: 150, height: 150}}>
                            <CircularProgressbar
                                value={progress.incomePercentage.toFixed(2)}
                                text={`${progress.incomePercentage.toFixed(2)}%`}
                                styles={buildStyles({
                                    pathColor: progress.incomePercentage >= 50 ? "#38B973" : "orange",
                                    textColor: "#002961",
                                    trailColor: "#d6d6d6",
                                    textSize: "18px",
                                })}
                            />
                        </div>
                        <p
                            style={{
                                marginTop: "0.5rem",
                                fontSize: "14px",
                                color: "#333",
                            }}
                        >
                            Collected: KSH {receivedIncome} / {expectedIncome}
                        </p>
                    </div>

                    {/* Occupancy Circle */}
                    <div style={{textAlign: "center"}}>
                        <div style={{width: 150, height: 150}}>
                            <CircularProgressbar
                                value={progress.occupancyPercentage.toFixed(2)}
                                text={`${progress.occupancyPercentage.toFixed(2)}%`}
                                styles={buildStyles({
                                    pathColor: "#3799D6",
                                    textColor: "#002961",
                                    trailColor: "#d6d6d6",
                                    textSize: "14px",
                                })}
                            />
                        </div>
                        <p
                            style={{
                                marginTop: "0.5rem",
                                fontSize: "14px",
                                color: "#333",
                            }}
                        >
                            Occupancy: {progress.occupancyPercentage}%
                        </p>
                    </div>

                    {/* Profit Circle */}
                    {/*<div style={{textAlign: "center"}}>*/}
                    {/*    <div style={{width: 150, height: 150}}>*/}
                    {/*        <CircularProgressbar*/}
                    {/*            value={Math.abs(progress.profitPercentage)}*/}
                    {/*            text={`${progress.profitPercentage >= 0 ? progress.profitPercentage : `Loss ${Math.abs(progress.profitPercentage)}`}%`}*/}
                    {/*            styles={buildStyles({*/}
                    {/*                pathColor: progress.profitPercentage >= 0 ? "#38B973" : "#D82828",*/}
                    {/*                textColor: "#002961",*/}
                    {/*                trailColor: "#d6d6d6",*/}
                    {/*                textSize: "14px",*/}
                    {/*            })}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <p*/}
                    {/*        style={{*/}
                    {/*            marginTop: "0.5rem",*/}
                    {/*            fontSize: "14px",*/}
                    {/*            color: "#333",*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Profit: KSH {profit >= 0 ? profit : `Loss ${Math.abs(profit)}`} / {receivedIncome}*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                </div>
            </div>

            {/* Property Details Section */}
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    backgroundColor: "#f9fafb",
                    padding: "1.5rem",
                    borderRadius: "10px",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #e0e0e0",
                }}
            >
                <h3
                    style={{
                        color: "#002961",
                        marginBottom: "1rem",
                        fontSize: "20px",
                        fontWeight: "600",
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                    }}
                >
                    Property Details
                </h3>
                <p
                    style={{
                        margin: "0.75rem 0",
                        fontSize: "16px",
                        color: "#555",
                        fontWeight: "500",
                        lineHeight: "1.6",
                    }}
                >
                    <span style={{color: "#002961", fontWeight: "600"}}>Total Units:</span>{" "}
                    {total_property_units}
                </p>
                <p
                    style={{
                        margin: "0.75rem 0",
                        fontSize: "16px",
                        color: "#555",
                        fontWeight: "500",
                        lineHeight: "1.6",
                    }}
                >
                    <span style={{color: "#27ae60", fontWeight: "600"}}>Occupied Units:</span>{" "}
                    {occupied_property_units}
                </p>

                <p
                    style={{
                        margin: "0.75rem 0",
                        fontSize: "16px",
                        color: "#555",
                        fontWeight: "500",
                        lineHeight: "1.6",
                    }}
                >
                    <span style={{color: "#c0392b", fontWeight: "600"}}>Vacant Units:</span>{" "}
                    {vacant_property_units}
                </p>

                {/*   Add the additional information
                <p style={{fontSize: "16px", marginTop: "0.75rem"}}>
                    <span style={{fontWeight: "600", color: "#002961"}}>Expected Income:</span> {expectedIncome}
                </p>
                <p style={{fontSize: "16px", marginTop: "0.75rem"}}>
                    <span style={{fontWeight: "600", color: "#002961"}}>Received Income:</span> {receivedIncome}
                </p>
                <p style={{fontSize: "16px", marginTop: "0.75rem"}}>
                    <span style={{fontWeight: "600", color: "#002961"}}>Tax:</span> {tax}
                </p>
                <p style={{fontSize: "16px", marginTop: "0.75rem"}}>
                    <span style={{fontWeight: "600", color: "#002961"}}>Deposit Held:</span> {depositHeld}
                </p>
                <p style={{fontSize: "16px", marginTop: "0.75rem"}}>
                    <span style={{fontWeight: "600", color: "#002961"}}>Expense Cost:</span> {expenseCost}
                </p>*/}
            </div>
        </div>
    );
};

export default ProgressBars;
