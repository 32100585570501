import React, {useEffect, useState} from "react";
import {
    Box,
    Typography,
    IconButton,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    TextField,
    Stack,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {ApiDetails} from "../../../../dummyData";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";
import {DeleteOutline} from "@mui/icons-material";

export default function TenantFiles(props) {
    const {files} = props; // Access tenant files from props
    const [isEditing, setIsEditing] = useState(false); // State to toggle between views
    const [uploadedFiles, setUploadedFiles] = useState(files); // State to manage file updates
    const [fileInputs, setFileInputs] = useState([null, null, null, null]); // State for the 4 file inputs

    // Placeholder function for sharing a file
    const handleShare = (fileName) => {
        alert(`Sharing file: ${fileName}`);
    };

    // Placeholder function for previewing a file
    const handlePreview = (base64, extension) => {
        if (base64 !== "No File Found") {
            const fileURL = `data:application/${extension};base64,${base64}`;
            window.open(fileURL, "_blank");
        } else {
            alert("File not found for preview.");
        }
    };

    // Placeholder function for downloading a file
    const handleDownload = (base64, fileName, extension) => {
        if (base64 !== "No File Found") {
            const link = document.createElement("a");
            link.href = `data:application/${extension};base64,${base64}`;
            link.download = fileName;
            link.click();
        } else {
            alert("File not found for download.");
        }
    };

    // Handle file uploads for multiple inputs
    const handleFileUpload = (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                const fileName = file.name.split(".")[0];
                const fileExtension = file.name.split(".").pop();

                // Update the uploadedFiles state
                const updatedFiles = {
                    ...uploadedFiles,
                    [fileName]: {
                        base64: base64String,
                        extension: fileExtension,
                    },
                };
                setUploadedFiles(updatedFiles);

                // Update the specific file input state
                const updatedFileInputs = [...fileInputs];
                updatedFileInputs[index] = file.name; // Store the file name for display
                setFileInputs(updatedFileInputs);
            };
            reader.readAsDataURL(file);
        }
    };

    // Convert props.files object into an array for easier mapping
    const fileEntries = Object.entries(uploadedFiles).filter(
        ([key, value]) => value.base64 !== "No File Found" && key !== "defaults" // Filter out files that are not found
    );

    //Tenant Photo
    const [tenantPhoto, setTenantPhoto] = useState("");
    const [photo, setPhoto] = useState(null);

    //Tenant National ID
    const [photoOfNationalID, setPhotoOfNationalID] = useState("");
    const [nationalID, setNationalID] = useState(null);

    //Tenant Contract
    const [copyOfContract, setCopyOfContract] = useState("");
    const [contract, setContract] = useState(null);

    //Tenant Checklist
    const [inventoryChecklist, setInventoryChecklist] = useState("");
    const [checklist, setChecklist] = useState(null);


    function handleFile1(e) {
        let filename = getFileName(e);
        setPhoto(filename);
        setTenantPhoto(e.target.files[0]);
    }

    function handleFile2(e) {
        let filename = getFileName(e);
        setNationalID(filename);
        setPhotoOfNationalID(e.target.files[0]);
    }

    function handleFile3(e) {
        let filename = getFileName(e);
        setContract(filename);
        setCopyOfContract(e.target.files[0]);
    }

    function handleFile4(e) {
        let filename = getFileName(e);
        setChecklist(filename);
        setInventoryChecklist(e.target.files[0]);
    }

    const getFileName = (e) => {
        let startIndex =
            e.target.value.indexOf("\\") >= 0
                ? e.target.value.lastIndexOf("\\")
                : e.target.value.lastIndexOf("/");
        let filename = e.target.value.substring(startIndex);
        if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
            filename = filename.substring(1);
        }
        return filename;
    };

    function getExtension(filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
    }

    function checkImageFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "jpg":
            case "jpeg":
            case "png":
            case "pdf":
                //etc
                return true;
        }
        return false;
    }

    function checkTextFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "txt":
            case "doc":
            case "docx":
            case "pdf":
                //etc
                return true;
        }
        return false;
    }

    useEffect(() => {

        if (props.files.defaults.tenant_photo.trim() !== "") {
            setPhoto(
                props.files.defaults.tenant_photo
                    .toString()
                    .split("FILE_EXTENSION")[0]
                    .split("/")[
                props.files.defaults.tenant_photo
                    .toString()
                    .split("FILE_EXTENSION")[0]
                    .split("/").length - 1
                    ]
            );
            setTenantPhoto(props.files.defaults.tenant_photo);
            // setTenantPhoto(data.tenant_photo);
        }
        if (props.files.defaults.photo_of_national_id.trim() !== "") {
            setNationalID(
                props.files.defaults.photo_of_national_id
                    .toString()
                    .split("FILE_EXTENSION")[0]
                    .split("/")[
                props.files.defaults.photo_of_national_id
                    .toString()
                    .split("FILE_EXTENSION")[0]
                    .split("/").length - 1
                    ]
            );
            setPhotoOfNationalID(props.files.defaults.photo_of_national_id);
            // setPhotoOfNationalID(data.photo_of_national_id)
        }

        if (props.files.defaults.copy_of_contract.trim() !== "") {
            setContract(
                props.files.defaults.copy_of_contract
                    .toString()
                    .split("FILE_EXTENSION")[0]
                    .split("/")[
                props.files.defaults.copy_of_contract
                    .toString()
                    .split("FILE_EXTENSION")[0]
                    .split("/").length - 1
                    ]
            );
            setCopyOfContract(props.files.defaults.copy_of_contract);
            // setCopyOfContract(data.copy_of_contract)
        }

        if (props.files.defaults.inventory_checklist.trim() !== "") {
            setChecklist(
                props.files.defaults.inventory_checklist
                    .toString()
                    .split("FILE_EXTENSION")[0]
                    .split("/")[
                props.files.defaults.inventory_checklist
                    .toString()
                    .split("FILE_EXTENSION")[0]
                    .split("/").length - 1
                    ]
            );
            setInventoryChecklist(props.files.defaults.inventory_checklist);
            // setInventoryChecklist(data.inventory_checklist)
        }


    }, []);


    const updateTenantFiles = () => {
        let formData = new FormData();
        //Add values to formData
        formData.append(
            "details",
            JSON.stringify({
                tenant_id: props.tenantDetails.tenant_id,
                landlord_id: JSON.parse(props.originalText)[0][1],
                property_name: props.propertyName,
                property_id: props.propertyID,
                photo_of_national_id:
                    typeof photoOfNationalID === "string" ? photoOfNationalID : "",
                copy_of_contract:
                    typeof copyOfContract === "string" ? copyOfContract : "",
                inventory_checklist:
                    typeof inventoryChecklist === "string" ? inventoryChecklist : "",
                tenant_photo: typeof tenantPhoto === "string" ? tenantPhoto : "",
            })
        );

        formData.append("photo_of_national_id", photoOfNationalID);
        formData.append("copy_of_contract", copyOfContract);
        formData.append("inventory_checklist", inventoryChecklist);
        formData.append("tenant_photo", tenantPhoto);

        fetch(ApiDetails + "pegasus/visionary/tenant/update/tenant/files", {
            method: "POST",
            body: formData,
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    showSnackbar({
                        title: "Success!",
                        message: "Tenant files updated successfully.",
                        type: "success", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Failed updating tenant files.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Try again later.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });
    };


    return (
        <Box
            sx={{
                padding: "20px",
                backgroundColor: "#F7F9FC",
                borderRadius: "8px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    variant="h6"
                    fontWeight="bold"
                    color={"#2C5283"}
                    gutterBottom
                >
                    Tenant Files
                </Typography>

                <Button
                    variant="outlined"
                    sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                    onClick={() => setIsEditing(!isEditing)} // Toggle editing state
                >
                    {isEditing ? "Back to Files" : "Edit Files"}
                </Button>
            </Box>

            {isEditing ? (
                // File Upload Form with 4 input boxes
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#FFF",
                        padding: "20px",
                        marginTop: "10px",
                        borderRadius: "8px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <UploadFileIcon sx={{fontSize: "50px", color: "#9CA3AF"}}/>
                    <Typography
                        variant="h6"
                        sx={{
                            marginTop: "10px",
                            fontWeight: "bold",
                            color: "#2C5283",
                        }}
                    >
                        Upload Files (Up to 4)
                    </Typography>
                    <Stack spacing={2} sx={{marginTop: "20px", width: "100%"}}>
                        {tenantPhoto === "" ? (
                            <TextField
                                type="file"
                                onChange={(event) => handleFile1(event)}
                                fullWidth
                                inputProps={{accept: ".pdf,.doc,.docx,.txt,.png,.jpg"}}
                                label={`Tenant photo`}
                                helperText={"No file selected"}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <span>{photo}</span>
                                <DeleteOutline
                                    className="userListDelete"
                                    onClick={() => {
                                        setTenantPhoto("");
                                        setPhoto(null);
                                    }}
                                />
                            </div>
                        )}
                        {photoOfNationalID === "" ? (
                            <TextField
                                type="file"
                                onChange={(event) => handleFile2(event)}
                                fullWidth
                                inputProps={{accept: ".pdf,.doc,.docx,.txt,.png,.jpg"}}
                                label={`National ID`}
                                helperText={"No file selected"}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <span>{nationalID}</span>
                                <DeleteOutline
                                    className="userListDelete"
                                    onClick={() => {
                                        setPhotoOfNationalID("");
                                        setNationalID(null);
                                    }}
                                />
                            </div>
                        )}
                        {copyOfContract === "" ? (
                            <TextField
                                type="file"
                                onChange={(event) => handleFile3(event)}
                                fullWidth
                                inputProps={{accept: ".pdf,.doc,.docx,.txt,.png,.jpg"}}
                                label={`Contract`}
                                helperText={"No file selected"}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <span>{contract}</span>
                                <DeleteOutline
                                    className="userListDelete"
                                    onClick={() => {
                                        setCopyOfContract("");
                                        setContract(null);
                                    }}
                                />
                            </div>
                        )}
                        {inventoryChecklist === "" ? (
                            <TextField
                                type="file"
                                onChange={(event) => handleFile4(event)}
                                fullWidth
                                inputProps={{accept: ".pdf,.doc,.docx,.txt,.png,.jpg"}}
                                label={`Inventory`}
                                helperText={"No file selected"}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <span>{checklist}</span>
                                <DeleteOutline
                                    className="userListDelete"
                                    onClick={() => {
                                        setInventoryChecklist("");
                                        setChecklist(null);
                                    }}
                                />
                            </div>
                        )}
                    </Stack>

                    {/* Submit Button */}
                    <Button
                        variant="contained"
                        sx={{
                            marginTop: "20px",
                            backgroundColor: "#2C5283",
                            color: "#FFF",
                            fontWeight: "bold",
                            textTransform: "none",
                            padding: "10px 20px",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                            "&:hover": {
                                backgroundColor: "#1A365D",
                            },
                        }}
                        onClick={updateTenantFiles}
                    >
                        Submit Files
                    </Button>
                </Box>

            ) : fileEntries.length > 0 ? (
                    <List>
                        {fileEntries.map(([key, fileData], index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "15px",
                                        backgroundColor: "#FFF",
                                        borderRadius: "8px",
                                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                        padding: "10px",
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{backgroundColor: "#2C5283"}}>
                                            <InsertDriveFileIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={key.replace(/_/g, " ")}
                                        secondary={`${fileData.extension} File`}
                                        sx={{
                                            marginRight: "auto",
                                            ".MuiTypography-root": {
                                                fontSize: "14px",
                                            },
                                        }}
                                    />
                                    <IconButton
                                        sx={{color: "#2C5283"}}
                                        onClick={() =>
                                            handlePreview(fileData.base64, fileData.extension)
                                        }
                                    >
                                        <VisibilityIcon/>
                                    </IconButton>
                                    <IconButton
                                        sx={{color: "#2C5283"}}
                                        onClick={() => handleShare(key)}
                                    >
                                        <ShareIcon/>
                                    </IconButton>
                                    <IconButton
                                        sx={{color: "#2C5283"}}
                                        onClick={() =>
                                            handleDownload(
                                                fileData.base64,
                                                `${key}.${fileData.extension}`,
                                                fileData.extension
                                            )
                                        }
                                    >
                                        <DownloadIcon/>
                                    </IconButton>
                                </ListItem>
                            )
                        )}
                    </List>
                ) :
                (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            backgroundColor: "#fff",
                            padding: "30px",
                            borderRadius: "8px",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <FolderOffIcon sx={{fontSize: "50px", color: "#9CA3AF"}}/>
                        <Typography
                            variant="h6"
                            sx={{
                                marginTop: "10px",
                                fontWeight: "bold",
                                color: "#9CA3AF",
                            }}
                        >
                            No Files Found
                        </Typography>
                    </Box>
                )
            }
        </Box>
    );
}
