import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    IconButton,
    Avatar,
    Divider,
    Fade, MenuItem,
} from "@mui/material";
import {styled} from "@mui/system";
import {ApiDetails} from "../../../../dummyData";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";
import Tooltip from "@mui/material/Tooltip";
import {BugReport} from "@material-ui/icons";
import {DeleteOutline} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";
import Backdrop from "@mui/material/Backdrop";
import {BarWave} from "react-cssfx-loading";

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    "& .MuiDataGrid-columnHeaders": {
        fontSize: "0.9rem",
        fontWeight: 600,
        backgroundColor: "#FBF4F0", // Logo blue
        color: "black", // White text
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "#F4F6F8", // Subtle hover color
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "0.8rem",
        color: "#002961", // Top bar color
    },
}));


const Arrears = (props) => {

    const [arrears, setArrears] = useState({});

    useEffect(() => {

        fetch(ApiDetails + "pegasus/visionary/tenant/get/tenant/arrears", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: props.tenantID,
            }),
        })
            .then(async (response) => {
                let arrears = await response.json();
                if (response.status === 200) {
                    setArrears(arrears);
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Could not fetch transactions.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });

            });
    }, [])

    const arrearsColumn = [
        {
            field: "description",
            headerName: "Description",
            headerAlign: "center",
            // width: 180,
            minWidth: 180,
            flex: 2,
            sortOrder: "asc",
        },
        {
            field: "amount",
            headerName: "Amount",
            headerAlign: "center",
            // width: 120,
            minWidth: 120,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.balance.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "date_created",
            headerAlign: "center",
            align: "center",
            headerName: "Date",
            // width: 170,
            minWidth: 170,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            fontSize: "12px",
                        }}
                    >
                        {params.row.date_created}
                    </div>
                );
            },
        },
        {
            field: "",
            headerName: "Action",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {params.row.dirty === "TRUE" ? (
                            <>
                                <Tooltip title="Remove Issue" placement="bottom-start">
                                    <BugReport
                                        style={{
                                            color: "red",
                                        }}
                                        onClick={() => {
                                            //open confirm delete modal
                                            //Delete arrear
                                            markArrearDirty(params.row.id, "FALSE");
                                        }}
                                    />
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title="Raise Issue" placement="bottom-start">
                                    <BugReport
                                        style={{color: "green"}}
                                        onClick={() => {
                                            //open confirm delete modal
                                            //Delete arrear
                                            markArrearDirty(params.row.id, "TRUE");
                                        }}
                                    />
                                </Tooltip>
                            </>
                        )}
                        <Tooltip title="Delete" placement="bottom-start">
                            <DeleteOutline
                                className="userListDelete"
                                style={{
                                    paddingLeft: "10px",
                                    color: "red",
                                }}
                                onClick={() => {
                                    //open confirm delete modal
                                    //Delete arrear
                                    deleteArrears(params.row.id);
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const deleteArrears = (id) => {
        //Add arrear to database
        let body = {
            tenant_id: props.tenantID,
            id: id,
        };
        fetch(ApiDetails + "pegasus/visionary/tenant/delete/arrears", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    window.location.reload(false);
                    // handleClose();
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "An error occurred processing your request. Please try again later.",
                        type: "error", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });

                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something Went Wrong. Try Again Later.",
                    type: "error", // success | error | warning | info
                    position: {vertical: "bottom", horizontal: "right"}, // Custom position
                });

            });
    };
    const markArrearDirty = (id, dirty) => {
        //Add arrear to database
        let body = {
            id: id,
            dirty: dirty,
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/arrear/dirty", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    showSnackbar({
                        title: "Success!",
                        message: "Arrears successfully marked as dirty.",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Failed updating arrear details.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later..",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });
    };
    return (

        <StyledDataGrid
            rows={arrears}
            columns={arrearsColumn}
            disableSelectionOnClick
            pageSize={5}
            rowsPerPageOptions={[5, 10]}
            autoHeight
        />
    );
};

export default Arrears;
