import * as yup from "yup";
import "./VacatingTenantList.css";
import {makeStyles} from "@material-ui/core/styles";
import {DataGrid} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {TextField} from "@material-ui/core";
import {WarningAmberOutlined, Restore} from "@mui/icons-material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";
import {ApiDetails, KEY} from "../../dummyData";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import {CheckCircleOutlineOutlined, Info} from "@material-ui/icons";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import CryptoJS from "crypto-js";
import NonEditableRichTextEditor from "../../components/RichTextEditorNotEditable/NonEditableRichTextEditor";
import {convertFromRaw, EditorState} from "draft-js";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});

export default function VacatingTenantList(props) {
    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.getItem("myMap"),
        KEY
    );
    let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.portfolioDetails,
        KEY
    );
    let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    let originalText2 = portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    //Navigator
    const navigate = useNavigate();

    //Loading Screen
    const [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );

    const [dataSet, setDataSet] = useState(false);

    let [tenants, updateTenants] = React.useState([]);
    const [searchResults, setSearchResults] = useState(tenants);
    let [properties, updateProperties] = React.useState([]);
    const [expensesIncurred, setExpensesIncurred] = useState(1);
    const [expenses, setExpenses] = useState([]);
    const [expenseItem] = useState(new Map());
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [depositToRefund, setDepositToRefund] = useState(0);
    const [lossIncurred, setLossIncurred] = useState(0);
    const [tenantDetails, setTenantDetails] = useState({
        tenant_id: "",
        tenant_name: "",
        deposit_held: "",
        phone_number: "",
        secondary_phone_number: "",
    });
    const [noticeDetails, setNoticeDetails] = useState({
        last_name: "",
        first_name: "",
        date_created: "",
        deposit_held: "",
        date_to_vacate_house: "",
        reasons_for_vacating: "",

    });

    const handleChange = (props) => (event) => {
        // console.log("Target event name : " + event.target.name);
        expenseItem.set(event.target.name, event.target.value);
        setExpenses([...expenses, expenseItem]);
        // expenseItem.clear();

    };

    const [inputs, setInputs] = useState(
        <div>
            <br/>
            <div
                style={{
                    display: "none",
                    justifyContent: "space-between",
                }}
            >
                <TextField
                    label="Description"
                    type={"text"}
                    variant="outlined"
                    required
                    style={{marginTop: "10px", width: "49%"}}
                />
                <TextField
                    label="Amount"
                    type={"number"}
                    variant="outlined"
                    style={{marginTop: "10px", width: "49%"}}
                />
            </div>
        </div>
    );
    const [depositExpenses, setDepositExpenses] = useState(
        <div>
            <br/>
            <div
                style={{
                    display: "none",
                    justifyContent: "space-between",
                }}
            >
                <TextField
                    label="Description"
                    type={"text"}
                    variant="outlined"
                    required
                    style={{marginTop: "10px", width: "49%"}}
                />
                <TextField
                    label="Amount"
                    type={"number"}
                    variant="outlined"
                    style={{marginTop: "10px", width: "49%"}}
                />
            </div>
        </div>
    );

    //Trying To Use UseEffect To Set Component Data
    useEffect(() => {
        props.refreshSideBar();
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/vacating/tenants", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let tenants = await response.json();

                if (response.status === 200) {
                    // console.log(tenants);
                    //Get All Properties
                    fetch(ApiDetails + "pegasus/visionary/property/get/all/properties", {
                        method: "POST",
                        body: JSON.stringify({
                            landlord_id: JSON.parse(originalText)[0][1],
                        }),
                    })
                        .then(async (response) => {
                            let data = await response.json();

                            if (response.status === 200) {
                                // console.log(tenants);
                                updateProperties(data);
                                updateTenants(tenants);
                                setSearchResults(tenants);
                                setBackDrop(<></>);
                                // setDetailsFetched(true);
                            } else {
                                props.snackBar({
                                    text: "Failed Fetching Details",
                                    type: "error",
                                });
                            }
                        })
                        .catch((err) => console.log(err));
                } else {
                    props.snackBar({text: "Failed Fetching Details", type: "error"});
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const [restoreTenantDetails, setRestoreTenantDetails] = useState({
        property_id: "",
        tenant_id: "",
        house_number: "",
        name: "",
    });
    const restoreTenant = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/restore", {
            method: "POST",
            body: JSON.stringify({
                property_id: restoreTenantDetails.property_id,
                tenant_id: restoreTenantDetails.tenant_id,
                house_number: restoreTenantDetails.house_number,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    props.snackBar({
                        text: "Tenant restored successfully",
                        type: "success",
                    });
                    handleClose();
                    getVacatingTenants();
                } else if (response.status === 403) {
                    props.snackBar({
                        text: "Unit is currently occupied by another tenant",
                        type: "error",
                    });
                    handleClose();
                    getVacatingTenants();
                } else {
                    props.snackBar({
                        text: "Something went wrong try again later",
                        type: "error",
                    });
                }
            })
            .catch((err) => console.log(err));
    };
    const getVacatingTenants = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/vacating/tenants", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let tenants = await response.json();

                if (response.status === 200) {
                    // console.log(tenants);
                    //Get All Properties
                    fetch(ApiDetails + "pegasus/visionary/property/get/all/properties", {
                        method: "POST",
                        body: JSON.stringify({
                            landlord_id: JSON.parse(originalText)[0][1],
                        }),
                    })
                        .then(async (response) => {
                            let data = await response.json();

                            if (response.status === 200) {
                                // console.log(data);
                                updateProperties(data);
                                updateTenants(tenants);
                                setSearchResults(tenants);
                                setBackDrop(<></>);
                                // setDetailsFetched(true);
                            } else {
                                props.snackBar({
                                    text: "Failed Fetching Details",
                                    type: "error",
                                });
                            }
                        })
                        .catch((err) => console.log(err));
                } else {
                    props.snackBar({text: "Failed Fetching Details", type: "error"});
                }
            })
            .catch((err) => console.log(err));
    };

    //Modal states
    const [deleteModal, setDeleteModal] = useState(false);
    const [refundModal, setRefundModal] = useState(false);
    const [moreInfoModal, setMoreInfoModal] = useState(false);
    const [addDepositExpenseModal, setAddDepositExpenseModal] = useState(false);
    const [vacatingModal, setVacatingModal] = useState(false);

    const classes = useStyles();

    const getTenants = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/vacating/tenants", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let tenants = await response.json();

                if (response.status === 200) {
                    // console.log(data);
                    updateTenants(tenants);
                    setSearchResults(tenants);
                } else {
                    props.snackBar({text: "Failed Fetching Details", type: "error"});
                }
            })
            .catch((err) => console.log(err));
    };

    const handleClose = () => {
        setDataSet(false);
        setDeleteModal(false);
        setRefundModal(false);
        setMoreInfoModal(false);
        setVacatingModal(false);
        setAddDepositExpenseModal(false);
    };

    const calculateRefundableDeposit = () => {
        // console.log(key + " = " + value);
        let issueExpenses = new Map(expenses[0]);
        let sortedMap = new Map();
        let expense = 0;

        issueExpenses.forEach(function (value, key) {
            let newKey = value;
            let includes = key;
            // console.log(key + " = " + value);
            if (!key.includes("value")) {
                issueExpenses.forEach(function (value, key) {
                    // console.log(key + " = " + value);
                    if (key.includes(includes)) {
                        sortedMap.set(newKey, value);
                    }
                });
            }
        });

        sortedMap.forEach(function (value, key) {
            expense = parseFloat(expense) + parseFloat(value);
        });

        setTotalExpenses(
            expense.toLocaleString("en-US", {
                style: "currency",
                currency: "KSH",
            })
        );
        let depositToRefund =
            parseFloat(tenantDetails.deposit_held) - parseFloat(expense);
        if (depositToRefund >= 0) {
            setDepositToRefund(depositToRefund);
        } else {
            setDepositToRefund(0);
            setLossIncurred(depositToRefund * -1);
        }
        // console.log(expense);
    };
    const handleRefundDeposit = () => {
        let issueExpenses = new Map(expenses[0]);
        let sortedMap = new Map();

        issueExpenses.forEach(function (value, key) {
            let newKey = value;
            let includes = key;
            // console.log(key + " = " + value);
            if (!key.includes("value")) {
                issueExpenses.forEach(function (value, key) {
                    // console.log(key + " = " + value);
                    if (key.includes(includes)) {
                        sortedMap.set(newKey, value);
                    }
                });
            }
        });
        setLoading(true);

        //Make API call to delete tenant
        fetch(ApiDetails + "pegasus/visionary/tenant/vacating/tenants/deposit", {
            method: "POST",
            body: JSON.stringify({
                ...Object.fromEntries(sortedMap),
                tenant_id: tenantDetails.tenant_id,
                deposit_to_refund: depositToRefund,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    getTenants();
                    props.snackBar({
                        text: "Tenant Details Updated Successful",
                        type: "success",
                    });
                    handleClose();
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };
    const searchUser = (e) => {
        setSearchResults(
            Array.from(tenants).filter((item) =>
                (
                    item.first_name.toString().toLowerCase() +
                    " " +
                    item.last_name.toString().toLowerCase()
                ).includes(e.target.value.toLowerCase())
            )
        );
    };

    const [loading, setLoading] = React.useState(false);
    const [tenantLoading, setTenantLoading] = React.useState(false);

    const basicSchema = yup.object().shape({
        phone_number: yup.string().phone("KE", true).required("Required"),
        amount: yup.number().min(0, "Amount can't be lower than 0"),
    });

    const [initialPaymentsDetails, setInitialPaymentsDetails] = useState(false);

    const [initialPaymentValues, setInitialPaymentValues] = useState({
        names: "",
        amount: "",
        deposit: "",
        expenses: "",
        tenant_id: "",
        phone_number: "",
        property_id: "",
        house_number: "",
        account_number: "",
        secondary_phone_number: "",
    });
    const processPayment = (values) => {
        // values.phone_number;
        setTenantLoading(true);

        console.log(values);

        let status;
        let totalExpenses = 0;

        let deposit = parseFloat(values.deposit_held);
        new Map(Object.entries(values.expenses)).forEach((expense) => {
            totalExpenses = totalExpenses + parseFloat(expense);
        });
        let indicator = 0;
        // console.log("deposit : " + deposit);
        // console.log("total expenses : " + totalExpenses);
        indicator = deposit - totalExpenses;

        if (indicator > 0) {
            status = "REFUNDED";
        } else if (indicator === 0) {
            status = "NEUTRAL";
        } else {
            status = "LOSS";
        }

        // console.log("totalExpenses :" + totalExpenses);
        // console.log(values);

        let body = {
            ...values,
            status: status,
            indicator: indicator,
            landlord_id: JSON.parse(originalText)[0][1],

        };

        // fetch(ApiDetails + "pegasus/visionary/mpesa/B2C", {
        fetch(ApiDetails + "pegasus/visionary/tenant/handle/deposit", {

            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                // let data = await response.json();
                if (response.status === 200) {
                    window.location.reload(false);
                } else {
                    props.snackBar({
                        text: "Failed Fetching Details",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something went wrong. Try again later",
                    type: "error",
                });
            });

        setTenantLoading(false);
        // handleClose();
    };
    const processDepositAsIncome = (values) => {
        // values.phone_number;
        setTenantLoading(true);

        let body = {
            ...values,
            landlord_id: JSON.parse(originalText)[0][1],

        };

        // fetch(ApiDetails + "pegasus/visionary/tenant/handle/deposit", {
        fetch(ApiDetails + "pegasus/visionary/tenant/deposit/income", {

            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                // let data = await response.json();
                if (response.status === 200) {
                    window.location.reload(false);
                } else {
                    props.snackBar({
                        text: "Failed Fetching Details",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something went wrong. Try again later",
                    type: "error",
                });
            });

        setTenantLoading(false);
        // handleClose();
    };
    const columns = [
        {
            field: "user",
            headerName: "Name",
            // width: 200,
            minWidth: 200,
            flex: 1,
            sortOrder: "asc",
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                        }}
                        className="userListUser"
                    >
                        <div
                            style={{
                                margin: "0 5px",
                            }}
                        >
                            {params.row.state === "NOTICE" ? (
                                <WarningAmberOutlined
                                    style={{
                                        color: "#F0972A",
                                    }}
                                />
                            ) : (
                                <CheckCircleOutlineOutlined
                                    style={{
                                        color: "#57AF51",
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            {params.row.first_name} {params.row.last_name}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "property_name",
            align: "left",
            headerName: "Property Name",
            // width: 150,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "house_number",
            align: "left",
            headerName: "HSE NO",
            minWidth: 50,
            flex: 1,
        },
        // {
        //   field: "arrears",
        //   headerName: "Arrears",
        //   width: 80,
        //   align: "center",
        //   renderCell: (params) => {
        //     // console.log(params.row.arrears);
        //     if (params.row.arrears === 0) {
        //       return (
        //         <div>
        //           <div style={{ color: "green" }}>{params.row.arrears}</div>
        //         </div>
        //       );
        //     } else {
        //       return (
        //         <div>
        //           <div style={{ color: "red" }}>{params.row.arrears}</div>
        //         </div>
        //       );
        //     }
        //   },
        // },
        {
            field: "bill",
            headerName: "Pending Bill",
            // width: 150,
            minWidth: 100,
            flex: 1,
            headerAlign: "left",
            align: "left",
            renderCell: (params) => {
                let arrears =
                    params.row.status === "NOT PAID"
                        ? parseFloat(params.row.bill)
                        : parseFloat(params.row.arrears) + parseFloat(params.row.bill);
                if (arrears === 0) {
                    return (
                        <div>
                            <div style={{color: "green"}}>
                                {arrears.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <div style={{color: "red"}}>
                                {arrears.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>
                    );
                }
            },
        },
        {
            field: "deposit_held",
            headerName: "Deposit Held",
            // width: 150,
            minWidth: 150,
            flex: 1,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {parseInt(
                            params.row.deposit_held.toString().replace(",", "")
                        ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "deposit_to_refund",
            headerName: "Deposit To Refund",
            // width: 180,
            minWidth: 180,
            flex: 1,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {params.row.deposit_to_refund === "" ? (
                            <>NOT SET</>
                        ) : (
                            <>
                                {parseInt(
                                    params.row.deposit_to_refund.toString().replace(",", "")
                                ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Actions",
            // width: 200,
            minWidth: 400,
            flex: 1,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            width: "100%",
                        }}
                        className="userListAction">
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%"
                                }}
                            >

                                <div>

                                    {params.row.deposit_to_refund === "" ? (
                                        <>
                                            <Button
                                                onClick={() => {
                                                    //Opens refund modal
                                                    setTenantDetails({
                                                        tenant_id: params.id,
                                                        tenant_name:
                                                            params.row.first_name + " " + params.row.last_name,
                                                        deposit_held: params.row.deposit_held,
                                                    });

                                                    if (params.row.arrears !== 0 || params.row.bill !== 0) {
                                                        let arrears =
                                                            params.row.status === "NOT PAID"
                                                                ? parseFloat(params.row.bill)
                                                                : parseFloat(params.row.arrears) +
                                                                parseFloat(params.row.bill);
                                                        // console.log("Arrears: " + arrears);
                                                        expenseItem.set("description 1", arrears);
                                                        setExpenses([...expenses, expenseItem]);
                                                        // expenseItem.clear();
                                                        setExpensesIncurred(expensesIncurred + 1);
                                                        setInputs(
                                                            [...Array(expensesIncurred)].map(
                                                                (elementInArray, index) => (
                                                                    <div key={index}>
                                                                        <br/>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <TextField
                                                                                label="Description"
                                                                                type={"text"}
                                                                                variant="outlined"
                                                                                name={"description " + (index + 1)}
                                                                                defaultValue={"Arrears"}
                                                                                disabled={true}
                                                                                style={{
                                                                                    marginTop: "10px",
                                                                                    width: "49%",
                                                                                }}
                                                                                InputLabelProps={{shrink: true}}
                                                                                onChange={handleChange()}
                                                                            />
                                                                            <TextField
                                                                                label="Amount"
                                                                                type={"number"}
                                                                                variant="outlined"
                                                                                name={
                                                                                    "value for description " + (index + 1)
                                                                                }
                                                                                defaultValue={arrears}
                                                                                disabled={true}
                                                                                style={{
                                                                                    marginTop: "10px",
                                                                                    width: "49%",
                                                                                }}
                                                                                InputLabelProps={{shrink: true}}
                                                                                onChange={handleChange()}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )
                                                        );
                                                    }

                                                    setAddDepositExpenseModal(true);
                                                }}
                                                variant="outlined"
                                            >
                                                Add Expenses
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "100%"
                                                }}
                                            >
                                                <Button
                                                    onClick={() => {
                                                        //Opens refund modal
                                                        setTenantDetails({
                                                            tenant_id: params.id,
                                                            tenant_name:
                                                                params.row.first_name + " " + params.row.last_name,
                                                            deposit_held: params.row.deposit_held,
                                                        });
                                                        console.log(params.row);
                                                        setInitialPaymentValues({
                                                            names:
                                                                params.row.first_name + " " + params.row.last_name,
                                                            tenant_id: params.row.tenant_id,
                                                            expenses: params.row.deposit_expenses,
                                                            deposit_held: params.row.deposit_held,
                                                            amount: params.row.deposit_to_refund,
                                                            phone_number: params.row.phone_number,
                                                            secondary_phone_number:
                                                            params.row.secondary_phone_number,
                                                            property_id: params.row.property_id,
                                                            house_number: params.row.house_number,
                                                            account_number: params.row.account_number
                                                        });
                                                        setInitialPaymentsDetails(true);
                                                        setRefundModal(true);
                                                        // setOpen(true);
                                                    }}
                                                    variant="outlined"
                                                >
                                                    Refund Deposit
                                                </Button>
                                                <>
                                                    <Tooltip title="More information">
                                                        <Info
                                                            onClick={() => {
                                                                let issueExpenses = new Map(
                                                                    Object.entries(params.row.deposit_expenses)
                                                                );
                                                                let expensesLength = 0;
                                                                let expenseDescriptions = [];
                                                                let expenseAmounts = [];
                                                                issueExpenses.forEach(function (value, key, index) {
                                                                    expensesLength = expensesLength + 1;
                                                                    expenseAmounts.push(value);
                                                                    expenseDescriptions.push(key);
                                                                });

                                                                setDepositExpenses(
                                                                    [...Array(expensesLength)].map(
                                                                        (elementInArray, index) => (
                                                                            <div key={index}>
                                                                                <br/>
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        justifyContent: "space-between",
                                                                                    }}
                                                                                >
                                                                                    <TextField
                                                                                        label="Description"
                                                                                        type={"text"}
                                                                                        variant="outlined"
                                                                                        value={expenseDescriptions[index]}
                                                                                        disabled={true}
                                                                                        style={{
                                                                                            marginTop: "10px",
                                                                                            width: "49%",
                                                                                        }}
                                                                                    />
                                                                                    <TextField
                                                                                        label="Amount"
                                                                                        type={"number"}
                                                                                        variant="outlined"
                                                                                        value={expenseAmounts[index]}
                                                                                        disabled={true}
                                                                                        style={{
                                                                                            marginTop: "10px",
                                                                                            width: "49%",
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                );

                                                                let totalExpense = 0;

                                                                expenseAmounts.map((amount) => {
                                                                    totalExpense =
                                                                        parseFloat(totalExpense) + parseFloat(amount);
                                                                });

                                                                setTotalExpenses(totalExpense);

                                                                setMoreInfoModal(true);
                                                            }}
                                                            style={{
                                                                paddingLeft: "5px",
                                                                color: "#398DD2",
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div
                                    style={{
                                        paddingLeft: "10px"
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            //Opens vacating notice modal
                                            setNoticeDetails({
                                                last_name: params.row.last_name,
                                                first_name: params.row.first_name,
                                                date_notice_given: params.row.date_created,
                                                deposit_held: params.row.deposit_held,
                                                date_to_vacate_house: params.row.date_to_vacate_house,
                                                reasons_for_vacating: params.row.reasons_for_vacating,
                                            });
                                            setDataSet(true);
                                            setVacatingModal(true)
                                        }}
                                        variant="outlined"
                                    >
                                        View Notice
                                    </Button>
                                </div>

                                <div
                                    style={{
                                        paddingLeft: "10px"
                                    }}
                                >
                                    <Restore
                                        style={{
                                            color: "green"
                                        }}
                                        onClick={() => {
                                            setRestoreTenantDetails({
                                                property_id: params.row.property_id,
                                                tenant_id: params.row.tenant_id,
                                                house_number: params.row.house_number,
                                                name: params.row.first_name + " " + params.row.last_name,
                                            });
                                            setDeleteModal(true);
                                        }}>
                                    </Restore>
                                </div>

                            </div>
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            {backDrop}

            <Dialog
                open={vacatingModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Vacating Form
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <small
                        style={{
                            color: "red",
                        }}
                    >
                        Kindly refresh if details are not clear
                    </small>
                    <br/>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        value={new Map(Object.entries(noticeDetails)).get("first_name")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        value={new Map(Object.entries(noticeDetails)).get("last_name")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Date To Vacate"
                        variant="outlined"
                        value={new Map(Object.entries(noticeDetails)).get("date_to_vacate_house")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Date Notice Given"
                        variant="outlined"
                        value={new Map(Object.entries(noticeDetails)).get("date_notice_given")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <br/>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                        }}
                    >
                        {dataSet &&
                            <NonEditableRichTextEditor
                                richTextValue={EditorState.createWithContent(
                                    convertFromRaw(
                                        JSON.parse(
                                            new Map(Object.entries(noticeDetails)).get("reasons_for_vacating")
                                        )
                                    )
                                )}
                            />
                        }
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "20px",
                        }}
                    >
                        <Button onClick={handleClose}>
                            <span style={{color: "green"}}>Close</span>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            {/*DELETE MODAL*/}
            <Dialog
                open={deleteModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    Restore {restoreTenantDetails.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>
                            Restoring a Tenant will return a tenant and his or her documents
                            back to the system and render the house occupied.
                        </Typography>
                        <br/>
                        <Typography>
                            Are you sure you want to restore this tenant ?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={restoreTenant}
                        loading={loading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Agree
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*REFUND MODAL*/}
            <Dialog
                open={refundModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Refund {tenantDetails.tenant_name}'s Deposit
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {initialPaymentsDetails ? (
                            <>
                                <Formik
                                    initialValues={initialPaymentValues}
                                    validationSchema={basicSchema}
                                    onSubmit={processPayment}
                                >
                                    {(props) => (
                                        <Form noValidate>
                                            <p>Enter number to send money to.</p>
                                            <Field
                                                as={TextField}
                                                name="phone_number"
                                                label="Phone Number"
                                                error={
                                                    props.errors.phone_number &&
                                                    props.touched.phone_number
                                                }
                                                helperText={
                                                    <ErrorMessage className="error" name="phone_number"/>
                                                }
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "100%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="amount"
                                                label="Amount"
                                                type="number"
                                                required
                                                variant="outlined"
                                                error={props.errors.amount && props.touched.amount}
                                                helperText={
                                                    <ErrorMessage className="error" name="amount"/>
                                                }
                                                style={{marginTop: "10px", width: "100%"}}
                                            />
                                            <br/>
                                            <br/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <div>
                                                    <small
                                                        style={{
                                                            fontSize: "18px",
                                                            fontWeight: "bold",
                                                            color: "#f05546",
                                                        }}
                                                    >
                                                        Kindly confirm phone number before proceeding!
                                                    </small>
                                                    <br/>
                                                </div>
                                            </div>
                                            <br/>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                {/*<Button onClick={handleClose}>*/}
                                                {/*  <span style={{ color: "red" }}>Cancel</span>*/}
                                                {/*</Button>*/}

                                                <LoadingButton
                                                    onClick={() => {
                                                        processDepositAsIncome(initialPaymentValues)
                                                        // console.log("Setting tenants deposit as income");

                                                    }}

                                                    size="small"
                                                    loading={tenantLoading}
                                                    startIcon={<SendIcon/>}
                                                    style={{
                                                        color: "green",
                                                        display: parseInt(initialPaymentValues.amount) === 0 ? "none" : ""
                                                    }}
                                                    loadingPosition="start"
                                                >
                                                    Income
                                                </LoadingButton>

                                                <LoadingButton
                                                    type={parseInt(initialPaymentValues.amount) === 0 ? "" : "submit"}
                                                    size="small"
                                                    loading={tenantLoading}
                                                    startIcon={<SendIcon/>}
                                                    style={{color: "green"}}
                                                    loadingPosition="start"
                                                    // disabled={parseInt(initialPaymentValues.amount) === 0}
                                                >
                                                    {parseInt(initialPaymentValues.amount) === 0 ? 'Remove Tenant' : 'Pay out'}
                                                </LoadingButton>
                                                <Button onClick={handleClose}>
                                                    <span style={{color: "red"}}>Cancel</span>
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        ) : (
                            <></>
                        )}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {/*DELETE MODAL*/}
            <Dialog
                open={addDepositExpenseModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Expenses To Subtract From {tenantDetails.tenant_name}'s Deposit
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography>Expenses Incurred</Typography>
                            <Button
                                onClick={() => {
                                    setExpensesIncurred(expensesIncurred + 1);
                                    setInputs(
                                        [...Array(expensesIncurred)].map(
                                            (elementInArray, index) => (
                                                <div key={index}>
                                                    <br/>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <TextField
                                                            label="Description"
                                                            type={"text"}
                                                            variant="outlined"
                                                            name={"description " + (index + 1)}
                                                            required
                                                            style={{marginTop: "10px", width: "49%"}}
                                                            onChange={handleChange("property" + (index + 1))}
                                                        />
                                                        <TextField
                                                            label="Amount"
                                                            type={"number"}
                                                            name={"value for description " + (index + 1)}
                                                            variant="outlined"
                                                            style={{marginTop: "10px", width: "49%"}}
                                                            onChange={handleChange("property" + (index + 1))}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        )
                                    );
                                }}
                            >
                                <span style={{color: "green"}}>Add Expense</span>
                            </Button>
                        </div>
                        <div>{inputs}</div>
                        <br/>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    calculateRefundableDeposit();
                                }}
                                variant="outlined"
                            >
                                Calculate
                            </Button>
                        </div>

                        <br/>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Deposit Held :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                }}
                            >
                                {parseFloat(
                                    tenantDetails.deposit_held.toString().replace(",", "")
                                ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Total Expenses :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                }}
                            >
                                {totalExpenses}
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Deposit To refund :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                }}
                            >
                                {depositToRefund.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Loss incurred :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    color: "red",
                                }}
                            >
                                {lossIncurred.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            window.location.reload(false);
                            handleClose();
                        }}
                    >
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={handleRefundDeposit}
                        loading={loading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Agree
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*VIEW DEPOSIT EXPENSES MODAL*/}
            <Dialog
                open={moreInfoModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Expenses Subtracted From Deposit
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <div>
                        <div>{depositExpenses}</div>
                        <br/>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green",
                                    flex: "2",
                                }}
                            >
                                Total Expenses :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                }}
                            >
                                {totalExpenses.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "KSH",
                                })}
                            </div>
                        </div>

                        {/*<div*/}
                        {/*  style={{*/}
                        {/*    width: "100%",*/}
                        {/*    display: "flex",*/}
                        {/*    justifyContent: "left",*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*  <div*/}
                        {/*    style={{*/}
                        {/*      color: "green",*/}
                        {/*      flex: "2",*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    Loss incurred :*/}
                        {/*  </div>*/}
                        {/*  <div*/}
                        {/*    style={{*/}
                        {/*      flex: "1",*/}
                        {/*      color: "red",*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    {lossIncurred.toLocaleString("en-US", {*/}
                        {/*      style: "currency",*/}
                        {/*      currency: "KSH",*/}
                        {/*    })}*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Close</span>
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="userList">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            padding: "10px 10px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            label="Search tenants ... "
                            variant="outlined"
                            name="firstname"
                            onChange={(e) => {
                                searchUser(e);
                            }}
                            style={{paddingRight: "10px"}}
                        />
                    </div>
                </div>
                <div className={"userListDataGrid"}>
                    <DataGrid
                        className={classes.root}
                        rows={searchResults}
                        disableSelectionOnClick
                        rowHeight={45}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </div>
            </div>
        </>
    );
}
