import React, {useEffect, useState} from "react";
import "./App.css";
import {ApiDetails, KEY, loginState} from "./dummyData";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./pages/Home/Home";
import {MyFileBrowser} from "./components/Backup/Backup";
import Error404 from "./pages/404/Error404";
import UserList from "./pages/tenantList/UserList";
import Topbar from "./components/topbar/Topbar";
import Property from "./pages/property/Property";
import PropertyList from "./pages/propertyList/PropertyList";
import CreateUser from "./pages/createUser/CreateUser";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Details from "./pages/details/Details";
import CustomizedDialogs from "./components/dialog/DialogTemplate";
import LoginDialog from "./components/authentication/LoginDialog";
import Transactions from "./pages/transactions/transactions";
import MailList from "./pages/mailsList/MailList";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Analytics from "./pages/analytics/Analytics";
import Expenses from "./pages/Expenses/Expenses";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import Message from "./pages/messages/Message";
import OldTenantList from "./pages/OldTenantList/OldTenantList";
import Events from "./pages/events/Events";
import Settings from "./pages/settings/Settings";
import TenantIssues from "./pages/tenantIssues/TenantIssues";
import VacatingTenantList from "./pages/vacatingTenantList/VacatingTenantList";
import LoginPage from "./components/authentication/LoginPage";
import SignupPage from "./components/authentication/SignupPage";
import TestSidebar from "./components/sidebar/TestSidebar";
import CryptoJS from "crypto-js";
import UserManagement from "./pages/AgentUserManagement/UserManagement";
import { ConfirmProvider } from "material-ui-confirm";
import Tenant from "./pages/tenant/Tenant";
import HomeV2 from "./pages/Home/HomeV2";
import TenantV2 from "./pages/tenant/TenantV2";
import {SnackbarComponent} from "./components/SnackBar/SnackBar";
import PropertyV2 from "./pages/property/PropertyV2";
import LoginPageV2 from "./components/authentication/Login/LoginPageV2";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
    let image = require("./assets/img/3747371.jpg");
    let myMapDetailsDecrypted;
    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(
            localStorage.getItem("myMap"),
            KEY
        );
    } catch (e) {
        myMapDetailsDecrypted = "";
    }
    const logout = () => {
        // 👇️ toggle class on click
        window.location.href = "/";
        localStorage.clear();
    };

    const [originalText, setOriginalText] = React.useState(
        myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8)
    );

    const [open, setOpen] = React.useState(loginState);

    const [emailPasswordDialog, setEmailPasswordDialog] = React.useState(false);

    const [backdrop, setBackDrop] = React.useState(null);

    const [password, setPassword] = React.useState("");

    const [message, setMessage] = React.useState("");

    const [severity, setSeverity] = React.useState();

    const [isLoggedIn, setLogin] = React.useState(
        !(
            window.location.pathname === "/" || window.location.pathname === "/signup"
        )
    );

    const [connectionMade, setConnectionMade] = React.useState(false);

    const changeLogin = () => {
        setLogin(true);
        //updateViewTopBar(true);
    };

    const handleClick = (message) => {
        setMessage(message.text);
        setSeverity(message.type);
        setOpen(true);
    };

    const openDialog = () => {
        console.log("Opening Dialog ... ");
        setEmailPasswordDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const openBackDrop = (message) => {
        if (message === "open") {
            setBackDrop(
                <div
                    style={{
                        position: "absolute",
                    }}
                >
                    <Backdrop
                        sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={true}
                        onClick={null}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </div>
            );
        } else {
            setBackDrop();
        }
    };

    let userDetails;

    try {
        userDetails = new Map(
            JSON.parse(originalText).map((object) => {
                return [object[0], object[1]];
                // console.log(object);
            })
        );
    } catch (e) {
    }

    // SSE
    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchEventSource(
                    ApiDetails +
                    "pegasus/visionary/sse/" +
                    JSON.parse(originalText)[0][1],
                    {
                        method: "GET",
                        headers: {
                            Accept: "text/event-stream",
                        },
                        onopen(res) {
                            if (res.ok && res.status === 200) {
                                setConnectionMade(true);
                                // console.log("Connection made ", res);
                            } else if (
                                res.status >= 400 &&
                                res.status < 500 &&
                                res.status !== 429
                            ) {
                                console.log("Client side error ", res);
                            }
                        },
                        onmessage(event) {
                            try {
                                let notifications = Array.from(JSON.parse(event.data));

                                notifications.map((notifi, index) => {
                                    let notification = new Map(Object.entries(notifi));
                                    if (notification.get("type") === "payment") {
                                        NotificationManager.success(
                                            notification.get("tenant_name") +
                                            " Credited " +
                                            notification.get("amount").toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "KSH",
                                            }),
                                            "Payment Received",
                                            3000
                                        );
                                    } else if (
                                        notification.get("type") === "subscription payment"
                                    ) {
                                        NotificationManager.success(
                                            notification.get("message"),
                                            "Subscription",
                                            3000
                                        );
                                        setTimeout(() => {
                                            logout();
                                        }, 3500);
                                    } else if (notification.get("type") === "information") {
                                        NotificationManager.info(
                                            notification.get("message"),
                                            "Notice",
                                            3000
                                        );
                                    } else if (notification.get("type") === "warning") {
                                        NotificationManager.warning(
                                            notification.get("message"),
                                            "Warning",
                                            3000
                                        );
                                    } else if (notification.get("type") === "error") {
                                        NotificationManager.error(
                                            notification.get("message"),
                                            "Error",
                                            3000
                                        );
                                    }
                                });
                            } catch (e) {
                                //Means the SSE message is a keep alive
                                // console.log(event.data);
                            }

                            // console.log(event.data);
                        },
                        onclose() {
                            console.log("Connection closed by the server retrying ... ");
                        },
                        onerror(err) {
                            console.log("There was an error from server", err);
                        },
                    }
                );
            } catch (e) {
            }
        };
        if (!connectionMade) {
            fetchData();
        }
    }, []);

    // useEffect(() => {
    //     const sse = new EventSource(ApiDetails +
    //         "pegasus/visionary/sse/" +
    //         JSON.parse(originalText)[0][1],
    //         { withCredentials: true });
    //     function getRealtimeData(data) {
    //         // process the data here,
    //         // then pass it to state to be rendered
    //     }
    //     sse.onmessage = e => getRealtimeData(JSON.parse(e.data));
    //     sse.onerror = () => {
    //         // error log here
    //
    //         sse.close();
    //     }
    //     return () => {
    //         sse.close();
    //     };
    // }, []);

    const {render, setForceRefresh} = Topbar();
    const {sideBar, setForceUpdate} = TestSidebar();

    const errorHandler = (error, errorInfo) => {
        console.log("Logging", error, errorInfo);
    };
    const errorPage = () => {
        return (
            <CustomizedDialogs>
                <LoginDialog
                    snackBar={(message) => handleClick(message)}
                    fn={() => changeLogin()}
                    topBar={() => changeLogin()}
                    refresh={(value) => {
                        setForceRefresh(value);
                    }}
                />
            </CustomizedDialogs>
        );
    };

    const [inactive, setInactive] = useState(false);

    // Define a function to reset the inactivity timer
    const resetInactivityTimer = () => {
        setInactive(false);
    };

    // Set up event listeners to track user interactions
    useEffect(() => {
        const inactivityTimeout = setTimeout(() => {
            setInactive(true);
            // console.log("User is inactive")
            logout()
        },  3600000); // 1 hour of inactivity

        // Add event listeners to reset the timer on user interactions
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keydown', resetInactivityTimer);

        // Clean up the event listeners when the component unmounts
        return () => {
            clearTimeout(inactivityTimeout);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
        };
    }, []);

    return (
        <ConfirmProvider>
        {/*// <CheckConnection>*/}
        <div>
            <SnackbarComponent />
            {/*{inactive && <p>User is inactive</p>}*/}
            <NotificationContainer/>
            <Stack spacing={2} sx={{width: "100%"}}>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{width: "100%"}}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            {backdrop}
            <div className="App">

                <div className="cont">
                    <Router>
                        {isLoggedIn ? (
                            // <Sidebar
                            // <TestSidebar
                            //   loginState={isLoggedIn}
                            //   fn={() => logout()}
                            //   dialog={() => {
                            //     openDialog();
                            //   }}
                            //   backrop={(message) => {
                            //     openBackDrop(message);
                            //   }}
                            //   snackBar={(message) => handleClick(message)}
                            // />
                            // <>{sideBar}</>
                            <TestSidebar/>
                        ) : (
                            <></>
                        )}
                        <div
                            style={{
                                flex: 4,
                                width: "100wh",
                                backgroundColor: "whitesmoke",
                            }}
                        >
                            {isLoggedIn ? <> {render}</> : <></>}
                            {/*<ErrorBoundary*/}
                            {/*  FallbackComponent={errorPage}*/}
                            {/*  onError={errorHandler}*/}
                            {/*>*/}
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <LoginPageV2
                                            snackBar={(message) => {
                                                handleClick(message);
                                            }}
                                            fn={() => changeLogin()}
                                            topBar={() => changeLogin()}
                                            refresh={(value) => {
                                                setForceRefresh(value);
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/userManagement"
                                    element={
                                        <UserManagement
                                            snackBar={(message) => {
                                                handleClick(message);
                                            }}
                                            fn={() => changeLogin()}
                                            topBar={() => changeLogin()}
                                            refresh={(value) => {
                                                setForceRefresh(value);
                                            }}
                                        />
                                    }
                                />

                                <Route
                                    path="/login"
                                    element={
                                        <CustomizedDialogs>
                                            <LoginDialog
                                                snackBar={(message) => handleClick(message)}
                                                fn={() => changeLogin()}
                                                topBar={() => changeLogin()}
                                                refresh={(value) => {
                                                    setForceRefresh(value);
                                                }}
                                            />
                                        </CustomizedDialogs>
                                    }
                                />

                                <Route
                                    path="/tenantList"
                                    element={
                                        <UserList
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/vacatingTenantList"
                                    element={
                                        <VacatingTenantList
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/tenantIssues"
                                    element={
                                        <TenantIssues
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/oldTenantList"
                                    element={
                                        <OldTenantList
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/analytics"
                                    element={
                                        <Analytics
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/propertyList"
                                    element={
                                        <PropertyList
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            snackBar={(message) => handleClick(message)}
                                            loginState={isLoggedIn}
                                        />
                                    }
                                />
                                <Route
                                    path="/transactions"
                                    element={
                                        <Transactions
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            snackBar={(message) => handleClick(message)}
                                            loginState={isLoggedIn}
                                        />
                                    }
                                />
                                <Route
                                    path="/signup"
                                    element={
                                        // <Signup snackBar={(message) => handleClick(message)} />
                                        // <CustomizedDialogs>
                                        //   <SignupDialog
                                        //     snackBar={(message) => handleClick(message)}
                                        //     fn={() => changeLogin()}
                                        //     topBar={() => changeLogin()}
                                        //   />
                                        // </CustomizedDialogs>
                                        <SignupPage
                                            snackBar={(message) => handleClick(message)}
                                            fn={() => changeLogin()}
                                            topBar={() => changeLogin()}
                                        />
                                    }
                                />
                                {/*<Route*/}
                                {/*    path="/tenant/:userId"*/}
                                {/*    element={*/}
                                {/*        <User*/}
                                {/*            loginState={isLoggedIn}*/}
                                {/*            snackBar={(message) => handleClick(message)}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*/>*/}
                                <Route
                                    path="/tenant/:userId"
                                    element={
                                       /* <Tenant
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                        />*/
                                        <TenantV2
                                        loginState={isLoggedIn}
                                    snackBar={(message) => handleClick(message)}
                                />
                                    }
                                />
                                <Route
                                    path="/property/:propertyId"
                                    element={
                                        <PropertyV2
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/mailList"
                                    element={
                                        <MailList
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                            backrop={(message) => {
                                                openBackDrop(message);
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/messages"
                                    element={
                                        <Message
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/expenses"
                                    element={
                                        <Expenses
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            loginState={isLoggedIn}
                                            snackBar={(message) => handleClick(message)}
                                            backrop={(message) => {
                                                openBackDrop(message);
                                            }}
                                        />
                                    }
                                />
                                <Route
                                    path="/user/create"
                                    element={<CreateUser loginState={isLoggedIn}/>}
                                />
                                <Route
                                    path="/home"
                                    element={
                                        <HomeV2
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            fn={() => changeLogin()}
                                            loginState={isLoggedIn}
                                            topBar={() => changeLogin()}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/events"
                                    element={
                                        <Events
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            loginState={isLoggedIn}
                                            topBar={() => changeLogin()}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/settings"
                                    element={
                                        <Settings
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            refresh={(value) => {
                                                setForceRefresh(value);
                                            }}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                                <Route
                                    path="/details"
                                    element={
                                        <Details
                                            refreshSideBar={() =>
                                                setForceRefresh((forceRefresh) => !forceRefresh)
                                            }
                                            loginState={isLoggedIn}
                                        />
                                    }
                                />

                                <Route
                                    path="/backup"
                                    element={
                                        <MyFileBrowser
                                            snackBar={(message) => handleClick(message)}
                                            topBar={(value) => changeLogin(value)}
                                        />
                                        //         <FolderUpload
                                        //         snackBar={(message) => handleClick(message)}
                                        //     topBar={(value) => changeLogin(value)}
                                        //
                                        // />
                                    }
                                />


                                <Route path='/MPESA-organization-portal' component={() => {
                                    window.location.href = 'https://org.ke.m-pesa.com/orglogin.action?language=en';
                                    return null;
                                }}/>

                                <Route
                                    path="*"
                                    element={
                                        <Error404
                                            snackBar={(message) => {
                                                handleClick(message);
                                            }}
                                            fn={() => changeLogin()}
                                            topBar={() => changeLogin()}
                                            refresh={(value) => {
                                                setForceRefresh(value);
                                            }}
                                        />
                                    }
                                />
                            </Routes>
                            {/*</ErrorBoundary>*/}
                        </div>
                    </Router>
                </div>
            </div>
        </div>
        {/*// </CheckConnection>*/}
        </ConfirmProvider>
    );
}

export default App;
