import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ProSidebarProvider} from "react-pro-sidebar";
import {setChonkyDefaults} from 'chonky';
import {ChonkyIconFA} from 'chonky-icon-fontawesome';
import { MantineProvider } from '@mantine/core';

// Somewhere in your `index.ts`:
setChonkyDefaults({iconComponent: ChonkyIconFA});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <MantineProvider withGlobalStyles withNormalizeCSS>
        <ProSidebarProvider>
            <App/>
        </ProSidebarProvider>
    </MantineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
