import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import PropertyDetails from "./Property components/PropertyDetails";

export default function PropertyV2(props) {
    const {state} = useLocation();
    // let { property, tenants } = state;
    let {property_id} = state;

    return (
        <div>
            <PropertyDetails property_id={property_id}/>
        </div>
    );
}
