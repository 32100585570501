import React, {useState, useMemo} from "react";
import {
    Box,
    Typography,
    Tooltip,
    LinearProgress,
    Paper,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {DownloadForOfflineOutlined} from "@mui/icons-material";
import CryptoJS from "crypto-js";
import {ApiDetails, KEY} from "../../dummyData";

// Styled Tooltip
const HtmlTooltip = styled(Tooltip)(({theme}) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        boxShadow: theme.shadows[3],
    },
}));

// Styled Linear Progress with Label
const LinearProgressWithLabel = styled(LinearProgress)(({theme}) => ({
    height: 10,
    borderRadius: 5,
    [`& .MuiLinearProgress-bar`]: {
        backgroundColor: "#38B973", // Success green
    },
}));

const ProgressWithLabel = ({value}) => {
    // Ensure the value is a valid number between 0 and 100
    const progressValue = Math.max(0, Math.min(100, value));

    return (
        <>

            <Box sx={{display: "flex", alignItems: "center"}}>
                <Box sx={{width: "100%"}}>
                    <LinearProgressWithLabel
                        value={progressValue}
                    />
                </Box>
                <Typography variant="h5" sx={{color: "#3799D6"}}>
                    {`${Math.round(progressValue)}%`}
                </Typography>
            </Box>
        </>
    );
};


// Styled Featured Item
const StyledFeaturedItem = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    // borderRadius: "5px",
    boxShadow: theme.shadows[2],
    textAlign: "center",
    color: "#597599",
    backgroundColor: "#f3f7fa", // Lighter blue background
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
}));

// Main Component
export default function FeaturedInfo(props) {
    const [dateFrom] = useState(new Date());
    const [propertyId] = useState(null);

    // Parse data only once
    const originalData = useMemo(
        () =>
            JSON.parse(
                CryptoJS.AES.decrypt(localStorage.portfolioDetails, KEY).toString(
                    CryptoJS.enc.Utf8
                )
            ),
        []
    );

    const taxData = useMemo(() => new Map(Object.entries(originalData[16][1])), [originalData]);
    const incomeData = useMemo(() => new Map(Object.entries(originalData[9][1])), [originalData]);
    const expenseData = useMemo(() => new Map(Object.entries(originalData[12][1])), [originalData]);
    const depositData = useMemo(() => new Map(Object.entries(originalData[17][1])), [originalData]);
    const occupancyData = useMemo(() => new Map(Object.entries(originalData[13]), [originalData]));

    console.log("Original data")
    console.log(occupancyData.get("1"))

    const tax = taxData.get("Total tax") || 0;
    const income = incomeData.get("Total income") || 0;
    const incomeExpected = new Map(Object.entries(originalData[8][1])).get("Total income") || 0;
    const expenses = expenseData.get("Total expenses") || 0;
    const deposit = depositData.get("Total deposit") || 0;
    const occupancy = occupancyData.get("1");

    return (
        <div style={{margin: "10px"}}>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                    gap: 3,
                }}
            >
                {/* Profit */}
                {/*<StyledFeaturedItem>*/}
                {/*    <Typography variant="h6" sx={{color: "#002961"}}>Profit</Typography>*/}
                {/*    <Typography*/}
                {/*        variant="h5"*/}
                {/*        color={income - expenses - tax < 0 ? "error" : "#38B973"}*/}
                {/*    >*/}
                {/*        {(income - expenses - tax).toLocaleString("en-US", {*/}
                {/*            style: "currency",*/}
                {/*            currency: "KSH",*/}
                {/*        })}*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="body2" color="text.secondary">*/}
                {/*        Net profit after tax*/}
                {/*    </Typography>*/}
                {/*</StyledFeaturedItem>*/}

                {/* Income */}
                <StyledFeaturedItem>
                    <Typography variant="h6" sx={{color: "#002961"}}>Income</Typography>
                    <Typography variant="h5" sx={{color: "#3799D6"}}>
                        {income.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Expected:{" "}
                        {incomeExpected.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </Typography>
                </StyledFeaturedItem>

                {/* Occupancy */}
                <StyledFeaturedItem>
                    <Typography variant="h6" sx={{color: "#002961"}}>Occupancy</Typography>
                    <ProgressWithLabel value={occupancy}/>
                    <Typography variant="body2" color="text.secondary">
                        Property occupancy rate
                    </Typography>
                </StyledFeaturedItem>

                {/* Expenses */}
                <StyledFeaturedItem>
                    <Typography variant="h6" sx={{color: "#002961"}}>Expenses</Typography>
                    <Typography variant="h5" sx={{color: "error.main"}}>
                        {expenses.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Across all properties
                    </Typography>
                </StyledFeaturedItem>

                {/* Total Deposit Held */}
                <StyledFeaturedItem>
                    <Typography variant="h6" sx={{color: "#002961"}}>Total Deposit Held</Typography>
                    <Typography variant="h5" sx={{color: "#3799D6"}}>
                        {deposit.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Across all properties
                    </Typography>
                </StyledFeaturedItem>
            </Box>
        </div>
    );
}
