import React, {useState, useEffect} from "react";
import CryptoJS from "crypto-js";
import {ApiDetails, KEY} from "../../dummyData";
import {DataGrid} from "@mui/x-data-grid";
import {styled} from "@mui/material/styles";
import {
    Box,
    Typography,
    Paper,
    Select,
    MenuItem,
    FormControl,
    CircularProgress
} from "@mui/material";
import {format} from "date-fns";
import {showSnackbar} from "../SnackBar/SnackBar";

const StyledContainer = styled(Paper)(({theme}) => ({
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f3f7fa",
    boxShadow: theme.shadows[3],
    overflow: "hidden",
}));

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    "& .MuiDataGrid-columnHeaders": {
        fontSize: "1rem",
        fontWeight: 600,
        backgroundColor: "#f8b2a1",
        color: "black",
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "#F5F5F5",
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "0.9rem",
        color: "#002961",
    },
}));

export default function WidgetLg() {
    const [filter, setFilter] = useState("daily");
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        let decrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);
        let originalText = decrypted.toString(CryptoJS.enc.Utf8);

        fetch(ApiDetails + "pegasus/visionary/dashboard/get/home/transactions", {
            method: "POST",
            body: JSON.stringify({
                filter: filter,
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let transactions = await response.json();
                if (response.status === 200) {
                    setTransactions(transactions.transactions);
                    showSnackbar({
                        title: "Success!",
                        message: filter + " transactions retrieved successfully.",
                        type: "success",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Failed fetching " + filter + " transactions.",
                        type: "error",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                }
            })
            .catch(() => {
                showSnackbar({
                    title: "Error!",
                    message: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                    position: {vertical: "bottom", horizontal: "right"},
                });
            })
            .finally(() => setLoading(false));
    }, [filter]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const columns = [
        {
            field: "transaction_id",
            headerName: "Transaction ID",
            minWidth: 120,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "amount",
            headerName: "Amount",
            minWidth: 120,
            flex: 1,
            renderCell: (params) => (
                <Typography sx={{fontWeight: 600, color: "#38B973"}}>
                    {params.row.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "KSH",
                    })}
                </Typography>
            ),
        },
        {
            field: "Name",
            headerName: "Name",
            minWidth: 150,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "date_created",
            headerName: "Date",
            minWidth: 200,
            flex: 1,
            valueFormatter: (params) =>
                format(new Date(params.value), "dd MMM yyyy, HH:mm"),
        },
    ];

    return (
        <StyledContainer>
            <Box sx={{display: "flex", alignItems: "center", mb: 2}}>
                <Typography variant="h6" sx={{fontWeight: "bold", color: "#002961", paddingRight: "10px"}}>
                    Transactions:
                </Typography>
                <FormControl sx={{minWidth: 300}}>
                    <Select value={filter} onChange={handleFilterChange} style={{minWidth: "150px"}}>
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {loading ? (
                <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: 200}}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    <StyledDataGrid
                        rows={transactions.map((row, index) => ({...row, id: index}))}
                        columns={columns}
                        disableSelectionOnClick
                        pageSize={5}
                        rowsPerPageOptions={[5, 10]}
                        autoHeight
                    />
                </Box>
            )}
        </StyledContainer>
    );
}
