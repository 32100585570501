import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Avatar,
    Box,
    Typography,
    TextField,
    IconButton,
    Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {ApiDetails} from "../../../../dummyData";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";

export default function MessagesComponent(props) {
    const messagesEndRef = useRef(null); // Reference to the end of the messages container
    const [messages, setMessages] = useState([
        {
            id: 1,
            sender_id: "",
            recipient_id: "",
            message: "",
            type: "file",
            date_created: "",
        }
    ]);
    const [inputMessage, setInputMessage] = useState("");
    const [attachedFile, setAttachedFile] = useState(null);

    // Scroll to the bottom of the messages when the component loads or when messages change
    const scrollToBottom = () => {
        // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]); // Scroll when messages are updated


    useEffect(() => {

        // Fetch the tenant data
        fetch(ApiDetails + "pegasus/visionary/messages", {
            method: "POST",
            body: JSON.stringify({id: props.tenatDetails.tenant_id}),
            headers: {"Content-Type": "application/json"},
        })
            .then((response) => response.json())
            .then((data) => {
                setMessages(data);

            })
            .catch((err) => console.error(err));

    }, []);


    // Handle file attachment
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setAttachedFile(file);
        }
    };

    // Handle sending a message
    const handleSendMessage = () => {
        if (inputMessage.trim() || attachedFile) {
            const newMessage = {
                id: messages.length + 1,
                sender: "You",
                message: attachedFile
                    ? `${attachedFile.name} (${Math.round(attachedFile.size / 1024)} KB)`
                    : inputMessage,
                recipient_id: props.tenatDetails.tenant_id,
                type: attachedFile ? "file" : "text",
                date_created: "Just now",
            };

            setMessages([...messages, newMessage]);
            setInputMessage("");
            setAttachedFile(null); // Clear the attached file after sending
        }

        try {
            fetch(ApiDetails + "pegasus/visionary/messages/send", {
                method: "POST",
                body: JSON.stringify({
                    sender: "landlord",
                    sender_id: props.landlordID,
                    recipient_id: props.tenatDetails.tenant_id,
                    message: inputMessage,
                }),
            })
                .then(async (response) => {
                    if (response.status !== 200) {
                        showSnackbar({
                            title: "Error!",
                            message: "Could not send message.",
                            type: "error", // Options: success, error, warning, info
                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                        });
                    }
                })
                .catch((err) => console.log(err));
        } catch (e) {
            console.log(e)
        }

    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "55vh",
                backgroundColor: "#F7F9FC",
            }}
        >
            {/* Messages List */}
            <Box sx={{overflowY: "auto", flexGrow: 1, padding: "10px"}}>
                {messages.map((msg) => (
                    <Box
                        key={msg.id}
                        sx={{
                            display: "flex",
                            flexDirection: msg.sender_id !== props.tenatDetails.tenant_id ? "row-reverse" : "row",
                            marginBottom: "15px",
                            alignItems: "flex-start",
                        }}
                    >
                        {/* Avatar */}
                        <Avatar
                            sx={{
                                bgcolor: msg.sender_id !== props.tenatDetails.tenant_id ? "#2C5283" : "#F4F6F8",
                                marginRight: msg.sender_id !== props.tenatDetails.tenant_id ? 0 : "10px",
                                marginLeft: msg.sender_id !== props.tenatDetails.tenant_id ? "10px" : 0,
                            }}
                        >
                            {/*{msg.sender[0]}*/}
                        </Avatar>

                        {/* Message Bubble */}
                        <Box
                            sx={{
                                maxWidth: "70%",
                                backgroundColor: msg.sender_id !== props.tenatDetails.tenant_id ? "#2C5283" : "#EDEFF1",
                                color: msg.sender_id !== props.tenatDetails.tenant_id ? "#fff" : "#000",
                                padding: "10px 15px",
                                borderRadius: "15px",
                                borderTopLeftRadius: msg.sender_id !== props.tenatDetails.tenant_id ? "15px" : "0",
                                borderTopRightRadius: msg.sender_id !== props.tenatDetails.tenant_id ? "0" : "15px",
                                boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                            }}
                        >
                            {msg.type === "file" ? (
                                <Button
                                    variant="outlined"
                                    sx={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                    }}
                                    onClick={() => alert(`Downloading: ${msg.message}`)}
                                >
                                    {msg.message}
                                </Button>
                            ) : (
                                <Typography variant="body1">{msg.message}</Typography>
                            )}
                            <Typography
                                variant="caption"
                                sx={{display: "block", marginTop: "5px", color: "#9CA3AF"}}
                            >
                                {msg.date_created}
                            </Typography>
                        </Box>
                    </Box>
                ))}
                {/* Empty div to allow scrolling to the bottom */}
                <div ref={messagesEndRef}/>
            </Box>

            {/* Input Section */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "10px",
                    backgroundColor: "#fff",
                    borderTop: "1px solid #E0E0E0",
                }}
            >
                {/* File Attachment Button */}
                <IconButton component="label" sx={{marginRight: "10px"}}>
                    <AttachFileIcon/>
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                    />
                </IconButton>

                {/* File Preview */}
                {attachedFile && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F0F0F0",
                            padding: "5px 10px",
                            borderRadius: "15px",
                            marginRight: "10px",
                        }}
                    >
                        <Typography variant="body2" sx={{marginRight: "10px"}}>
                            {attachedFile.name}
                        </Typography>
                        <IconButton size="small" onClick={() => setAttachedFile(null)}>
                            ✖
                        </IconButton>
                    </Box>
                )}

                {/* Text Field */}
                <TextField
                    variant="outlined"
                    placeholder="Type a message..."
                    fullWidth
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    sx={{
                        marginRight: "10px",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                        },
                    }}
                />

                {/* Send Button */}
                <IconButton
                    color="primary"
                    sx={{
                        backgroundColor: "#2C5283",
                        color: "#fff",
                        borderRadius: "50%",
                    }}
                    onClick={handleSendMessage}
                >
                    <SendIcon/>
                </IconButton>
            </Box>
        </Box>
    );
}
