import React from "react";
import {
    Box,
    Grid,
    Typography,
    Avatar,
    Divider,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import imageAvatar from "../../../../assets/Tenant_avatar.jpg";
import {LocationOn} from "@material-ui/icons";

const LeftSide = (props) => {

    const {propertyDetails, propertyImage} = props;

    return (
        <Box
            sx={{
                margin: "20px",
                marginTop: "40px",
                marginLeft: "40px",
                padding: "20px",
                maxWidth: "300px",
                borderRadius: "8px",
                border: "1px solid #E0E0E0",
                backgroundColor: "#F9FAFB",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
            }}
        >
            {/* Customer Header */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                <Avatar
                    sx={{
                        width: 80,
                        height: 80,
                        backgroundColor: "#E0E0E0",
                        marginBottom: "10px",
                    }}
                    src={propertyImage === "" ? imageAvatar : propertyImage}
                />

                <Typography
                    variant="h6"
                    sx={{fontWeight: "bold", color: "#2C5283"}}
                >
                    {propertyDetails.property_name.toUpperCase()}
                </Typography>
                {propertyDetails.location && (
                    <Box sx={{display: "flex", alignItems: "center", gap: "5px"}}>
                        <LocationOn fontSize="small" sx={{color: "#2C5283"}}/>
                        <Typography variant="body2" color="textSecondary">
                            {propertyDetails.location}
                        </Typography>
                    </Box>
                )}
            </Box>

            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

            {/* Details Section */}
            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", marginBottom: "10px"}}>
                    Identification Details
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Names:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {propertyDetails.property_name.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Location:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {propertyDetails.location}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Occupancy:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {propertyDetails.occupancy}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", marginBottom: "10px"}}>
                    Contact Information
                </Typography>
                <Grid container spacing={1}>
                    {propertyDetails.query_number && <><Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Query No.:
                        </Typography>
                    </Grid>
                        <Grid item xs={6} sx={{textAlign: "right"}}>
                            <Typography variant="body2" sx={{fontWeight: "bold"}}>
                                {propertyDetails.query_number}
                            </Typography>
                        </Grid></>
                    }

                </Grid>
            </Box>


            <Divider sx={{backgroundColor: "#2C5283"}}/>

            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", marginBottom: "10px"}}>
                    Billing Information
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Water Cost per Unit:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {parseInt(
                                propertyDetails.water_bill.toString().replace(",", "")
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "KSH",
                            })}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Minimum water Cost per Unit:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {parseInt(
                                propertyDetails.minimum_water_bill.toString().replace(",", "")
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "KSH",
                            })}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Electricity Cost per Unit:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {parseInt(
                                propertyDetails.electricity_bill.toString().replace(",", "")
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "KSH",
                            })}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Minimum Electricity Cost per Unit:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {parseInt(
                                propertyDetails.minimum_electricity_bill.toString().replace(",", "")
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "KSH",
                            })}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Other charges:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {parseInt(
                                propertyDetails.other_charges.toString().replace(",", "")
                            ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "KSH",
                            })}
                        </Typography>
                    </Grid>


                </Grid>
            </Box>

            <Divider sx={{backgroundColor: "#2C5283"}}/>

            <Box sx={{marginBottom: "20px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", marginBottom: "10px"}}>
                    Other Details
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Apply Penalty:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Chip
                            label={propertyDetails.apply_penalty}
                            color={propertyDetails.apply_penalty === "YES" ? "success" : "error"}
                            variant="outlined"
                            sx={{fontWeight: "bold"}}
                        />
                    </Grid>


                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Penalty Date:
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {propertyDetails.penalty_date === "" ? 11 : propertyDetails.penalty_date}
                        </Typography>
                    </Grid>


                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Percentage Penalty:
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {propertyDetails.penalty_percentage === "" ? 10 : propertyDetails.penalty_percentage}
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Reminder Date:
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {propertyDetails.reminder_date === "" ? 5 : propertyDetails.reminder_date}
                        </Typography>
                    </Grid>


                    <Grid item xs={6}>
                        <Typography variant="body2" sx={{color: "#4A5568"}}>
                            Payment Details:
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sx={{textAlign: "right"}}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                            {propertyDetails.payment_details}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>);
};

export default LeftSide;
